import React, { useState } from "react"
import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
import classes from "./NewsBlog.module.css"
import blogImage from "assets/images/news_reading.jpg"
// import ImageSlider from "../../components/GeneralParts/ImageSlider/ImageSlider"
import userIcon from "assets/icons/userfe.svg"
import { ArticleContent, Articles, ArticlesDiv } from "./style"
import { Link } from "react-router-dom"
import { formatDate } from "utils"

function BlogSlider({ blog }) {
	const [currentSlide, setCurrentSlide] = useState(0)
	const [loaded, setLoaded] = useState(false)
	const [sliderRef, instanceRef] = useKeenSlider({
		initial: 0,
		breakpoints: {
			"(min-width: 400px)": {
				slides: { perView: 1, spacing: 5 }
			},
			"(min-width: 760px)": {
				slides: { perView: 1, spacing: 5 }
			},
			"(min-width: 1000px)": {
				slides: { perView: 3, spacing: 10 }
			}
		},
		slides: { perView: 1 },
		slideChanged(slider) {
			setCurrentSlide(slider.track.details.rel)
		},
		created() {
			setLoaded(true)
		}
	})
	const allblog = blog ? blog : []

	return (
		<ArticlesDiv>
			{allblog.length > 0 && (
				<div className="navigation-wrapper">
					<div ref={sliderRef} className="keen-slider">
						{allblog &&
							allblog.length > 0 &&
							allblog?.map(item => (
								<div key={item.id} className="keen-slider__slide slide_bg">
									<Articles>
										<div className={classes.articleImage}>
											<Link to={`/blog/posts/${item?.blogUrl}/${item?.id}`}>
												<img src={item?.image?.url ? item?.image?.url : blogImage} alt={item?.title} />
											</Link>
										</div>
										<ArticleContent>
											<Link to={`/blog/posts/${item?.blogUrl}/${item?.id}`}>
												<h1>{item?.title.substring(0, 40)}</h1>
											</Link>

											<Link to={`/blog/posts/${item?.blogUrl}/${item?.id}`}>
												<h2>{item?.excerpt.substring(0, 56)}...</h2>
											</Link>
											<div className={"blogger"}>
												<div className={classes.bloggerImage}>
													<Link to={`/blog/posts/${item?.blogUrl}/${item?.id}`}>
														<img src={userIcon} alt={"blog.author.firstname"} />
													</Link>
												</div>
												<div className={classes.bloggerInfo}>
													<p>
														{item?.author?.first_name} {item?.author?.surname}
													</p>
													<p>{item?.createdAt ? formatDate(new Date(item?.createdAt)) : ""}</p>
													<p>{item?.readTime} min Read</p>
												</div>
											</div>
										</ArticleContent>
									</Articles>
								</div>
							))}
					</div>
				</div>
			)}
			{blog.length > 0 && loaded && instanceRef.current && (
				<div className="dots">
					{[...Array(instanceRef.current.track.details.slides.length).keys()].map(idx => {
						return (
							<button
								key={idx}
								onClick={() => {
									instanceRef.current?.moveToIdx(idx)
								}}
								className={"dot" + (currentSlide === idx ? " active" : "")}
							></button>
						)
					})}
				</div>
			)}
		</ArticlesDiv>
	)
}

export default BlogSlider
