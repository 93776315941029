import {ReviewDetailSection, ReviewField, ReviewRow,ReviewFieldTitle, ReviewFieldValue } from "../style";

export default function AmountDetail({amountDetail}){
    return (
        <ReviewDetailSection>
                <h4 style={{fontSize:"24px",marginBottom:"16px"}}>Loan Details</h4>
                <ReviewRow>
                   <ReviewField>
                        <div className="field">
                            <ReviewFieldTitle>Amount Requested</ReviewFieldTitle>
                            <ReviewFieldValue >₦{parseFloat(amountDetail.amount).toLocaleString()}</ReviewFieldValue>
                        </div>
                   </ReviewField>
                   <ReviewField>
                        <div className="field">
                            <ReviewFieldTitle>Purpose of Loan</ReviewFieldTitle>
                            <ReviewFieldValue >{amountDetail.purpose}</ReviewFieldValue>
                        </div>
                   </ReviewField>
                   
                </ReviewRow>
                <ReviewRow>
                    <ReviewField>
                        <div className="field">
                            <ReviewFieldTitle>Loan Tenure</ReviewFieldTitle>
                            <ReviewFieldValue >{amountDetail.loan_tenure} Months</ReviewFieldValue>
                        </div>
                    </ReviewField>
                    <ReviewField>
                        <div className="field">
                            <ReviewFieldTitle>Monthly Repayment Amount</ReviewFieldTitle>
                            <ReviewFieldValue >₦{parseFloat(amountDetail.monthly_repayment_amount).toLocaleString()}</ReviewFieldValue>
                        </div>
                    </ReviewField>
                </ReviewRow>
            </ReviewDetailSection>
    )
}