import { message } from 'antd';
import { useState } from 'react';
import {useDropzone} from 'react-dropzone';

const MAX_FILE_SIZE = 500 * 1024

export default function UploadFileContainer({formTitle,note,fileURL,setFile,setFileURL,file}) {
    const [edit, setEdit] = useState(false);
    const {getRootProps, getInputProps} = useDropzone({
        accept:{
            'image/jpeg':[".jpeg",".jpeg"],
            'image/jpg':[".jpg"],
        },
        onDrop: (files) => {
            const initialFile = files[0];
            if(initialFile.size > MAX_FILE_SIZE ) return message.error("Image File should not exceed 500KB")
            setFileURL(URL.createObjectURL(initialFile));
            setFile(files);
            setEdit(false);
        }
    });


  return (
    <section className="">
        <div className="flex justify-between">
            <h3 className="font-semibold mt-2 mb-1 text-lg text-[#757575]">{formTitle} <b className="font-poppins">(Max Image Size of 500KB)</b></h3>
            {fileURL && (
                <button onClick={()=>setEdit(!edit)}>
                    {edit
                        ?<span><i className="bi bi-x-lg"></i></span>
                        :<span><i className="bi bi-pencil-fill"></i></span>
                    }
                </button>
            )}
        </div>
        {note && <p className="text-sm font-poppins mb-2">{note}</p>}
        {fileURL && !edit?(
        <div className="rounded-lg min-h-[150px]">
            <img src={fileURL} alt={formTitle} className="h-[180px] object-cover w-full rounded-lg" />
        </div>
        ):(
        <div className="cursor-pointer border-dashed border-2 flex items-center justify-center py-4 border-[#9E9E9E] rounded-lg bg-[#E0E0E0] min-h-[180px]">
            <div {...getRootProps({className:"flex-1 font-poppins text-center"})}>
                <i className="text-5xl text-[#9E9E9E]-500 bi bi-cloud-upload"></i>
                <input {...getInputProps()} />
                <p className="text-base text-[#BDBDBD] font-poppins font-semibold">Drag and drop or click to select file</p>
            </div>
        </div>
        )}
    </section>
  );
}
