import BlogMain from "components/BlogComponent"
import Mainlayout from "layout/MainLayout"
import React from "react"

function BlogPage() {
	return (
		<Mainlayout>
			<BlogMain />
		</Mainlayout>
	)
}

export default BlogPage
