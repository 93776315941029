import styled from "styled-components/macro"
import Colors from "utils/colors"
import media from "utils/media"

export const LoanReqDiv = styled.div`
	height: 70%;
	width: 60%;
	background: ${Colors.white};
	padding: 5px 40px;
	border-radius: 5px;
	overflow: hidden;
	overflow-y: scroll;
	box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;

	ul,
	ol {
		width: 100%;
		font-size: 16px;
		/* padding: 10px; */
		/* border: 1px solid #999; */
		width: 100%;
		margin: 20px auto;
	}
	ul {
		/* background-color: #ddd; */
	}
	ol {
		/* background-color: #fff; */
	}
	${media.tablet`
	width: 85%;
	height: 80%;
	padding-bottom: 2rem;
	`}
	${media.mobile`
	width: 95%;
	height: 90%;
	padding-bottom: 2rem;
	`}
`

export const WlButton = styled.button`
	background-color: ${({ bc }) => (bc ? bc : null)};
	width: ${({ w }) => (w ? w : null)};
	height: ${({ h }) => (h ? h : null)};
	flex: ${({ fg }) => (fg ? fg : null)};
	padding: ${({ p }) => (p ? p : null)};
	border: ${({ border }) => (border ? `1px solid ${border}` : `none`)};
	border-radius: ${({ br }) => (br ? br : null)};
	outline: none;
	font-weight: ${({ fw }) => (fw ? fw : null)};
	font-size: ${({ fs }) => (fs ? fs : null)};
	display: ${({ display }) => display};
	align-items: ${({ alignI }) => alignI || null};
	cursor: pointer;
	box-shadow: ${({ bs }) => (bs ? bs : null)};
	box-sizing: ${({ bSizing }) => (bSizing ? bSizing : null)};
	color: ${({ color }) => (color ? color : null)};
	margin: ${({ m }) => (m ? m : null)};
	margin-top: ${({ mt }) => (mt ? mt : null)};
	margin-left: ${({ ml }) => (ml ? ml : null)};
	margin-right: ${({ mr }) => (mr ? mr : null)};
	margin-bottom: ${({ mb }) => (mb ? mb : null)};
	border-bottom: ${({ bm }) => (bm ? bm : null)};
	&:hover {
		background: ${({ hv }) => (hv ? hv : null)};
	}
`
