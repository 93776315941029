import React from "react"
import { Link } from "react-router-dom"
import { DASHBOARD_PROFILEROUTE } from "routes"
import { CompleteButton, CompleteDiv, CompleteDivInner, CompleteHeaderText, CompleteHeaderTextDec, CompleteHeaderTextSub, CompleteTextOr } from "./style"

function CompleteYourProfile({
	link = DASHBOARD_PROFILEROUTE,
	title = "Complete Your Profile",
	desc = "Get started by first updating your profile using the button below",
	or = "goto profile menu and update",
	btnText = "Profile"
}) {
	return (
		<CompleteDiv>
			<CompleteDivInner>
				<CompleteHeaderText>{title}</CompleteHeaderText>
				<CompleteHeaderTextDec>{desc}</CompleteHeaderTextDec>
				<Link to={link}>
					<CompleteButton>Goto {btnText}</CompleteButton>
				</Link>
				<CompleteTextOr>
					or <CompleteHeaderTextSub>{or}</CompleteHeaderTextSub>
				</CompleteTextOr>
			</CompleteDivInner>
		</CompleteDiv>
	)
}

export default CompleteYourProfile
