import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  DASHBOARD_ONBOARDING_ROUTE,
} from "routes";

import { checkOnBoarding } from "services/MiscService";
import toast from "react-hot-toast";
import Application from "./Application";
import Notification from "./Notifications";
import OngoingApplication from "./OngoingApplications";

const DashboardHome = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleOnBoarded = async () => {
      const info = await checkOnBoarding();
      if (info?.error) {
        toast.error(info.error);
      } else {
        if (!info.onBoarded) navigate(DASHBOARD_ONBOARDING_ROUTE);
      }
    };
    handleOnBoarded();
  }, [navigate]);


  return (
    <section className="p-4 py-8">
        <div className="flex flex-col-reverse sm:flex-row gap-2">
            <div className="sm:flex-[3] my-6 sm:my-0"><Application/></div>
            <div className="sm:flex-[3]"><Notification/></div>
        </div>
        <div className="my-4 sm:my-2">
          <OngoingApplication/>
        </div>
    </section>
  );
};

export default DashboardHome;