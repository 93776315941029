import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import authStore from "mobx/AuthStore"

import CreateAccountEmail from "./CreateAccountEmail"
import VerifyOtp from "./VerifyOtp"
// import PersonalDetails from "./PersonalDetails"
// import SetPassword from "./SetPassword"
import { useNavigate } from "react-router-dom"
import { DASHBOARD_ROUTE } from "routes"

function CreateAccount() {
	const navigate = useNavigate()
	useEffect(() => {
		authStore.getCurrentStep()
		const status = authStore.loggedInStatus()
		if (status) {
			navigate(`${DASHBOARD_ROUTE}`)
		}
	}, [navigate])
	return (
		<>
			{authStore.currentStep === "" && <CreateAccountEmail />}
			{authStore.currentStep === "verify" && <VerifyOtp />}
			{/* {authStore.currentStep === "create" && <PersonalDetails />}
			{authStore.currentStep === "setpass" && <SetPassword />} */}
		</>
	)
}
export default observer(CreateAccount)
