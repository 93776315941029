import { BlinkingBtn, SlideInPopUp } from "components/MobileMenu/style"
import styled from "styled-components"
import { AuthProp, BvnPageBg } from "utils/assets"
import Colors from "utils/colors"
import media from "utils/media"

export const BvnContainer = styled.div`
	/* height: 100vh; */
	/* min-height: calc(100vh - 75px); */
	background: url(${BvnPageBg}), linear-gradient(73.6deg, rgba(57, 26, 96, 0.97) 4.94%, rgba(109, 0, 98, 0.97) 71.35%);
`
export const BvnValidationContainer = styled.div`
	width: 100%;
	/* height: 100vh; */
	background-image: url(${AuthProp});
	background-repeat: no-repeat;
	background-position: -16% 130%;
	background-size: 400px 400px;
	/* padding: 50px 120px; */
	display: flex;
	flex-direction: column;
	align-items: center;
`

export const BvnValidationPageDiv = styled.div``

export const BvnContentDiv = styled.div`
	width: 500px;
	height: 320px;
	background: #ffffff;
	border-radius: 43px 43px 80px 0px;
	padding: 30px 60px;
	margin-top: 50px;
`

export const BvnPageHeader = styled.h1`
	font-weight: 700;
	font-size: 25px;
	line-height: 40px;
	/* or 51px */

	letter-spacing: -0.02em;

	color: #391a60;
`

export const BvnPageQuestion = styled.span`
	font-weight: 700;
	font-size: 12px;
	line-height: 30px;
`
export const BvnPageText = styled.p`
	font-weight: 200;
	font-size: 12px;
	line-height: 17px;
	color: #000000;
`

export const BvnForm = styled.form`
	/* background-color: red; */
	margin: 15px 0px;
`

export const BvnLabel = styled.label`
	/* background-color: red; */
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 700;
	font-size: 12px;
	line-height: 12px;
	letter-spacing: 0.025em;

	color: #000000;

	span {
		font-weight: 200;
	}
`

export const BvnHeaderDiv = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 50px;
	align-items: center;
`

export const ValidateButton = styled.button`
	width: 100px;
	height: 35px;
	border-radius: 10px;
	background: linear-gradient(73.6deg, rgba(57, 26, 96, 0.97) 4.94%, rgba(109, 0, 98, 0.97) 71.35%);
	margin-top: 15px;
	color: ${Colors.white};
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 700;
	font-size: 12px;
	line-height: 12px;
	letter-spacing: 0.025em;
`

export const InfoContentLeft = styled.form`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;

	${media.mobile`
width: calc(100vw - 20px);
`}
`
export const PersonalInfoHeader = styled.div`
	/* background-color: palegreen; */
	width: 65%;
	/* margin-left: 110px; */
	display: flex;
	flex-direction: column;
`
export const UserImage = styled.div``
export const UserImageText = styled.span`
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 700;
	font-size: 12px;
	line-height: 15px;
	letter-spacing: 0.025em;
	text-transform: capitalize;
	background: ${Colors.gradient};
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
`

export const InfoContentRight = styled.div`
	width: 40%;
	display: flex;
	justify-content: center;
	/* flex-direction: column; */
	/* align-items: center; */

	img {
		width: 400px;
	}

	${media.tablet`
  display: none;
`}
	${media.mobile`
  display: none;
`}
`

export const ContentImg = styled.div`
	margin-top: 80px;
	img {
		width: 500px;
		height: 500px;
	}
`

export const InputDiv = styled.div`
	display: flex;
	width: 100%;
	justify-content: center;
	${media.mobile`
	flex-direction: column;
	`}
`
export const InputWrap = styled.div`
	display: flex;
	flex-direction: column;
	padding: 10px 20px;
	width: 100%;

	${media.mobile`
  width: 100%;
  padding: 0px 10px;

`}
`

export const AddressDiv = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	overflow: visible;
	${media.mobile`
  width: 90%;
  `}
`

export const ProceedBtn = styled.button`
	width: 133px;
	height: 45px;
	margin-top: 20px;
	background: ${Colors.gradient};
	border-radius: 10px;
	color: ${Colors.white};
	cursor: pointer;
	${media.mobile`
	margin-bottom:2rem;
	`}
`

export const InfoContainer = styled.div`
	width: 100%;
	/* height: 100vh; */
	display: flex;
	flex-direction: column;
	align-items: center;
	${media.mobile`
	margin-top:2rem;
	`}
`
export const InfoContent = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	margin-top: 30px;
	padding: 0px 60px;

	${media.mobile`
  margin-top: 20px;
  width:calc(100vw - 30px); ;
  flex-direction: column-reverse;
  justify-content: center;
  `}
`

export const InfoText = styled.h1`
	font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 35px;
  line-height: 35px;
  color: #6d0062;
  margin-bottom: 10px;
  
  ${media.mobile`
    font-size: 25px;
    text-align: center;
  `}
`

export const FileContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
	margin-top: 120px;

	${media.mobile`
margin-top: 50px;
`}
`

export const FileUploadCon = styled.div`
	/* background-color: red; */

	${media.mobile`
  padding: 0px 30px;
  `}
`

export const FormatType = styled.p`
	font-size: 9px;
	margin-top: -10px;
	margin-bottom: 5px;
`
export const FileContentDiv = styled.div`
	width: 250px;
	height: 200px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`

export const FileHeaderText = styled.h1`
	/* Button Text */
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 800;
	font-size: 14px;
	line-height: 40px;
	letter-spacing: 0.025em;
	text-transform: capitalize;
	color: #000000;
`
export const FileContent = styled.div`
	width: 100%;
	background: #e7e7e7;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 20px 20px;
	cursor: pointer;

	/* padding-top: 10px; */

	img {
		width: 50px;
		height: 50px;
	}
`
export const FileDescText = styled.p`
	max-width: 95%;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 12px;
	letter-spacing: 0.025em;
	text-transform: capitalize;
	text-align: center;
	color: #000000;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;

	span {
		color: ${Colors.primary};
	}
`
export const FileFooterText = styled.p`
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 30px;
	letter-spacing: 0.025em;
	text-transform: capitalize;

	color: #000000;
`

export const PgdContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-top: 20px;
	padding-bottom: 30px;
`
export const PgdHeader = styled.h1`
	margin-left: 103px;
	font-family: 'Montserrat', sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 30px;
	line-height: 30px;
	letter-spacing: -0.02em;

	color: #391a60;
	${media.mobile`
	margin-left: 0px;
	text-align: center;
	margin-top:3rem;
	`}
`
export const PgdFormDiv = styled.div`
	display: flex;
	justify-content: space-around;
`
export const PgdFormContent = styled.div`
	display: flex;
	${media.mobile`
	flex-direction: column-reverse;
	`}
`
export const PgdFormLeft = styled.div`
	padding: 30px 20px;
	${media.mobile`
	margin-top: 20px;
	`}
`
export const PgdFormRight = styled.div`
	padding: 30px 20px;
	${media.mobile`
	margin-top: 20px;
	`}
`
export const PgdFormImage = styled.div`
	${media.mobile`
	display:none;
	`}
`

export const AppFeeCon = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 60px 0px;
`

export const AppFeeHeader = styled.h1`
	font-weight: 700;
	font-size: 40px;
	line-height: 60px;
	letter-spacing: -0.02em;
`

export const AppFeePara = styled.p`
	text-align: center;
	padding: 0px 30px;
	${media.mobile`
	padding: 0px 10px;
  `}
`
export const AppFeeParaDiv = styled.div`
	width: 60%;
	margin: 0 auto;
	${media.mobile`
  width: 100%;
  `}
`
export const AppFeeImg = styled.div`
	padding: 20px 0px;
	width: 200px;
	img {
		width: 100%;
	}
`
export const FormsModal = styled.div`
	height: 100%;
	top: 0%;
	left: 0%;
	right: 0%;
	bottom: 0%;
	display: flex;
	justify-content: center;
	align-items: center;
	background: ${({ show }) => (show ? "rgba(0, 0, 0, 0.5)" : "#4ef3af")};
	position: fixed;
	transition: all 0.5s ease-out;
	animation-name: ${SlideInPopUp};
	animation-duration: 0.5s;
	z-index: 600;
`
export const ViewDetailBtn = styled.button`
	height: 35px;
	padding: 0px 10px;
	background: ${Colors.primary};
	color: ${Colors.white};
	cursor: pointer;
	border-radius: 3px;
	animation: ${BlinkingBtn} 20s infinite alternate;
`
export const ReviewContainer = styled.div`
  max-width:900px;
  margin:32px auto;
  padding:8px;
`

export const ReviewDetailSection = styled.div`
	margin:10px auto;
	width:100%;
	padding-bottom:16px;
`

export const ReviewRow = styled.div`
 	display:flex;
	margin:8px 0px;
	gap:16px;
`

export const ReviewField = styled.div`
	flex:1;
`
export const ReviewFieldTitle = styled.div`
   font-weight:600;
   font-size:16px
`;
export const ReviewFieldValue = styled.div`
   font-weight:200;
`;

export const MainHeading = styled.div`
	text-align:center;
	margin-bottom:20px;
	h4{
		font-size:32px;
		color:#391a60;
	};
	p{
	 font-size:18px;
	}
`

export const ContinueButton = styled.button`
	border:none;
	background:#391a60;
	padding:16px 36px;
	font-size:18px;
	font-weight:500;
	color:#fff;
	border-radius:10px;
`

export const ButtonContainer = styled.div`
	display:flex;
	align-items:center;
	gap:8px;
	justify-content:space-between;
`