import { Img } from "globalStyles"
import authStore from "mobx/AuthStore"
import React, { useEffect, useState } from "react"
import { Link, Outlet, useNavigate } from "react-router-dom"
import { DASHBOARD_ROUTE, LOGIN_ROUTE } from "routes"
import { BASE_URL } from "services"
import http, { setJwt } from "services/httpService"
import useSWR from "swr"
import { AuthHamburger, AuthNavUser, ByshelbDescLogo } from "utils/assets"
import AuthSideNav from "./SideBar"
import { AuthNavContainer, AuthNavLogo, AuthNavLogoMain, AuthNavRight, AuthNavText, AuthUserIcon } from "./style"
import { observer } from "mobx-react-lite"

const AuthNav = () => {
	const [isOpen, setIsOpen] = useState(false)
	const navigate = useNavigate()
	function toggleAuthNav() {
		setIsOpen(!isOpen)
	}
	useEffect(() => {
		const status = authStore.loggedInStatus()
		const userStatus = sessionStorage.getItem("token")
		userStatus ? setJwt(userStatus) : setJwt("")
		// console.log("Hello login status", status)
		if (!status) {
			navigate(`${LOGIN_ROUTE}`)
		}
		window.scrollTo(0, 0)
	}, [navigate])

	const url = `${BASE_URL}/users/who_i_am`
	const fetcher = async () => {
		// authStore.loggedInStatus()
		const res = await http.get(url)
		return res?.data?.data
	}

	const { data: userProfile } = useSWR(url, fetcher)

	return (
		<>
			{isOpen && <AuthSideNav isOpen={isOpen} user={userProfile} onClick={() => toggleAuthNav()} />}
			<AuthNavContainer>
				<AuthNavLogoMain>
					<Link className="home-link" to={DASHBOARD_ROUTE}>
						<Img src={ByshelbDescLogo} alt="BSHELF" />
					</Link>
				</AuthNavLogoMain>
				<AuthNavLogo onClick={() => toggleAuthNav()}>
					<Img src={AuthHamburger} alt="byshelb" />
				</AuthNavLogo>
				<AuthNavRight>
					<AuthNavText>
						<p className="student__name">Welcome {userProfile?.surname}</p>
						<span className="student__category">Student</span>
					</AuthNavText>
					<AuthUserIcon>
						<Img src={userProfile?.avatar ? userProfile?.avatar : AuthNavUser} alt="byshelb" />
					</AuthUserIcon>
				</AuthNavRight>
			</AuthNavContainer>
			<Outlet />
		</>
	)
}

export default observer(AuthNav)
