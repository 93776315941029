// import { SideImageDiv } from "components/AuthNav/SideBar/style"
import { UploadsLoader } from "components/FullScreenLoader"
// import { Div, Img } from "globalStyles"
import React from "react"
import { useState, useEffect } from "react"
import http from "services/httpService"
import useSWR from "swr"
// import { AuthNavUser } from "utils/assets"
import NextOfKinPage from "../Apply/NextOfKinPage"
// import ProfiletabBtn, { AButtonContainer, ScrollDiv } from "./ProfileBtn"

// import { ProfileContainer, ProfileDiv, ProfileDivContainer, ProfileLable, ProfileRightDiv, ProfileUserName } from "./style"
import apiRoute from "routes/apirRoutes"
import toast from "react-hot-toast"
import { checkOnBoarding } from "services/MiscService"
// import { DASHBOARD_NEXTOFKINROUTE, DASHBOARD_PAYMENT_DETAILS_ROUTE } from "routes"
// import { useNavigate } from "react-router-dom"
import ProfileForm from "./ProfileForm"
import PaymentDetails from "../Payment"
// import { FormatColorResetOutlined } from "@mui/icons-material"

function Onboarding(btnShow = true) {
	// const navigate = useNavigate();
	const [currentStep, setCurrentStep] = useState(0)
	const [loading, setLoading] = useState(false)

	const next=()=>{
		if(currentStep < 3){
			setCurrentStep(prev=>prev + 1)
		}
	}
	const prev=()=>{
		if(currentStep > 0){
			setCurrentStep(prev=>prev - 1)
		}
	}
	// const url = `${BASE_URL}/users/who_i_am`
	const fetcher = async () => {
		const res = await http.get(apiRoute.whoIAm)
		return res?.data?.data
	}

	const { data: userProfile } = useSWR(apiRoute.whoIAm, fetcher)
	
	const handleOnBoarded = async () => {
		setLoading(true)
		const info = await checkOnBoarding();
		setLoading(false)
		if(info?.error) {
		  toast.error(info.error);
		}else{
		  // reroute to onboarding page
		   if(!info.onBoarded && info.nextStep === "next_of_kin"){
			// navigate(DASHBOARD_NEXTOFKINROUTE)
			return setCurrentStep(1)

		  }
		  if(!info.onBoarded && info.nextStep === "bank_account"){
			// navigate(DASHBOARD_PAYMENT_DETAILS_ROUTE)
			return setCurrentStep(2)
		  }
		}
	  }
	useEffect(()=>{
		handleOnBoarded();
	},[]);

	const steps = [
		"Personal Details",
		"Next of Kin Details",
		"Payment Details"
	]

	if (!userProfile || loading) return <UploadsLoader/>

	const StepComponents = [
		<ProfileForm next={next} user={userProfile} currentStep={currentStep} show ={btnShow} />,
		<NextOfKinPage next={next} prev={prev} user={userProfile} loanType="user"  show ={btnShow}  />,
		<PaymentDetails next={next} prev={prev} onboarded={false} />
	]
	return (
		<div>
			<header className="flex sticky top-0 bg-white z-[100] py-4 pt-8 -mt-[60px] mb-8 justify-center items-center gap-8">
				{steps.map((step,index) => (
					<div className="flex flex-col items-center">
					<button className={`text-poppins h-8 w-8 rounded-full border border-solid border-secondary-700 ${currentStep === index?"bg-secondary-800 text-white":"text-secondary-800"} flex items-center justify-center`}>{index + 1}</button>
					<p className="font-poppins text-center">{step}</p>
				</div>
				))}
			</header>
			{StepComponents[currentStep]}
		</div>
	)
}

export default Onboarding
