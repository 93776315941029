import React from "react"
import UserProfile from "components/Dashboard/Profile"
import AuthLayout from "layout/AuthLayout"
import { MainContainer } from "globalStyles"

const ProfilePage = () => {
	return (
		<AuthLayout>
			<MainContainer>
				<UserProfile />
			</MainContainer>
		</AuthLayout>
	)
}

export default ProfilePage
