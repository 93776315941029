import { Link } from "react-router-dom"
import styled, { keyframes } from "styled-components"
import { AppLogo } from "utils/assets"
import Colors from "utils/colors"
import media from "utils/media"
const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`
const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
	width: 100%;
	z-index: 100000;
	position: fixed;
	background: rgba(129, 11, 147, 0.8);
`

const Title = styled.h1`
	font-size: 6rem;
	font-weight: bold;
	margin: 0;
	text-align: center;
	color: #ff2e63;
	text-shadow: 2px 2px #ffe4e1;
`

const Subtitle = styled.h2`
	font-size: 2rem;
	font-weight: bold;
	margin: 0;
	text-align: center;
	color: ${Colors.primary};
	text-shadow: 1px 1px #ffe4e1;
`

const Description = styled.p`
	font-size: 1.2rem;
	margin: 1rem 0;
	text-align: center;
	color: #ffe4e1;
	max-width: 60%;
	${media.smallDesktopMinimum`
    max-width:80%;
    font-size: 1.2rem;
    `}
	${media.tablet`
    max-width:80%;
    font-size: .9rem;
    `}
	${media.mobile`
    max-width:90%;
    font-size: .9rem;
    `}
`

const Button = styled(Link)`
	font-size: 1.2rem;
	padding: 1rem 2rem;
	border-radius: 0.5rem;
	background-color: #ff2e63;
	text-decoration: none;
	color: #fff;
	text-decoration: none;
	transition: all 0.3s ease-in-out;

	&:hover {
		transform: translateY(-5px);
		box-shadow: 0 5px 15px rgba(255, 46, 99, 0.5);
	}
`

const ErrorImage = styled.img`
	width: 400px;
	height: auto;
	margin-bottom: 2rem;
	animation: ${rotate360} 30s linear infinite;
	${media.smallDesktopMinimum`
    width:70%;
    `}
	${media.tablet`
    width:70%;
    `}
	${media.mobile`
    width:70%;
    `}
`
const ImageWrapper = styled.div`
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
	width: 100vw;
	z-index: 1000;
	/* back */
`

const NotFoundPage = () => {
	return (
		<ImageWrapper>
			<Container>
				<Title>404</Title>
				<Subtitle>Page Not Found</Subtitle>
				<Description>Sorry, but the page you are looking for has not been found. Try checking the URL for errors or hit the button below to go back to the homepage.</Description>
				<Button to="/">Go back to homepage</Button>
			</Container>
			<ErrorImage src={AppLogo} alt="404 Error" />
		</ImageWrapper>
	)
}

export default NotFoundPage
