// import { message } from "antd";
import { useFlutterwave } from "flutterwave-react-v3";
import { useState } from "react";

const CONFIG = {
    APPLICATION_FEE: process.env.REACT_APP_APPLICATION_FEE,
    FLUTTERWAVE_SECRET_KEY: process.env.REACT_APP_FLUTTERWAVE_SECRET_KEY,
    FLUTTERWAVE_PUBLIC_KEY: process.env.REACT_APP_FLUTTERWAVE_PUBLIC_KEY,
    BASE_URL: process.env.REACT_APP_BASE_URL,
}


export function usePayment({userProfile,callback}){
    const [loading,setLoading] = useState(false)
    const config = {
        public_key: CONFIG.FLUTTERWAVE_PUBLIC_KEY, //"FLWPUBK_TEST-e6194fcdae04fbd8bf253480c634cd90-X",
        tx_ref: Date.now(),
        amount: CONFIG.APPLICATION_FEE, //2000,
        currency: 'NGN',
        payment_options: 'card, mobilemoney, ussd',
        customer: {
          email: userProfile?.email,
          phonenumber: userProfile?.phone,
          name: `${userProfile?.first_name} ${userProfile?.surname}`,
        },
        customizations: {
          title: 'Proceed to Make Payment',
          description: 'Payment for loan application fee',
        //   logo: 'https://res.cloudinary.com/dxsksma2c/image/upload/v1670423940/logo_mgwsvi.svg',
        },
      }
    const handlePayment = useFlutterwave(config)

    const startPayment=()=>{
        setLoading(true)
        handlePayment({
            onClose:()=>{
                setLoading(false)
            },
            callback,
        })
    }

    return {
        loading,
        setLoading,
        startPayment
    }
}

export default CONFIG;