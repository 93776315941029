import Newsletter from "components/Newsletter"
import { AppLink } from "globalStyles"
import React from "react"
import { SIGNUP_ROUTE } from "routes"
import Board from "./Board"
import { AboutContainer, AboutHeroDiv, AboutSectionOne, AboutSectionTwo, HeroDetailsDiv, SectionOneLeft, SectionOneRight } from "./style"

const AboutComp = () => {
	return (
		<AboutContainer>
			<AboutHeroDiv>
				<HeroDetailsDiv>
					<h3>
						Welcome to <br /> BYSHELB
					</h3>
					<p>We are committed to providing the needed financial support to Bayelsan students to enable them scale their educational career.</p>
					<AppLink to={SIGNUP_ROUTE}>
						<button>Get a Loan</button>
					</AppLink>
				</HeroDetailsDiv>
			</AboutHeroDiv>
			<AboutSectionOne>
				<SectionOneLeft>
					<h3 className="title-gothrough">
						Go through <br /> School with <br /> ease
					</h3>
					<p className="desc-gothrough">Go through school without stress</p>
				</SectionOneLeft>

				<SectionOneRight>
					<h3 className="title-brand-header">Brand Story</h3>
					<p className="desc-brand">
						BYSHELB was launched by Sen. Henry Seriake Dickson, currently sustained and run by the prosperity administration led by His Excellency Sen. Douye Diri to guarantee educational empowerment
						in Bayelsa state.
					</p>
					<br />
					<h3 className="title-brand-header">Trusted By</h3>
					<p className="desc-brand">We have distributed loans to over 5,000 Bayelsans studying across Nigerian institutions.</p>
				</SectionOneRight>
			</AboutSectionOne>

			<AboutSectionTwo>
				<p>
					The Bayelsa State Higher Education Loan Board is a higher education loan scheme designed to offer assistance to indigent students of Bayelsa state origin studying in institutions of higher
					learning in the country for the payment of tuition fees and other related expenses.
				</p>

				<p>
					We offer interest-free loans to Bayelsans studying in any Nigerian university. As at February 2022, we have successfully paid tuition fees for 5,000 Bayelsa students across Nigerian
					universities. The scheme was launched in 2019 by His Excellency,
				</p>
				<p className="seriake">Sen. Henry Seriake Dickson.</p>
			</AboutSectionTwo>
			<Board />
			<Newsletter />
		</AboutContainer>
	)
}

export default AboutComp
