import React, { useEffect } from "react"

import { LogoutDiv, LogoutdropContainer } from "./style"
import loading from "assets/images/logo.svg"

import { useNavigate } from "react-router-dom"
import { Img } from "globalStyles"
import { HOME_ROUTE } from "routes"
import { observer } from "mobx-react-lite"
import authStore from "mobx/AuthStore"

function LogoutPage() {
	// const { setUser } = useAuthContext()
	const navigate = useNavigate()

	useEffect(() => {
		setTimeout(() => {
			authStore.logoutUser()
			navigate(`${HOME_ROUTE}`)
		}, 2000)
	})
	return (
		<LogoutdropContainer show={true}>
			<LogoutDiv>
				<Img width="100px" height="30px" src={loading} alt="Logout" />
				<p>Logging out...</p>
			</LogoutDiv>
		</LogoutdropContainer>
	)
}

export default observer(LogoutPage)
