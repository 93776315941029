import ContactComponent from "components/Dashboard/Apply/Contact"
import Mainlayout from "layout/MainLayout"
import React from "react"

function ContactPage() {
	return (
		<Mainlayout>
			<ContactComponent />
		</Mainlayout>
	)
}

export default ContactPage
