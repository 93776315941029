import { message, Skeleton } from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getUserNotifications } from "services/UserService";
import useLoanDetail from "../Apply/LoanReview/useLoanDetail";

function useNotifications(){
    const [loading,setLoading] = useState(false);
    const [notifications, setNotifications] = useState([]);

    useEffect(()=>{
        async function getNotifications(){
            setLoading(true);
            const [data,err]= await getUserNotifications()
            setLoading(false);
            if(err){
                message.error(err.message)
                return;
            }
            setNotifications(
                data.data.sort((n1,n2)=>{
                    const d1 = new Date(n1.createdAt);
                    const d2 = new Date(n2.createdAt);
                    return d2 - d1;
                })
            );
        }
        getNotifications()
    },[])

    return{notifications,loading}
}

function Loader(){
    const loaderCounter = new Array(3).fill("").map((item,i)=>`notification-loader-${i}-${item}`)
    return (
        <ul className="h-[220px] overflow-y-auto">
            {loaderCounter.map(loaderCount=>(
                <li key={loaderCount} className="p-2 m-2 mx-4 border-l-4"><Skeleton.Button active block style={{height:"70px"}}/></li>
            ))}
        </ul>
    )
}

function Empty(){
    return (
        <div className="text-center h-[140px] flex items-center justify-center">
            <div>
                <p className="font-poppins text-gray-700 text-lg font-semibold">You don't have any Notification</p>
            </div>
        </div>
    )
}

function Alert(props){
    const{message,subject,messageData,message_type} = props
    console.log(props)
    const alertMessage = message.length > 100?`${message.slice(0,100)}...`:message;
    const payload = JSON.parse(messageData)
    const {loan_id} = payload?payload:{loan_id:""}
    const {loading,loan} = useLoanDetail(loan_id)
    const isDraft = (message_type === "loan draft")
    return (
        <li className="p-2 pl-3 m-2 mx-4 border-l-4 bg-primary-50 border-solid border-primary-700">
            <p className="font-poppins text-primary text-lg font-semibold capitalize">{subject}</p>
            <p className="font-poppins text-primary text-sm">{alertMessage}</p>
            <div className="text-right my-1">
                {!loading && loan_id && (
                    isDraft && loan ?
                    (<Link to={`/dashboard/apply/${loan.loan_type}/${loan_id}`}>
                        <button disabled={loading} className="text-sm text-primary-800 font-semibold">See Loan</button>
                    </Link>)
                    :(<Link to={`/dashboard/apply/details/${loan_id}`}>
                        <button disabled={loading} className="text-sm text-primary-800 font-semibold">See Loan</button>
                    </Link>)
                )}
            </div>
        </li>
    )
}



export default function Notification(){
    const {notifications,loading} = useNotifications();

    return(
        <div className="bg-white pb-10 border border-solid border-gray-300 rounded-lg">
            <header className="flex justify-between sticky border-solid top-0 bg-white py-2 px-2 border-b border-secondary-200">
                <p className="flex gap-2 text-sm sm:text-lg text-primary items-center">
                    <span><i className="bi bi-bell"></i></span>
                    <span className="font-medium">Notifications ({notifications.length})</span>
                </p>
                <button className="bg-primary-100 border border-solid border-primary-500 text-primary-600 px-4 py-2 text-xs sm:text-sm rounded-full">See All</button>
            </header>
            {loading
                ?<Loader/>
                :notifications.length > 0
                ?(<ul className="h-[220px] overflow-auto">
                    {notifications.map(notification=>(<Alert {...notification}/>))}
                </ul>)
                :<Empty/>
            }
        </div>
    )
}