import React from "react"
import LoanComp from "./Loan"
import BlogComp from "./Blog"
import Newsletter from "components/Newsletter"
import { observer } from "mobx-react-lite"
import HeroComp from "./HeroSection"

const HomeComp = () => {
	return (
		<>
			<HeroComp />
			<LoanComp />
			<BlogComp />
			<Newsletter />
		</>
	)
}

export default observer(HomeComp)
