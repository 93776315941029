import React from "react"
import styled from "styled-components/macro"
import media from "utils/media"
// import { Img } from "globalStyles"
import Colors from "utils/colors"

const TabBtnProfile = styled.button`
	width: calc(180px - 20px);
	height: 39px;
	padding: 0px 10px;
	margin-top: 0.5rem;
	display: flex;
	align-items: center;
	outline: none;
	border: none;
	color: ${Colors.primaryDark};
	background: ${({ active }) => (active ? "#C4C4C4" : "#ffffff")};

	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
	border-radius: 3px;
	cursor: pointer;
	img {
		margin-right: 15px;
	}
	p {
		margin-left: 10px;
	}
	${media.mobile`
	width: 45%;
	flex: 0 0 45%;
	/* margin-left: 5px; */
	`}
`

export const ScrollDiv = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	/* box-sizing: content-box; */
	/* overflow: hidden; */
	overflow-x: scroll;
	gap: 15px;
	/* z-index: 300; */
	::-webkit-scrollbar {
		width: 0;
		background: transparent;
	}
	::-webkit-scrollbar-thumb {
		background: transparent;
	}
`
export const AButtonContainer = styled.div`
	width: 100%;
	margin-top: 0.5rem;
	display: flex;
	align-items: center;
	/* justify-content: center; */
	/* position: relative; */
	height: 70px;
	${media.smallDesktopMinimum`
	width: 100%;
	
	`}
	${media.tablet`
	width: 100%;
	
	`}
	${media.mobile`
	width: 100%;
	
	`}
`

function ProfiletabBtn({ title = "Profile", iconType = "kin", onClick, ImgUrl, active = false, show = true}) {
	if ( show)
	return (
		<TabBtnProfile onClick={onClick} active={active}>
			{/* <Img src={ImgUrl} alt="Toggle_Icon" /> */}
			{iconType === "person" && (
				<svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M17.8412 9.35L16.8333 10.35L14.7669 8.3L15.7749 7.3C15.8785 7.19923 16.0178 7.14278 16.163 7.14278C16.3081 7.14278 16.4474 7.19923 16.551 7.3L17.8412 8.58C18.0529 8.79 18.0529 9.14 17.8412 9.35ZM8.06384 14.94L14.1722 8.88L16.2386 10.93L10.1403 17H8.06384V14.94ZM8.06384 10C3.60857 10 0 11.79 0 14V16H6.04788V14.11L10.0798 10.11C9.41453 10.03 8.73919 10 8.06384 10ZM8.06384 0C6.99451 0 5.96897 0.421427 5.21284 1.17157C4.45671 1.92172 4.03192 2.93913 4.03192 4C4.03192 5.06087 4.45671 6.07828 5.21284 6.82843C5.96897 7.57857 6.99451 8 8.06384 8C9.13317 8 10.1587 7.57857 10.9148 6.82843C11.671 6.07828 12.0958 5.06087 12.0958 4C12.0958 2.93913 11.671 1.92172 10.9148 1.17157C10.1587 0.421427 9.13317 0 8.06384 0Z"
						fill="#6D0062"
					/>
				</svg>
			)}
			{iconType === "kin" && (
				<svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M14.1667 11.25H10.3889V13.125H14.1667V15L17 12.1875L14.1667 9.375V11.25ZM7.55556 0C5.47778 0 3.77778 1.6875 3.77778 3.75C3.77778 5.8125 5.47778 7.5 7.55556 7.5C9.63333 7.5 11.3333 5.8125 11.3333 3.75C11.3333 1.6875 9.63333 0 7.55556 0ZM7.55556 9.375C3.4 9.375 0 11.0625 0 13.125V15H8.97222C8.68889 14.25 8.5 13.5 8.5 12.6562C8.5 11.5312 8.78333 10.5 9.35 9.46875C8.78333 9.46875 8.21667 9.375 7.55556 9.375Z"
						fill="#6D0062"
					/>
				</svg>
			)}

			<p>{title}</p>
		</TabBtnProfile>
	)
}

export default ProfiletabBtn
