import React from "react"
import PaymentDetails from "components/Dashboard/Payment"

import AuthLayout from "layout/AuthLayout"

const PaymentPage = () => {
	return (
		<AuthLayout>
			<PaymentDetails onboarded/>
		</AuthLayout>
	)
}

export default PaymentPage
