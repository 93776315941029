import HomeComp from "components/Home"
import Mainlayout from "layout/MainLayout"
import React from "react"

function HomePage() {
	return (
		<Mainlayout>
			<HomeComp />
		</Mainlayout>
	)
}

export default HomePage
