import { Img } from "globalStyles"
import React from "react"
import { SectionLoaderIcon } from "utils/assets"
import { FullScreenLoaderContainer } from "./style"
import "./style.css"
import { Skeleton, Spin } from "antd"

function FullScreenLoader({ show }) {
	return (
		<FullScreenLoaderContainer show={show}>
			<div>
				<Img src={SectionLoaderIcon} alt="Loading" />
			</div>
		</FullScreenLoaderContainer>
	)
}

export function FormLoader(){
	return (
		<div className="flex items-center">
			<div className="">
				<Spin/>
			</div>
		</div>
	)
}

export function UploadsLoader(){
	return (
		<section className="p-8 py-2">
			<div className="max-w-xl mx-auto">
				<div className="my-4 ">
					<Skeleton active paragraph={{rows:1}}/>
					<div className="my-2">
						<Skeleton.Button active block style={{height:"130px"}}/>
					</div>
				</div>
				<div className="my-4 max-w-[700px] mx-auto">
					<Skeleton  active paragraph={{rows:1}}/>
					<div className="my-2">
						<Skeleton.Button active block style={{height:"130px"}}/>
					</div>
				</div>
				<div className="my-4 max-w-[700px] mx-auto">
					<Skeleton active paragraph={{rows:1}}/>
					<div className="my-2">
						<Skeleton.Button active block style={{height:"130px"}}/>
					</div>
				</div>
				<div className="my-4 max-w-[700px] mx-auto">
					<Skeleton active paragraph={{rows:1}}/>
					<div className="my-2">
						<Skeleton.Button active block style={{height:"130px"}}/>
					</div>
				</div>
			</div>
		</section>
	)
}

export default FullScreenLoader
