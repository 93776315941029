function getDaySuffix(day) {
	if (day >= 11 && day <= 13) {
		return "th"
	}
	switch (day % 10) {
		case 1:
			return "st"
		case 2:
			return "nd"
		case 3:
			return "rd"
		default:
			return "th"
	}
}

export function formatDate(date) {
	// create an array of month names
	if (!date) return ""
	const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

	// get the month name from the array
	const monthName = months[date.getMonth()]

	// get the day of the month and add a suffix
	const day = date.getDate()
	const suffix = getDaySuffix(day)

	// get the year
	const year = date.getFullYear()

	// create the formatted date string
	const formattedDate = `${monthName} ${day}${suffix}, ${year}`

	return formattedDate
}

// helper function to add a suffix to the day of the month
