import { useFormik } from "formik"
import * as Yup from "yup"
import TextInput from "components/TextInput"
import { Img } from "globalStyles"
import React from "react"
import { AppLogo2 } from "utils/assets"
import { AuthHeader } from ".."
import { LoginContainer, LoginDiv, LoginFormContainer } from "../Login/style"
import { AuthCard, Authcontainer, AuthContent } from "../style"
import { CreateAccountButton, VerifyExpires, VerifyResendBtn } from "./style"
import authStore from "mobx/AuthStore"
import { observer } from "mobx-react-lite"
import { resendOtpEnd, verifyOtpEmail } from "services/AuthService"
import { LOGIN_ROUTE } from "routes"
import { useNavigate } from "react-router-dom"

function VerifyOtp({ onClick }) {
	const navigate = useNavigate()
	const formik = useFormik({
		enableReinitialize: false,
		initialValues: {
			otp: ""
		},
		onSubmit: async (data, helpers) => {
			// authStore.loading()
			const response = await verifyOtpEmail(data)
			if (!response.error) {
				helpers.resetForm()
				navigate(`${LOGIN_ROUTE}`)
			}
			// console.log(response, "verify OTP")
			// if (response.status === "success") {
			// 	helpers.resetForm()
			// }
		},
		validationSchema: Yup.object({
			otp: Yup.string().min(6).max(6).required().label("Token")
		})
	})

	async function resendOtpUser() {
		authStore.getUserEmail()
		const response = await resendOtpEnd({ email: authStore.userEmail })
		// console.log("Response Resend", response)
	}
	return (
		<Authcontainer>
			<AuthContent>
				<Img src={AppLogo2} alt="BSL" />
				<LoginContainer>
					<LoginDiv onSubmit={formik.handleSubmit}>
						<AuthCard>
							<LoginFormContainer>
								<AuthHeader text="Verify Email Address" />
								<TextInput
									label="One Time Password (OTP)"
									name="otp"
									id="otp"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.otp}
									error={formik.errors.otp}
									visible={formik.touched.otp}
									placeholder="***********"
									mt="0.5rem"
								/>
								<VerifyExpires>OTP expires in 120 seconds</VerifyExpires>
								<VerifyResendBtn disabled={authStore.resendingOtp} type="button" onClick={() => resendOtpUser()}>
									{authStore.resendingOtp ? "Resending Otp..." : "Resend OTP"}
								</VerifyResendBtn>
								<CreateAccountButton type="submit" disabled={authStore.isLoading ? true : false}>
									{authStore.isLoading ? "Loading..." : "Continue"}
								</CreateAccountButton>
							</LoginFormContainer>
						</AuthCard>
					</LoginDiv>
				</LoginContainer>
			</AuthContent>
		</Authcontainer>
	)
}

export default observer(VerifyOtp)
