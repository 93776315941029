import React from "react"
import { Link, useLocation } from "react-router-dom"
import { MenuIcon, MenuIconContainer, MenuItem, MenuText } from "./style"

export default function BigMenu({ icon, text, isActive, link, active, onToggleNa }) {
	const location = useLocation()

	return (
		<Link to={link} onClick={onToggleNa}>
			<MenuItem active={location?.pathname === link ? true : link.includes(location.pathname.split("/")[2]) ? true : false}>
				<MenuIconContainer>
					<MenuIcon src={icon} />
				</MenuIconContainer>
				<div display="flex" justify="space-between">
					<MenuText>{text}</MenuText>{" "}
				</div>
			</MenuItem>
		</Link>
	)
}
