import NextOfKinPage from 'components/Dashboard/Apply/NextOfKinPage'
import { MainContainer } from 'globalStyles'
import AuthLayout from 'layout/AuthLayout'
import React from 'react'

function NOKPage() {
  return (
    <AuthLayout>
      <MainContainer>
        <NextOfKinPage />
      </MainContainer>
    </AuthLayout>
  )
}

export default NOKPage
