import { Img } from 'globalStyles'
import { useKeenSlider } from 'keen-slider/react'
import 'keen-slider/keen-slider.min.css'
import React from 'react'
import {
  BlogContainer,
  NewsHeader,
  PostCard,
  PostCardOver,
  PostContainer,
} from './style'
import { BASE_URL } from 'services'
import useSWR from 'swr'
import http from 'services/httpService'
import { Link } from 'react-router-dom'

const BlogComp = () => {
  const [postRef] = useKeenSlider({
    initial: 0,
    breakpoints: {
      '(min-width: 320px)': {
        slides: { perView: 1, spacing: 0 },
      },
      '(min-width: 400px)': {
        slides: { perView: 1, spacing: 0 },
      },
      '(min-width: 411px)': {
        slides: { perView: 1, spacing: 0 },
      },
      '(min-width: 760px)': {
        slides: { perView: 3, spacing: 0 },
      },
      '(min-width: 1000px)': {
        slides: { perView: 3, spacing: 0 },
      },
    },
    slides: { perView: 1, spacing: 0 }, // Set initial slides perView to 1
  })

  const url = `${BASE_URL}/blog`
  const fetcher = async () => {
    const res = await http.get(url)
    return res?.data?.data
  }
  const { data: blogPosts } = useSWR(url, fetcher)

  const data_blogs = blogPosts ? blogPosts : []

  // Determine the number of posts to show based on the active screen size
  const numPostsToShow = window.innerWidth >= 760 ? 3 : 1
  const visiblePosts = data_blogs?.slice(0, numPostsToShow)
  return (
    <BlogContainer>
      {data_blogs && data_blogs.length >= 3 && (
        <NewsHeader>
          <h3>
            What's new with <span>BYSHELB</span>?
          </h3>
        </NewsHeader>
      )}
      <PostContainer>
        <div ref={postRef} className="keen-slider">
          {visiblePosts?.map((post) => (
            <PostCard key={post.id} className="keen-slider__slide">
              <Img src={post?.image?.url} alt={post?.title} />
              <PostCardOver>
                <div>
                  <Link to={`/blog/posts/${post?.blogUrl}/${post?.id}`}>
                  <h3 className="blog-title">{post?.title}</h3>
                    <span>Read more...</span>
                  </Link>
                </div>
              </PostCardOver>
            </PostCard>
          ))}
        </div>
      </PostContainer>
    </BlogContainer>
  )
}

export default BlogComp
