import { Img } from "globalStyles"
import React from "react"
import { Link } from "react-router-dom"
import { ABOUT_ROUTE, BLOG_ROUTE, FAQ_ROUTE, GET_LOAN_ROUTE, HOME_ROUTE } from "routes"
import { AppLogo2, FacebookIcon, InstagramIcon, LinkedinIcon, LocationIcon, TwitterIcon } from "utils/assets"
import FooterItem from "./FooterItem"
import Socials from "./Socials"
import { FooterContainer, FooterContentContainer, FooterDiv, FooterLeft, FooterRight, FooterUl, SocialDivFooter } from "./style"

function Footer() {
	return (
		<FooterContainer>
			<FooterContentContainer>
				<FooterDiv>
					<FooterLeft>
						<FooterUl>
							<li className="bottom-link-start">
								<Link className="links-footer" to={HOME_ROUTE}>
									Home
								</Link>
							</li>
							<li className="links-footer">
								<Link className="links-footer" to={GET_LOAN_ROUTE}>
									Get A Loan
								</Link>
							</li>
							<li className="links-footer">
								<Link className="links-footer" to={ABOUT_ROUTE}>
									About Us
								</Link>
							</li>
							<li className="links-footer">
								<Link className="links-footer" to={FAQ_ROUTE}>
									FAQs
								</Link>
							</li>
							<li className="links-footer">
								<Link className="links-footer" to={BLOG_ROUTE}>
									Blog
								</Link>
							</li>
						</FooterUl>
						<div>
							<Img src={AppLogo2} alt="BSL" />
						</div>
						<p className="desc-text">Welcome to BYSHELB a Bayelsa State Government initiative that provides interest free loans to its indigines studying in Nigerian public higher institutions.</p>
						<p className="copyright-text">© Copyright {new Date().getFullYear()} | Byshelb</p>
					</FooterLeft>
					<FooterRight>
						<FooterItem icon={LocationIcon} text="Okaka Estate, off Sani Abacha Express Way, Yenagoa" />
						<br />
						<SocialDivFooter>
							<Socials icon={TwitterIcon} title="twitter" link="http://facebook.com" />
							<Socials icon={FacebookIcon} title="facebook" link="http://facebook.com" />
							<Socials icon={InstagramIcon} title="Instagram" link="http://facebook.com" />
							<Socials icon={LinkedinIcon} title="linkedin" link="http://facebook.com" />
						</SocialDivFooter>
					</FooterRight>
				</FooterDiv>
			</FooterContentContainer>
		</FooterContainer>
	)
}

export default Footer
