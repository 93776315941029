import { BASE_URL } from "services";

const apiRoute = {
    login: `${BASE_URL}/auth/login`,
    register: `${BASE_URL}/auth/register`,
    whoIAm: `${BASE_URL}/users/who_i_am`,
    notifications: `${BASE_URL}/users/notifications`,
    loanUser: `${BASE_URL}/loans/user`,
    onBoarding: `${BASE_URL}/users/onboarding`,
    addNextOfKin: `${BASE_URL}/loans/undergraduate/next_of_kin`,
    updateNextOfKin: `${BASE_URL}/users/next_of_kin`,
    banks:`${BASE_URL}/misc/banks`,
    updateBankDetails:`${BASE_URL}/users/bank_details`,
    addLoanInstition:`${BASE_URL}/loans/institution`,
    post_graduate_institution:`${BASE_URL}/loans/postgraduate/institution`,
    edit_institution:`${BASE_URL}/loans/edit/institution`,
    resolveBank:`${BASE_URL}/misc/resolve_bank`,
    currentLoanProgressStage:`${BASE_URL}/loans/progress_stage`,
}
export const onBoardStep = {
    profile:"profile",
    next_of_kin:"next_of_kin",
    bank_account:"bank_account",
    completed:"dashboard",
}

 export default apiRoute;