import { Spin } from "antd";
import { UndergraduateLoanStepContext } from "context/LoanStepContext";
import { useContext } from "react";

export default function FooterButtons({loading}){
    const {prev,step} = useContext(UndergraduateLoanStepContext)
    return (
        <footer className="flex py-2 gap-2 px-4 pt-4 border-t border-gray-200 justify-end border-solid sticky bottom-0 bg-gray-50">
                {step > 1 && (
                    <button onClick={prev} className="bg-secondary-50 border border-solid border-secondary-500 rounded-full py-2 text-secondary-500 disabled:opacity-60 disabled:cursor-not-allowed  px-6" type="button" disabled={loading}>
                        <span>Back</span>
                    </button>
                )}
				<button className="bg-secondary-50 border border-solid border-secondary-500 rounded-full py-2 text-secondary-500 disabled:opacity-60 disabled:cursor-not-allowed  px-6" type="submit" disabled={loading}>
					{loading
					?<Spin/>:
					<span>Proceed</span>}
				</button>
        </footer>
    )
}