import { useContext } from "react";
import { Step } from "./components";
import { loanSteps, UndergraduateLoanStepContext } from "context/LoanStepContext";

export default function StepsHeader(){
    const {step:currentStep,goToStep} = useContext(UndergraduateLoanStepContext)
    return (
        <div className="flex items-center gap-4 justify-center pt-[3em] p-[2em] z-[100] w-full sticky top-0 bg-gray-100">
            {loanSteps.map((step,i) => (
                <Step onClick={()=>goToStep(i)} active={currentStep === i} key={step}>{i + 1}</Step>
            ))}
        </div>
    )
}