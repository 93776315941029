import React, { useState } from "react"
import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
import classes from "./NewsBlog.module.css"
import { ArticleContent, Articles, ArticlesDiv } from "./style"
import Skeleton from "react-loading-skeleton"

function SkeletonLoader() {
	const [currentSlide, setCurrentSlide] = useState(0)
	const [loaded, setLoaded] = useState(false)
	const [sliderRef, instanceRef] = useKeenSlider({
		initial: 0,
		breakpoints: {
			"(min-width: 400px)": {
				slides: { perView: 1, spacing: 10 }
			},
			"(min-width: 760px)": {
				slides: { perView: 2, spacing: 10 }
			},
			"(min-width: 1000px)": {
				slides: { perView: 3, spacing: 10 }
			}
		},
		slides: { perView: 1.04, spacing: 15 },
		slideChanged(slider) {
			setCurrentSlide(slider.track.details.rel)
		},
		created() {
			setLoaded(true)
		}
	})

	return (
		<ArticlesDiv>
			<div className="navigation-wrapper">
				<div ref={sliderRef} className="keen-slider">
					{[...new Array(5)].map((item, idx) => (
						<div key={`${idx}ihjbcjnsg`} className="keen-slider__slide slide_bg">
							<Articles>
								<div className={classes.articleImage}>
									<Skeleton baseColor="#7e7a75" highlightColor="#606660" count={1} height={"98%"} />
								</div>
								<ArticleContent>
									<h1>
										<Skeleton baseColor="#7e7a75" highlightColor="#606660" count={1} height={20} />
									</h1>

									<h2>
										<Skeleton baseColor="#7e7a75" highlightColor="#606660" count={2} height={10} />
									</h2>

									<div className={"blogger"}>
										<div className={classes.bloggerImage}>
											<Skeleton baseColor="#7e7a75" circle highlightColor="#606660" count={1} height={30} width={30} />
										</div>
										<div className={classes.bloggerInfo}>
											<p>
												<Skeleton baseColor="#7e7a75" highlightColor="#606660" count={1} height={5} width={"50%"} />
											</p>
											<p>
												<Skeleton baseColor="#7e7a75" highlightColor="#606660" count={1} height={5} width={"40%"} />
											</p>
										</div>
									</div>
								</ArticleContent>
							</Articles>
						</div>
					))}
				</div>
			</div>
			{loaded && instanceRef.current && (
				<div className="dots">
					{[...Array(instanceRef.current.track.details.slides.length).keys()].map(idx => {
						return (
							<button
								key={idx}
								onClick={() => {
									instanceRef.current?.moveToIdx(idx)
								}}
								className={"dot" + (currentSlide === idx ? " active" : "")}
							></button>
						)
					})}
				</div>
			)}
		</ArticlesDiv>
	)
}

export default SkeletonLoader
