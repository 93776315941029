import Newsletter from "components/Newsletter"
import React from "react"
import { ContactCallIcon, ContactEmailIcon, ContactOfficeIcon } from "utils/assets"
import ContactForm from "./ContactForm"
import ContactItem from "./ContactItem"
import { ContactHeader, ContactLeft, ContactPara, ContactRight, ContactSectionDiv, ContactSectionMain, MobileContactDiv } from "./style"

function ContactComponent() {
	return (
		<ContactSectionMain>
			<ContactSectionDiv>
				<MobileContactDiv>
					<div>
						<ContactHeader>Get in touch</ContactHeader>
						<ContactHeader>with us</ContactHeader>
						<ContactPara>Get in touch with us</ContactPara>
					</div>
					<div>
						<ContactItem icon={ContactEmailIcon} text="byshelbinfo@gmail.com" email={true} />
						<ContactItem icon={ContactEmailIcon} text="info@byshelb.by.gov.ng" email={true} />
						<ContactItem icon={ContactCallIcon} text="08161859727" phone={true} />
						<ContactItem icon={ContactCallIcon} text="08064458875" phone={true} />
						<ContactItem icon={ContactOfficeIcon} text="Okaka estate, off Sani Abacha Express Way, Yenagoa" address={true} />
					</div>
				</MobileContactDiv>
				<ContactLeft>
					<ContactForm />
				</ContactLeft>
				<ContactRight>
					<div>
						<ContactHeader>Get in touch</ContactHeader>
						<ContactHeader>with us</ContactHeader>
						<ContactPara>Get in touch with us</ContactPara>
					</div>
					<div>
						<ContactItem icon={ContactEmailIcon} text="info@byshelb.by.gov.ng" email={true} />
						<ContactItem icon={ContactCallIcon} text="08161859727" phone={true} />
						<ContactItem icon={ContactCallIcon} text="08064458875" phone={true} />
						<ContactItem icon={ContactOfficeIcon} text="Okaka estate, off Sani Abacha Express Way, Yenagoa" address={true} />
					</div>
				</ContactRight>
			</ContactSectionDiv>
			<Newsletter />
		</ContactSectionMain>
	)
}

export default ContactComponent
