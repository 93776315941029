import AboutComp from "components/About"
import UniqueLayout from "layout/UniqueLayout"
import React from "react"

const AboutPage = () => {
	return (
		<UniqueLayout>
			<AboutComp />
		</UniqueLayout>
	)
}

export default AboutPage
