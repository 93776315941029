import { Img } from "globalStyles"
import React, { useState } from "react"

import stpIcon from "assets/icons/stpIcon.svg"
import insIcon from "assets/icons/insIcon.svg"
import docIcon from "assets/icons/docIcon.svg"
import nokIcon from "assets/icons/nokIcon.svg"
import accIcon from "assets/icons/accIcon.svg"
import bvnIcon from "assets/icons/bvnIcon.svg"
import wordIcon from "assets/icons/wordIcon.svg"
import stpAvatar from "assets/images/stpAvatar.png"

import {
	AccDiv,
	BvnConDiv,
	BvnLeft,
	BvnRight,
	CardFlex,
	ConFirst,
	ConSec,
	DocHeader,
	InsConDiv,
	InsLeft,
	InsRight,
	LoanDocDiv,
	NokConDiv,
	NokLeft,
	NokRight,
	ProTabBtnDiv,
	ProTabCon,
	ProTabContent,
	ProTabDiv,
	StpConRight,
	StpContent,
	StpContentDiv,
	StpLeft,
	StpRight
} from "./style"
import FileCard from "./FileCard"
import FullScreenLoader from "components/FullScreenLoader"
import { EditAlert } from "./LoanAlert"

const LoanProTab = ({ loan }) => {
	const [tabState, setTabState] = useState(1)

	const activeTab = index => {
		setTabState(index)
	}
	const mainDet = loan?.data && loan?.data.length > 0 ? loan?.data : [{}]

	const getDOB =()=>{
		const date =  new Date(mainDet[0]?.applicant?.dob)
		return {
			day:date.getDate() > 10 ? date.getDate() : `0${date.getDate()}`,
			month:date.getMonth() + 1 > 10 ? date.getDate() + 1: `0${date.getDate() + 1}`,
			year:date.getFullYear(),
		}
	}
	const {day,month,year} = getDOB()
	if (!loan) return <FullScreenLoader show={true} />
	return (
		<section>
			{ mainDet[0].edit_requested && (!mainDet[0].in_memo) && <EditAlert loan={mainDet[0]}/> }
			<ProTabCon>
			<ProTabDiv>
				{/* <LoanDetailsHead>Loan Details</LoanDetailsHead> */}
				<ProTabBtnDiv>
					<Img src={stpIcon} alt="Student Icon" />
					<button className={tabState === 1 ? "tabs active-tabs" : "tabs"} onClick={() => activeTab(1)}>
						Student Profile
					</button>
				</ProTabBtnDiv>
				<ProTabBtnDiv>
					<Img src={insIcon} alt="Institution Icon" />

					<button className={tabState === 2 ? "tabs active-tabs" : "tabs"} onClick={() => activeTab(2)}>
						Institution
					</button>
				</ProTabBtnDiv>

				<ProTabBtnDiv>
					<Img src={nokIcon} alt="Next of Kin Icon" />

					<button className={tabState === 3 ? "tabs active-tabs" : "tabs"} onClick={() => activeTab(3)}>
						Next of kin
					</button>
				</ProTabBtnDiv>
				<ProTabBtnDiv>
					<Img src={docIcon} alt="Document Icon" />

					<button className={tabState === 4 ? "tabs active-tabs" : "tabs"} onClick={() => activeTab(4)}>
						Documents
					</button>
				</ProTabBtnDiv>

				<ProTabBtnDiv>
					<Img src={accIcon} alt="Account Icon" />

					<button className={tabState === 5 ? "tabs active-tabs" : "tabs"} onClick={() => activeTab(5)}>
						Account info
					</button>
				</ProTabBtnDiv>

				<ProTabBtnDiv>
					<Img src={bvnIcon} alt="Bvn Icon" />

					<button className={tabState === 6 ? "tabs active-tabs" : "tabs"} onClick={() => activeTab(6)}>
						BVN Data
					</button>
				</ProTabBtnDiv>
				<ProTabBtnDiv>
					<Img src={bvnIcon} alt="Bvn Icon" />

					<button className={tabState === 7 ? "tabs active-tabs" : "tabs"} onClick={() => activeTab(7)}>
						Loan Info
					</button>
				</ProTabBtnDiv>

				{/* <ModalComp /> */}
			</ProTabDiv>
			<ProTabContent>
				<div className={tabState === 1 ? "content  active-content" : "content"}>
					<StpContent>
						<Img src={mainDet[0]?.applicant?.avatar ? mainDet[0]?.applicant?.avatar : `${stpAvatar}`} alt="student avatar" />
						<StpConRight>
							<ConFirst>
								<div>
									<label>Firstname</label>
									<h4>{mainDet[0]?.applicant?.first_name ? mainDet[0]?.applicant?.first_name : "Not Provided"}</h4>
								</div>
								<div>
									<label>MiddleName</label>
									<h4>{mainDet[0]?.applicant?.middle_name ? mainDet[0]?.applicant?.middle_name : "Not Provided"}</h4>
								</div>
								<div>
									<label>Surname</label>
									<h4>{mainDet[0]?.applicant?.surname ? mainDet[0]?.applicant?.surname : "Not Provided"}</h4>
								</div>
							</ConFirst>
							<ConSec>
								<div>
									<label>Address:</label>
									<span>{mainDet[0]?.applicant?.address ? mainDet[0]?.applicant?.address : "Not Provided"}</span>
								</div>
								<div>
									<label> Email:</label>
									<span>{mainDet[0]?.applicant?.email ? mainDet[0]?.applicant?.email : "Not Provided"}</span>
								</div>
								<div>
									<label>Phone:</label>
									<span>{mainDet[0]?.applicant?.phone ? mainDet[0]?.applicant?.phone : "Not Provided"}</span>
								</div>
							</ConSec>
						</StpConRight>
					</StpContent>
					<StpContentDiv>
						<StpLeft>
							<div>
								<label>LGA</label>
								<h4>{mainDet[0]?.applicant?.lga ? mainDet[0]?.applicant?.lga : "Not Provided"}</h4>
							</div>
							<div>
								<label>Gender</label>
								<h4>{mainDet[0]?.applicant?.gender ? mainDet[0]?.applicant?.gender : "Not Provided"}</h4>
							</div>
							<div>
								<label>Employment Status</label>
								<h4>{mainDet[0]?.applicant?.employment_status ? mainDet[0]?.applicant?.employment_status : "Not Provided"}</h4>
							</div>
						</StpLeft>
						<StpRight>
							<div>
								<label>Hometown</label>
								<h4>{mainDet[0]?.applicant?.hometown ? mainDet[0]?.applicant?.hometown : "Not Provided"}</h4>
							</div>
							<div>
								<label>Date Of Birth</label>
								<h4>{mainDet[0]?.applicant?.dob ? `${day}-${month}-${year}` :"Not Provided"}</h4>
							</div>
						</StpRight>
					</StpContentDiv>
				</div>
				<div className={tabState === 2 ? "content  active-content" : "content"}>
					<InsConDiv>
						<InsLeft>
							<div>
								<label>Name Of Institution</label>
								<h4>{mainDet[0]?.institution?.name ? mainDet[0]?.institution?.name : "Not Provided"}</h4>
							</div>
							<div>
								<label>Faculty</label>
								<h4>{mainDet[0]?.institution?.faculty ? mainDet[0]?.institution?.faculty : "Not Provided"}</h4>
							</div>
							<div>
								<label>Year Of Study</label>
								<h4>{mainDet[0]?.institution?.current_study_year ? mainDet[0]?.institution?.current_study_year : "Not Provided"}</h4>
							</div>
							<div>
								<label>Certificate in View</label>
								<h4>{mainDet[0]?.certificate ? mainDet[0]?.certificate : "Not Provided"}</h4>
							</div>
						</InsLeft>
						<InsRight>
							<div>
								<label>Course Of Study</label>
								<h4>{mainDet[0]?.institution?.course ? mainDet[0]?.institution?.course : "Not Provided"}</h4>
							</div>
							<div>
								<label>Matric No.</label>
								<h4>{mainDet[0]?.institution?.matric_number ? mainDet[0]?.institution?.matric_number : "Not Provided"}</h4>
							</div>
							<div>
								<label>Year Of Graduation</label>
								<h4>{mainDet[0]?.institution?.graduation_year ? mainDet[0]?.institution?.graduation_year : "Not Provided"}</h4>
							</div>
							<div>
								<label>Loan Category</label>
								<h4>{mainDet[0]?.loan_type ? mainDet[0]?.loan_type : "Not Provided"}</h4>
							</div>
						</InsRight>
					</InsConDiv>
				</div>
				<div className={tabState === 3 ? "content  active-content" : "content"}>
					<NokConDiv>
						<NokLeft>
							<div>
								<label>Surname</label>
								<h4>{mainDet[0]?.applicant?.next_of_kin?.surname ? mainDet[0]?.applicant?.next_of_kin?.surname : "Not Provided"}</h4>
							</div>
							<div>
								<label>Middle Name</label>
								<h4>{mainDet[0]?.applicant?.next_of_kin?.middle_name ? mainDet[0]?.applicant?.next_of_kin?.middle_name : "Not Provided"}</h4>
							</div>
							<div>
								<label>Address</label>
								<h4>{mainDet[0]?.applicant?.next_of_kin?.address ? mainDet[0]?.applicant?.next_of_kin?.address : "Not Provided"}</h4>
							</div>
						</NokLeft>
						<NokRight>
							<div>
								<label>Firstname</label>
								<h4>{mainDet[0]?.applicant?.next_of_kin?.first_name ? mainDet[0]?.applicant?.next_of_kin?.first_name : "Not Provided"}</h4>
							</div>
							<div>
								<label>Phone</label>
								<h4>{mainDet[0]?.applicant?.next_of_kin?.phone ? mainDet[0]?.applicant?.next_of_kin?.phone : "Not Provided"}</h4>
							</div>
							<div>
								<label>Relationship with Applicant</label>
								<h4>{mainDet[0]?.applicant?.next_of_kin?.relationship ? mainDet[0]?.applicant?.next_of_kin?.relationship : "Not Provided"}</h4>
							</div>
						</NokRight>
					</NokConDiv>
				</div>
				<div className={tabState === 4 ? "content  active-content" : "content"}>
					<LoanDocDiv>
						<DocHeader>Loan Requirement Documents</DocHeader>
						<CardFlex>
							<FileCard fileTxt="Admission Letter" imgUrl={mainDet[0]?.admission_letter ? mainDet[0]?.admission_letter : `${wordIcon}`} imgDesc="document" />
							<FileCard fileTxt="Signed School Letter" imgUrl={mainDet[0]?.signed_letter_id ? mainDet[0]?.signed_letter_id : `${wordIcon}`} imgDesc="document" />
							{mainDet[0]?.school_fee_receipt && (
								<FileCard fileTxt="Proof of Previous Fee" imgUrl={mainDet[0]?.school_fee_receipt ? mainDet[0]?.school_fee_receipt : `${wordIcon}`} imgDesc="document" />
							)}
							{mainDet[0]?.institution?.school_identity_card && (
								<FileCard fileTxt="School Id" imgUrl={mainDet[0]?.institution?.school_identity_card ? mainDet[0]?.institution?.school_identity_card : `${wordIcon}`} imgDesc="document" />
							)}
							{mainDet[0]?.applicant?.othercertificate && (
								<FileCard fileTxt="Previous Certificate" imgUrl={mainDet[0]?.othercertificate ? mainDet[0]?.othercertificate : `${wordIcon}`} imgDesc="document" />
							)}
						</CardFlex>

						<DocHeader>Personal Documents</DocHeader>
						<CardFlex>
							<FileCard fileTxt="ID Card" imgUrl={mainDet[0]?.applicant?.identification ? mainDet[0]?.applicant?.identification : `${wordIcon}`} imgDesc="document" />
							<FileCard fileTxt="LGA Certificate" imgUrl={mainDet[0]?.applicant?.lga_certificate ? mainDet[0]?.applicant?.lga_certificate : `${wordIcon}`} imgDesc="document" />
							<FileCard fileTxt="Birth Certificate" imgUrl={mainDet[0]?.applicant?.birth_certificate ? mainDet[0]?.applicant?.birth_certificate : `${wordIcon}`} imgDesc="document" />
						</CardFlex>
					</LoanDocDiv>
				</div>
				<div className={tabState === 5 ? "content  active-content" : "content"}>
					<AccDiv>
						<div>
							<label>Account Name</label>
							<h4>{mainDet[0]?.applicant?.bank_account?.account_name ? mainDet[0]?.applicant?.bank_account?.account_name : "Not Provided"} </h4>
						</div>
						<div>
							<label>Account Number</label>
							<h4>{mainDet[0]?.applicant?.bank_account?.account_number ? mainDet[0]?.applicant?.bank_account?.account_number : "Not Provided"} </h4>
						</div>
						<div>
							<label>Bank Name</label>
							<h4>{mainDet[0]?.applicant?.bank_account?.bank_name ? mainDet[0]?.applicant?.bank_account?.bank_name : "Not Provided"} </h4>
						</div>
						<div>
							<label>Bank Code</label>
							<h4>{mainDet[0]?.applicant?.bank_account?.bank_code ? mainDet[0]?.applicant?.bank_account?.bank_code : "Not Provided"} </h4>
						</div>
					</AccDiv>
				</div>
				<div className={tabState === 6 ? "content  active-content" : "content"}>
					<BvnConDiv>
						<BvnLeft>
							<div>
								<label>Bank Verification Number (BVN)</label>
								<h4>{mainDet[0]?.applicant?.bvn ? mainDet[0]?.applicant?.bvn : "Not Provided"}</h4>
							</div>
						</BvnLeft>
						<BvnRight></BvnRight>
					</BvnConDiv>
				</div>
				<div className={tabState === 7 ? "content  active-content" : "content"}>
					<BvnConDiv>
						<BvnLeft>
							<div style={{margin:"8px auto"}}>
								<label>Loan Amount </label>
								<h4>{mainDet[0]?.amount ? `₦${mainDet[0]?.amount.toLocaleString()}` : "Not Provided"}</h4>
							</div>
							<div style={{margin:"8px auto"}}>
								<label>Loan Tenor </label>
								<h4>{mainDet[0]?.loan_tenure ? `${mainDet[0]?.loan_tenure} months` : "Not Provided"}</h4>
							</div>
							<div style={{margin:"8px auto"}}>
								<label>Monthly Repayment Amount </label>
								<h4>{mainDet[0]?.loan_tenure ? `₦${mainDet[0]?.monthly_repayment_amount.toLocaleString()}` : "Not Provided"}</h4>
							</div>
						</BvnLeft>
						<BvnRight></BvnRight>
					</BvnConDiv>
				</div>
			</ProTabContent>
		</ProTabCon>
		</section>
	)
}

export default LoanProTab
