import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Colors from 'utils/colors'
import media from 'utils/media'

export const LoanContainer = styled.div`
  padding: 30px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;

  ${media.mobile`
 padding: 0px 0px ;
  `}
`
export const FirstDiv = styled.div`
  width: 100%;
  display: flex;
  color: #fff;
  padding: 30px 60px;

  ${media.mobile`
	display: flex;
  flex-direction: column;
  padding: 0px 30px;
  /* background: red; */
  margin-top: -15%;
	`}
`

export const LoanLeft = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 30px;
  /* background: ${Colors.gradient}; */

  ${media.smallDesktopMinimum`
  height: 500px;
 
  padding: 10px;
  
  `}

  ${media.mobile`
  width:100%;
	`}
`
export const LeftText = styled.div`

  ${media.mobile`
  width: 100%;
  
  `}
`

export const LoanTextHead = styled.h3`
  width: 95%;
  font-weight: 600;
  font-size: 34px;
  color: ${Colors.primary};
  
  ${media.smallDesktopMinimum`
  
  font-size: 24px;
  `}
  
  ${media.mobile`
  
  width: 100%;
  font-size: 20px;
  font-weight: 550;
  `}

  ${media.smallMobile`
  background: red;
  `}
`
export const LoanTextPara = styled.p`
  width: 95%;
  font-size: 16px;
  padding-top: 10px;
  color: black;
  word-spacing: 3px;
  line-height: 25px;

  span {
    color: ${Colors.primary};
    font-weight: 600;
  }

  ${media.mobile`
  
  width: 100%;
font-size: 14px;
word-spacing: 1px;
  line-height: 20px;
  
  `}
`
export const LoanBlogBtn = styled(Link)`
  width: 150px;
  /* height: 50px; */
  /* display: flex;
  justify-content: center;
  align-items: center; */
  font-family: 'Montserrat', sans-serif;
  color: #ed3237;
  text-decoration: none;
  font-weight: 550;
  font-size: 16px;
  margin-top: 10px;
  border-radius: 10px;
  /* background: ${Colors.gradient}; */
  cursor: pointer;

  ${media.mobile`
  /* margin-top: 20px; */

`}
`
export const LoanRight = styled.div`
  width: 50%;
  padding: 20px 30px;

  img {
    width: 100%;
    height: 100%;
  }

  ${media.smallDesktopMinimum`
 height: 500px;
  `}

  ${media.mobile`
 width:100%;
 height: 50%;
 margin-top: -40px;
 margin-bottom: 40px;
 padding: 0px 0px;


  `}
`
export const SecondDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const LoanFootComp = styled.div`
  width: 92%;
  padding: 30px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  background: ${Colors.gradient};

  ${media.smallDesktopMinimum`
  height: 100%;


  `}
  ${media.mobile`
  padding: 20px 0px;

  `}
`
export const HeroBtn = styled.button`
  width: 100%;
  padding: 15px 25px;
  margin-top: 20px;
  background: ${Colors.white};
  border-radius: 5px;
  color: ${Colors.primaryLight};
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.025em;
  cursor: pointer;

  ${media.mobile`
  padding: 10px 20px;
  font-size: 12px;

  
  `}
`

export const LoanFootHead = styled.h3`
  font-weight: 500;
  font-size: 28px;
  color: #ffffff;

  ${media.mobile`
	font-size: 14px;
	padding-top:10px ;
	
	`}
`
