import React from "react"
import { BlogPageContainer } from "./style"
import NewsBlog from "./NewsBlog"

function BlogMain() {
	return (
		<BlogPageContainer>
			<NewsBlog />
		</BlogPageContainer>
	)
}

export default BlogMain
