import React from "react"
import { ErrorMsgSpan, InputText, TextInputContainer } from "./style"

function TextInput({ name, type = "text", id, onChange, label, mt = "", astheric = false, labelColor = "", width = "100%", error, visible, ErrorMt, ...rest }) {
	return (
		<TextInputContainer mt={mt} width={width} labelColor={labelColor}>
			<div>
				<label className="font-medium mb-1" htmlFor={name}>
					{label} {astheric && ""}
				</label>
				{astheric && (
					<label style={{ color: "red" }} htmlFor={name}>
						*
					</label>
				)}
			</div>
			<InputText type={type} name={name} id={id} onChange={onChange} {...rest} />
			{visible && error ? <ErrorMsgSpan ErrorMt={`${mt}`}>{error}</ErrorMsgSpan> : null}
		</TextInputContainer>
	)
}

export default TextInput
