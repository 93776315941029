import styled from "styled-components"
import Colors from "utils/colors"
import media from "utils/media"

export const NewsletterContainer = styled.div`
	width: 100%;
	margin-top: 20px;
	padding: 30px 0px;
	display: flex;
	flex-direction: column;
	align-items: center;

	${media.mobile`
	margin-top: 0px;
	
	`}
`

export const NewsLetterHead = styled.div`
	width: 92%;
	text-align: center;
	span {
		color: rgba(109, 0, 98, 0.97);
	}
	h3 {
		font-weight: 550;
		font-size: 28px;
		letter-spacing: -0.02em;
	}

	${media.mobile`
		h3 {
			font-size: 16px;
		}
	`}
`

export const NewsLetterCTA = styled.form`
	display: flex;
	width: 50%;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
	padding: 30px 30px;
	gap: 20px;

	input {
		width: 100%;
		padding: 15px;
		border: 3px solid #d9d9d9;
		border-radius: 15px;
		font-family: "Montserrat";
		font-weight: 550;
		font-size: 12px;
		letter-spacing: 0.025em;
		color: #000000;

		::placeholder {
			color: #c0c0c0;
		}
	}

	button {
		width: 160px;
		height: 45px;
		border: none;
		outline: none;
		background: ${Colors.gradient};
		border-radius: 10px;
		font-family: "Montserrat";
		font-weight: 600;
		font-size: 16px;
		letter-spacing: 0.025em;
		text-transform: capitalize;
		cursor: pointer;
		color: #ffffff;

		${media.mobile`
			width: 100%;
		`}
	}

	${media.smallDesktopMinimum`
		width: 70%;
	`}
	${media.mobile`
		flex-direction: column;
		padding: 20px 0px;
		input {
			width: 100%;
		}
	`}
`;
