import React from "react"
import styled from "styled-components/macro"
import media from "utils/media"
// import { Img } from "globalStyles"
import Colors from "utils/colors"

const TabBtnProfile = styled.button`
	width: calc(200px - 20px);
	height: 39px;
	padding: 0px 10px;
	margin-top: 0.5rem;
	display: flex;
	align-items: center;
	outline: none;
	border: none;
	color: ${Colors.primaryDark};
	background: ${({ active }) => (active ? "#C4C4C4" : "#ffffff")};

	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
	border-radius: 3px;
	cursor: pointer;
	img {
		margin-right: 15px;
	}
	p {
		margin-left: 10px;
	}
	${media.mobile`
	width: 45%;
	flex: 0 0 45%;
	/* margin-left: 5px; */
	`}
`

export const ScrollDiv = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	/* box-sizing: content-box; */
	/* overflow: hidden; */
	overflow-x: scroll;
	gap: 15px;
	/* z-index: 300; */
	::-webkit-scrollbar {
		width: 0;
		background: transparent;
	}
	::-webkit-scrollbar-thumb {
		background: transparent;
	}
`
export const AButtonContainer = styled.div`
	width: 100%;
	margin-top: 0.5rem;
	display: flex;
	align-items: center;
	/* justify-content: center; */
	/* position: relative; */
	height: 70px;
	${media.smallDesktopMinimum`
	width: 100%;
	
	`}
	${media.tablet`
	width: 100%;
	
	`}
	${media.mobile`
	width: 100%;
	
	`}
`

function SettingsBtn({ title = "Password Setting", iconType = "bvn", onClick, ImgUrl, active = false }) {
	return (
		<TabBtnProfile onClick={onClick} active={active}>
			{/* <Img src={ImgUrl} alt="Toggle_Icon" /> */}
			{iconType === "password" && (
				<svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M10 14C10 15.11 9.11 16 8 16C7.46957 16 6.96086 15.7893 6.58579 15.4142C6.21071 15.0391 6 14.5304 6 14C6 12.89 6.89 12 8 12C8.53043 12 9.03914 12.2107 9.41421 12.5858C9.78929 12.9609 10 13.4696 10 14ZM9.09 19C9.21 19.72 9.46 20.39 9.81 21H2C1.46957 21 0.960859 20.7893 0.585786 20.4142C0.210714 20.0391 0 19.5304 0 19V9C0 7.89 0.89 7 2 7H3V5C3 2.24 5.24 0 8 0C10.76 0 13 2.24 13 5V7H14C14.5304 7 15.0391 7.21071 15.4142 7.58579C15.7893 7.96086 16 8.46957 16 9V12.09C15.67 12.04 15.34 12 15 12C14.66 12 14.33 12.04 14 12.09V9H2V19H9.09ZM5 7H11V5C11 3.34 9.66 2 8 2C6.34 2 5 3.34 5 5V7ZM17.34 14.84L13.75 18.43L12.16 16.84L11 18L13.75 21L18.5 16.25L17.34 14.84Z"
						fill="#FF00C7"
					/>
				</svg>
			)}
			{iconType === "bvn" && (
				<svg width="14" height="22" viewBox="0 0 14 22" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M12 2H9V4H12V20H2V4H5V2H2C1.46957 2 0.960859 2.21071 0.585786 2.58579C0.210714 2.96086 0 3.46957 0 4V20C0 20.5304 0.210714 21.0391 0.585786 21.4142C0.960859 21.7893 1.46957 22 2 22H12C12.5304 22 13.0391 21.7893 13.4142 21.4142C13.7893 21.0391 14 20.5304 14 20V4C14 3.46957 13.7893 2.96086 13.4142 2.58579C13.0391 2.21071 12.5304 2 12 2ZM7 6C7.53043 6 8.03914 6.21071 8.41421 6.58579C8.78929 6.96086 9 7.46957 9 8C9 8.53043 8.78929 9.03914 8.41421 9.41421C8.03914 9.78929 7.53043 10 7 10C6.46957 10 5.96086 9.78929 5.58579 9.41421C5.21071 9.03914 5 8.53043 5 8C5 7.46957 5.21071 6.96086 5.58579 6.58579C5.96086 6.21071 6.46957 6 7 6ZM11 14H3V13C3 11.67 5.67 11 7 11C8.33 11 11 11.67 11 13V14ZM11 17H3V16H11V17ZM7 19H3V18H7V19ZM8 4H6V0H8V4Z"
						fill="#FF00C7"
					/>
				</svg>
			)}

			<p>{title}</p>
		</TabBtnProfile>
	)
}

export default SettingsBtn
