export default function TermsAndConditions({accept,reject}){
    
    return (
    <div className="" >
      <div className="p-4">
      <header className="sticky z-[99] top-0 py-4 mb-4 pt-12 bg-gray-100">
        <h4 className="text-center text-xl sm:text-3xl font-semibold text-secondary">Terms and Conditions</h4>
      </header>
      <div className="max-w-3xl mb-6 p-4 bg-white border border-solid border-secondary-200 g-white rounded-lg mx-auto">
                <p className="text-justify text-sm sm:text-base font-poppins">
                    Welcome to the BYSHELB Loan Application System. This document outlines the terms and conditions governing the interest-free loan program provided exclusively to Bayelsa indigenes. By applying for this loan, applicants agree to comply with the terms and conditions set forth herein.
                </p>
                <div className="my-4 font-poppins text-[#242424] font-light">
                    <h4 className="font-semibold font-poppins text-xl text-secondary mb-4">Requirements for Loan Application </h4>
                    <p className="font-poppins font-light">Applicants must meet the following requirements to be eligible for the loan:</p>
                    <p className="font-poppins font-light">1.   Birth Certificate/Age Declaration: Proof of age.</p>
                    <p className="font-poppins font-light">2.   Local Government Area Identification: Verification of Bayelsa indigene status.</p>
                    <p className="font-poppins font-light">3.	Applicant’s Passport: Recent passport-sized photograph.</p>
                    <p className="font-poppins font-light">4.	Applicant’s School ID Card or Any Other ID Card: Valid identification.</p>
                    <p className="font-poppins font-light">5.	Admission Letter: Proof of admission into an educational institution.</p>
                    <p className="font-poppins font-light">6.	Original Letter of Identification: From the Registrar, Dean of Student Affairs, or Faculty Dean, duly stamped and signed.</p>
                    <p className="font-poppins font-light">7.	Guarantor’s Valid Identification: National ID Card.</p>
                    <p className="font-poppins font-light">8.	Previous Certificate: Required for postgraduate applicants.</p>
                    <p className="font-poppins font-light">9.	Evidence of Past School Fees Payment: For students reapplying for the loan.</p>
                </div>

                <div className="my-8">
                    <h4 className="font-semibold text-xl text-secondary mb-4">Terms and Conditions</h4>
                    <p className="font-poppins font-light">  1.	Interest-Free Loan:
                        This loan is provided interest-free exclusively to Bayelsa indigenes.
                    </p>
                    <p className="font-poppins font-light">
                        <span className="font-medium"> 2.	Loan Repayment:</span>
                        Undergraduate Students: Loan repayment starts one year after completing the National Youth Service Corps (NYSC) program.
                        Postgraduate Students: Loan repayment starts six months after program completion.
                    </p>
                    <p className="font-poppins font-light">
                        <span className="font-medium">3.	Loan Amount Decision:</span>
                        The board reserves the right to determine the loan amount issued to each applicant. This decision is based on several factors, including the applicant's institution, course of study, Local Government Area (LGA), program, and the availability of funds.
                    </p>
                    <p className="font-poppins font-light">
                        <span className="font-medium">4.	Application Validity:</span>
                        The board is not responsible for any transactions conducted outside this application system or at their physical office. All loan applications and processes must be conducted through the official loan application system.
                    </p>
                    <p className="font-poppins font-light">
                       <span className="font-medium"> 5.	Repayment Obligations:</span>
                        Applicants are required to adhere to the repayment schedule as determined by the board.
                    </p>
                    <p className="font-poppins font-light">
                        <span className="font-medium">6.	Use of Loan Funds:</span>
                        Loan funds must be used solely for educational purposes, including tuition fees, books, and other academic-related expenses. Misuse of funds may result in immediate repayment demand and legal actions.
                    </p>
                    <p className="font-poppins font-light">
                        <span className="font-medium">7.	Updates and Amendments:</span>
                        The board reserves the right to update or amend these terms and conditions at any time. Applicants will be notified of any changes through the official loan application system.
                    </p>
                    <p className="font-poppins font-light">
                        <span className="font-medium">8.	Data Privacy:</span>
                        All personal information provided by applicants will be used solely for the purposes of processing the loan application and administering the loan program. The board is committed to protecting the privacy and security of applicants' personal data.
                    </p>
                    <p className="font-poppins font-light">
                        <span className="font-medium"> 9.	Dispute Resolution:</span>
                        Any disputes arising from the loan application or repayment process shall be resolved through amicable negotiations. If a resolution cannot be reached, the dispute will be settled in accordance with the laws of Nigeria.
                    </p>
                    <p className="font-poppins font-light">
                       <span className="font-medium"> 10.	Acceptance of Terms:</span>
                        By submitting a loan application, applicants acknowledge that they have read, understood, and agreed to these terms and conditions.
                    </p>
                </div>
        </div>
      </div>
      <footer className="flex gap-2 py-2 px-4 pt-4 border-t border-gray-200 justify-end border-solid sticky bottom-0 bg-gray-50">
        <button type="button" onClick={reject} className="bg-red-100 border border-solid border-red-500 rounded-full py-2 text-red-600  px-6">
          <span>Reject</span>
        </button>
        <button type="button" onClick={accept} className="bg-secondary-50 border border-solid border-secondary-500 rounded-full py-2 text-secondary-500 disabled:opacity-60 disabled:cursor-not-allowed  px-6">
          <span>Accept</span>
        </button>
      </footer>
    </div>
    
    )
}