import Newsletter from "components/Newsletter"
import React, { useState } from "react"
import { facon } from "utils/assets"
import faqsData from "./faqsData"
import { AnsDiv, FaqBottom, FaqContainer, FaqHeader, FAQicon, FaqQuestion, QuestionDiv } from "./style"

const FaqComponent = () => {
	const [selected, setSelected] = useState(0)

	function toggleFaqs(id) {
		if (faqsData.length === id) {
			setSelected(0)
		} else {
			setSelected(id)
		}
	}
	const selectedIdx = faqsData.length === selected + 1 ? 0 : selected + 1
	return (
		<FaqContainer>
			<QuestionDiv>
				<FaqHeader>FAQS</FaqHeader>
				<FaqQuestion>
					{selected + 1}.{" "}
					{faqsData[selected].topic.includes("loan") ? (
						<>
							{faqsData[selected].topic.replace(/[^a-zA-Z ]/g, "") && faqsData[selected].topic.replace(/loan/g, "")}
							{/* {faqsData[selected].topic.replace(/loan/g, "")} */}
							<span cla>loan?</span>
						</>
					) : (
						faqsData[selected].topic
					)}
				</FaqQuestion>
			</QuestionDiv>

			<AnsDiv>
				<p className="faq__para">{faqsData[selected].firstpagraph}</p>
				<p className="faq__para">{faqsData[selected].secondpagraph}</p>
			</AnsDiv>
			<FaqBottom>
				<a href="#faq" className="faq__link" onClick={() => toggleFaqs(selected + 1)}>
					{faqsData.length === selected + 1 ? 1 : selected + 2}.{faqsData[selectedIdx].topic}
				</a>
				<FAQicon src={facon} alt="next question" />
			</FaqBottom>

			<Newsletter />
		</FaqContainer>
	)
}

export default FaqComponent
