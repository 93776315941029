import * as Yup from 'yup'
import moment from 'moment'
import TextInput from 'components/TextInput'
import { useFormik } from 'formik'
import React, {useState,useEffect } from 'react'
import {
  ProfileUserName,
} from './style'
import { updatePersonalDetails } from 'services/UserService'
import { toast } from 'react-hot-toast'
import CustomSelectMain from 'layout/SelectInput'
import lgaData from './lgaData'
import { observer } from 'mobx-react-lite'
import UploadFileContainer from './UploadContainer'
import { Spin } from 'antd'

function UpdateProfileForm({ user, setKey }) {

  const [loading,setLoading] = useState(false)

  const [proImage, setProImage] = useState(null)
  const [lgaImage, setLgaImage] = useState(null)
  const [birthcertImage, setBirthCertImage] = useState(null)
  const [idCardImage, setIdCardImage] = useState(null)

  const [proImageURL, setProImageURL] = useState(null)
  const [lgaImageURL, setLgaImageURL] = useState(null)
  const [birthcertImageURL, setBirthCertImageURL] = useState(null)
  const [idCardImageURL, setIdCardImageURL] = useState(null)


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name: user?.first_name ? user?.first_name : '',
      middle_name: user?.middle_name ? user?.middle_name : '',
      surname: user?.surname ? user?.surname : '',
      email: user?.email ? user?.email : '',
      hometown: user?.hometown ? user?.hometown : '',
      phone: user?.phone ? user?.phone : '',
      address: user?.address ? user?.address : '',
      lga: user?.lga ? user?.lga : '',
      mother_maiden_name: user?.mother_maiden_name
        ? user?.mother_maiden_name
        : '',
      gender: user?.gender ? user?.gender : '',
      dob:
        `${moment(user?.dob).format('YYYY')}-${moment(user?.dob).format(
          'MM'
        )}-${moment(user?.dob).format('DD')}` || '',
    },
    onSubmit: async (data, helpers) => {
      setLoading(true)
      if (!birthcertImage)
        return toast.error('please upload your birth certificate')
      if (!lgaImage) return toast.error('please upload LGA ID')
      if (!proImage) return toast.error('please upload passport photo')
      if (!idCardImage) return toast.error('please upload a valid ID card')

      const formData = new FormData()
      formData.set('surname', data.surname)
      formData.set('first_name', data.first_name)
      formData.set('middle_name', data.middle_name)
      formData.set('email', data.email)
      formData.set('gender', data.gender)
      formData.set('lga', data.lga)
      formData.set('mother_maiden_name', data.mother_maiden_name)
      formData.set('address', data.address)
      formData.set('phone', data.phone)
      formData.set('hometown', data.hometown)
      formData.set('dob', data.dob)
      formData.set('id', user.id)
      formData.append('avatar', proImage[0])
      formData.append('lga_certificate', lgaImage[0])
      formData.append('identification', idCardImage[0])
      formData.append('birth_certificate', birthcertImage[0])

      const response = await updatePersonalDetails(formData)
      setLoading(false)
      if (!response.error) {
        helpers.setSubmitting(false)
      }

      if (response.error) {
        helpers.setSubmitting(false)
      }
    },
    validationSchema: Yup.object({
      first_name: Yup.string().max(50).required().label('First Name'),
      middle_name: Yup.string().max(50).label('Middle Name'),
      surname: Yup.string().max(50).required().label('Surname'),
      address: Yup.string().max(150).required().label('Address'),
      mother_maiden_name: Yup.string().max(50).required().label('Maiden Name'),
      lga: Yup.string().max(100).required().label('LGA'),
      email: Yup.string().max(255).required().label('Email'),
      hometown: Yup.string().max(100).required().label('Hometown'),
      gender: Yup.string().max(100).required().label('Gender'),
      phone: Yup.string()
        .min(11)
        .max(16)
        .required('Phone Number is required.')
        .label('Phone Number'),
      dob: Yup.string()
        .required('Date of birth is required.')
        .label('Date of birth'),
    }),
  })

 
  async function updateFile(fileURL,setFile){
		const data = await fetch(fileURL)
		const fileBlob = await data.blob()
		// console.log(fileBlob)
		setFile([fileBlob])
	}

	useEffect(() => {
		if(user){
			// console.log({loan})
			if(user.avatar){
				setProImageURL(user.avatar)
				updateFile(user.avatar,setProImage)
			}
      if(user.birth_certificate){
				setBirthCertImageURL(user.birth_certificate)
				updateFile(user.birth_certificate,setBirthCertImage)
			}
      if(user.lga_certificate){
				setLgaImageURL(user.lga_certificate)
				updateFile(user.lga_certificate,setLgaImage)
			}
      if(user.identification){
				setIdCardImageURL(user.identification)
				updateFile(user.identification,setIdCardImage)
			}
		}
	},[user])

  return (
    <form className="p-4" onSubmit={formik.handleSubmit}>
       <div className="max-w-[850px] mx-auto">
        <ProfileUserName>Personal Details</ProfileUserName>
        <div className="grid grid-cols-2 gap-4">
          <div >
            <TextInput
              label="First Name"
              name="first_name"
              id="first_name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.first_name}
              error={formik.errors.first_name}
              visible={formik.touched.first_name}
              placeholder="First Name"
            />
            <TextInput
              label="Surname"
              name="surname"
              id="surname"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.surname}
              error={formik.errors.surname}
              visible={formik.touched.surname}
              placeholder="Surname"
            />
            <TextInput
              label="Address"
              name="address"
              id="address"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.address}
              error={formik.errors.address}
              visible={formik.touched.address}
              placeholder="Address"
            />
            <CustomSelectMain
              label={'L.G.A'}
              astheric={true}
              name="lga"
              value={formik.values.lga}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={formik.errors.lga}
              visible={formik.touched.lga}
            >
              <option style={{ color: 'grey' }} value="" disabled>
                Select LGA
              </option>
              {lgaData?.map((local) => (
                <option
                  className="active-option"
                  key={local.id}
                  value={local.value}
                >
                  {local.label}
                </option>
              ))}
            </CustomSelectMain>
            <TextInput
              label="Phone Number"
              name="phone"
              id="phone"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phone}
              error={formik.errors.phone}
              visible={formik.touched.phone}
              placeholder="Phone Number"
            />
          </div>
          <div>
            <TextInput
              label="Middle Name"
              name="middle_name"
              id="middle_name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.middle_name}
              error={formik.errors.middle_name}
              visible={formik.touched.middle_name}
              placeholder="Middle Name"
            />
            <TextInput
              label="Mother's Maiden Name"
              name="mother_maiden_name"
              id="mother_maiden_name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.mother_maiden_name}
              error={formik.errors.mother_maiden_name}
              visible={formik.touched.mother_maiden_name}
              placeholder="Mother's Maiden Name"
            />
            <TextInput
              label="Email Address"
              name="email"
              id="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              error={formik.errors.email}
              visible={formik.touched.email}
              placeholder="Email Address"
            />
            <TextInput
              label="Hometown"
              name="hometown"
              id="hometown"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.hometown}
              error={formik.errors.hometown}
              visible={formik.touched.hometown}
              placeholder="Hometown"
            />
            <CustomSelectMain
              astheric={true}
              label={'Gender'}
              name="gender"
              value={formik.values.gender}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={formik.errors.gender}
              visible={formik.touched.gender}
            >
              <option style={{ color: 'grey' }} value="" disabled>
                Select Gender
              </option>
              {[
                {
                  id: 'ghhdfsdfd',
                  label: 'Male',
                  value: 'male',
                },
                {
                  id: 'sdfghjkghjd',
                  label: 'Female',
                  value: 'female',
                },
              ].map((sex) => (
                <option className="active-option" key={sex.id} value={sex.value}>
                  {sex.label}
                </option>
              ))}
            </CustomSelectMain>
            <TextInput
              label="Date of Birth"
              name="dob"
              id="dob"
              type="date"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.dob}
              error={formik.errors.dob}
              visible={formik.touched.dob}
              placeholder="Date of Birth"
            />
          </div>
        </div>

        <div className="my-3 w-full">
            <UploadFileContainer 
            setFile={setProImage}
            setFileURL={setProImageURL}
            fileURL={proImageURL}
            formTitle={"Upload Passport Photograph"}/>
        </div>
        <div className="my-3 w-full">
            <UploadFileContainer
              setFile={setLgaImage}
              setFileURL={setLgaImageURL}
              fileURL={lgaImageURL}
              formTitle={"Upload L.G.A Identification"}
            />
        </div>
        <div className="my-3 w-full">
            <UploadFileContainer
              fileURL={birthcertImageURL}
              setFileURL={setBirthCertImageURL}
              setFile={setBirthCertImage}
              formTitle={"Upload Birth certificate"}
            />
        </div>
        <div className="my-3 w-full">
            <UploadFileContainer
              setFile={setIdCardImage}
              fileURL={idCardImageURL}
              setFileURL={setIdCardImageURL}
              formTitle={"Upload National Id, Voter's Card or Int'l Passport"}
              />
        </div>

        <button disabled={loading} className={"disabled:opacity-50 bg-primary-100 border border-solid border-primary-500 px-8 rounded-3xl py-2 disabled:cursor-not-allowed"} type="submit">
          {loading ?<Spin/> : 'Save'}
        </button>
       </div>
    </form>
  )
}

export default observer(UpdateProfileForm)
