import styled from "styled-components/macro"
import { AuthProp } from "utils/assets"
import Colors from "utils/colors"
import media from "utils/media"

export const Authcontainer = styled.div`
	width: 100%;
	height: 100vh;
	background: ${Colors.gradient};
`
export const AuthContent = styled.div`
	width: 100%;
	height: 100%;
	background-image: url(${AuthProp});
	background-repeat: no-repeat;
	background-position: -14% 130%;
	background-size: 400px 400px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	${media.mobile`
	background-position: -40.5% 110%;
	background-size: 200px 200px;
	`}
`
export const AuthCard = styled.div`
	width: 100%;
	background: #ffffff;
	border-radius: 43px 43px 80px 0px;
	margin-top: 2rem;
	padding: 2rem 2rem;
	${media.mobile`
	padding: 2rem 1rem;
	`}
`
export const AuthText = styled.h3`
	font-family: 'Montserrat', sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 22px;
	line-height: 103%;
	color: ${Colors.primary};
	margin-top: -8px;
	margin-bottom: 1px;
	${media.mobile`
	font-size: 22px;
	
	`}
`
export const Authbtn = styled.button`
	width: 30%;
	height: 35px;
	border: none;
	outline: none;
	border-radius: 10px;
	background: ${Colors.gradient};
	color: ${Colors.white};
	padding: 5px 20px;
	margin-top: 20px;
	cursor: pointer;
	${media.mobile`
	width: 50%;
	height: 35px;
	`}
`
export const AuthBottomDIv = styled.div`
	display: flex;
	align-items: center;
	font-size: 14px;
	margin-top: 8px;
	.auth--bottom-link {
		color: ${Colors.muted};
		font-size: 12px;
	}
	.link-bottom {
		margin-left: 5px;
		text-decoration: none;
		color: ${Colors.primary};
	}
`
