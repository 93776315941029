import { Img } from 'globalStyles'
import React from 'react'
import { Link } from 'react-router-dom'
import { BLOG_ROUTE, LOGIN_ROUTE } from 'routes'
import {
  StdMeeting,
} from 'utils/assets'

import {
  FirstDiv,
  HeroBtn,
  LeftText,
  LoanBlogBtn,
  LoanContainer,
  LoanFootComp,
  LoanFootHead,
  LoanLeft,
  LoanRight,
  LoanTextHead,
  LoanTextPara,
  SecondDiv,
} from './style'

const LoanComp = () => {
  return (
    <LoanContainer>
      <FirstDiv>
        <LoanLeft>
          <LeftText>
            <LoanTextHead>
              Navigate through your academic journey effortlessly.
            </LoanTextHead>
            <LoanTextPara>
              Over the past three years, the Bayelsa State Higher Education
              Students Loan Board (BYSHELB) has successfully disbursed over{' '}
              <span>1.5 billion Naira</span> in loans to more than{' '}
              <span>5,000 Bayelsa indigines</span>
              {' '}enrolled in Nigerian tertiary institutions. This accomplishment
              underscores BYSHELB's dedication to promoting higher education and
              empowering individuals within the state.
            </LoanTextPara>
          </LeftText>
		  <LoanBlogBtn to={BLOG_ROUTE}> Read more... </LoanBlogBtn>
        </LoanLeft>
        <LoanRight>
          <Img src={StdMeeting} alt="Student Image" />
        </LoanRight>
      </FirstDiv>

      <SecondDiv>
        <LoanFootComp>
          <LoanFootHead>Loan process is simple and fast</LoanFootHead>
          <Link to={LOGIN_ROUTE}>
            <HeroBtn>Apply Now!</HeroBtn>
          </Link>
        </LoanFootComp>
      </SecondDiv>
    </LoanContainer>
  )
}

export default LoanComp
