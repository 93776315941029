import TextInput from "components/TextInput"
import React from "react"
import * as Yup from "yup"
import { useFormik } from "formik"

import { resolveBVN } from "services/MiscService"
import loanStore from "mobx/LoanStore"
import { observer } from "mobx-react-lite"
import { ConnectBvnDiv } from "./style"
import { ProceedBtn } from "../Apply/style"

const ConnectBvn = () => {
	const formik = useFormik({
		initialValues: {
			bvn: ""
		},

		onSubmit: async (data, helpers) => {
			const payload = { ...data }

			const underResponse = await resolveBVN(payload)
		},

		validationSchema: Yup.object({
			bvn: Yup.string().min(11).max(11).required().label("BVN")
		})
	})

	return (
		<ConnectBvnDiv onSubmit={formik.handleSubmit}>
			<h2>Connect your BVN</h2>
			<h2>Why do we ask for your BVN?</h2>
			<p style={{ marginTop: "10px" }}>
				To confirm your identity on BYSHELB, you need to connect your BVN. This does not give BYSHELB any access to your bank information or balances. This just enables BYSHELB confirm your identity
				(real name, BvnPageTexthone number & date of birth) from your bank.
			</p>
			<div>
				<label>
					BVN Number <span>(to get your BVN, text *565*0#)</span>
				</label>
				<TextInput
					name="bvn"
					id="bvn"
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					value={formik.values.bvn}
					error={formik.errors.bvn}
					visible={formik.touched.bvn}
					placeholder="22554466227"
					width="50%"
					mt="1.5rem"
				/>
				<ProceedBtn type="submit">{loanStore.isLoading ? "Loading..." : "Validate BVN"}</ProceedBtn>
			</div>
		</ConnectBvnDiv>
	)
}

export default observer(ConnectBvn)
