import React from "react"
import { NewsletterContainer, NewsLetterCTA, NewsLetterHead } from "./style"
import { useState } from "react"
import { subscribeToNewsLetter } from "services/MiscService"
import { observer } from "mobx-react-lite"
import authStore from "mobx/AuthStore"

const Newsletter = () => {
	const [statevalue, setStateValue] = useState("")

	const handleSubscribe = async e => {
		e.preventDefault()

		const response = await subscribeToNewsLetter(statevalue)
		if (!response.error) {
			setStateValue("")
		}
	}
	const handleChange = e => {
		setStateValue(e.target.value)
	}
	return (
		<NewsletterContainer>
			<NewsLetterHead>
				<h3>
					Subscribe to our <span>Newsletter</span>
				</h3>
			<NewsLetterCTA onSubmit={handleSubscribe}>
				<input
					disabled={authStore.isLoading}
					onChange={handleChange}
					value={statevalue}
					pattern="^[\w]{1,}[\w.+-]{0,}@[\w-]{2,}([.][a-zA-Z]{2,}|[.][\w-]{2,}[.][a-zA-Z]{2,})$"
					type="email"
					required
					placeholder="Your email..."
				/>
				<button disabled={authStore.isLoading} type="submit">
					{authStore.isLoading ? "Submitting...." : "Subscribe"}
				</button>
			</NewsLetterCTA>
			</NewsLetterHead>
		</NewsletterContainer>
	)
}

export default observer(Newsletter)
