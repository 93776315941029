import styled from "styled-components/macro"
import Colors from "utils/colors"
import media from "utils/media"

export const ProfileContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  justify-content: space-between;
  align-items: center;
  
  ${media.tablet`
    flex-direction: column;
  `}

  ${media.mobile`
    padding: 10px;
    flex-direction: column;
    justify-content: center;
  `}
`

export const ProfileDiv = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  background: #d9d9d9;
  border-radius: 30px;
  margin: 0 auto;
  padding: 20px;
  
  ${media.mobile`
    padding: 0;
    height: 350px;
    flex-direction: column;
  `}
`

export const ProfileDivContainer = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  background: #d9d9d9;
  border-radius: 30px;
  margin: 0 auto;
  padding: 20px;
  
  ${media.mobile`
    width: 100%;
    padding: 5px;
  `}
`

export const ProfileUserName = styled.h2`
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 35px;
  line-height: 35px;
  color: #6d0062;
  margin-bottom: 10px;
  
  ${media.mobile`
    font-size: 25px;
    text-align: center;
  `}
`

export const ProfileLabel = styled.p`
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #1e1e1e;
  margin-top: 20px;
  
  ${media.mobile`
    text-align: center;
  `}
`

export const ProfileRightDiv = styled.div`
  margin-left: 20px;
`

export const FormContainer = styled.div`
  width: 85%;
  display: flex;
  justify-content: space-between;
  
  ${media.mobile`
    flex-direction: column;
  `}
`
export const FormUploadContainer = styled.div`
  width: 85%;
`

export const ProLeft = styled.div`
  width: 48%;
  display: flex;
  flex-direction: column;
  
  ${media.mobile`
    width: 100%;
  `}
`

export const ProRight = styled.div`
  width: 48%;
  display: flex;
  flex-direction: column;
  
  ${media.mobile`
    width: 100%;
  `}
`

export const UploadSelect = styled.button`
  width: 100%;
  margin-top: 3rem;
  height: 35px;
  border: 2px solid #d7d7d7;
  border-radius: 15px;
  
  ${media.mobile`
    height: 45px;
  `}
`

export const ProDiv = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 6rem;
`

export const SubmitButton = styled.button`
  width: 200px;
  height: 42px;
  background: linear-gradient(73.6deg, rgba(57, 26, 96, 0.97) 4.94%, rgba(109, 0, 98, 0.97) 71.35%);
  border-radius: 10px;
  color: ${Colors.white};
  margin-top: 2rem;
  margin-bottom: 2rem;
  cursor: pointer;
`


// import styled from "styled-components/macro"
// import Colors from "utils/colors"
// import media from "utils/media"

// export const ProfileContainer = styled.div`
// 	width: 100%;
// 	display: flex;
// 	justify-content: space-between;
// 	align-items: center;
// 	flex-wrap: wrap;
// 	padding: 0rem 20px;
// 	${media.tablet`
// 	width: 100%;
// 	padding: 0rem 20px;
// 	flex-direction: column;
// 	`}
// 	${media.mobile`
// 	width: 100%;
// 	padding: 0rem 10px;
// 	flex-direction: column;
//   justify-content:center;
// 	`}
// `
// export const ProfileDiv = styled.div`
// 	width: 100%;
// 	height: 200px;
// 	display: flex;
// 	align-items: center;
// 	background: #d9d9d9;
// 	border-radius: 30px;
// 	margin: 0 auto;
// 	padding: 20px;
// 	/* margin-top: 4rem; */
// 	${media.mobile`
// 	padding: 0px;
// 	height: 350px;
// 	flex-direction: column;
// 	`}
// `
// export const ProfileDivContainer = styled.div`
// 	width: 90%;
// 	display: flex;
// 	flex-direction: column;
// 	background: #d9d9d9;
// 	border-radius: 30px;
// 	margin: 0 auto;
// 	padding: 20px;
// 	${media.mobile`
// 	width:100%;
// 	padding: 5px 5px;
// 	width: 100%;
// 	.hello___val {
// 		font-size: 14px;
// 		text-align:center;
// 	}
// 	`}
// `
// export const ProfileUserName = styled.h2`
// 	font-family: 'Montserrat', sans-serif;
// 	font-style: normal;
// 	font-weight: 700;
// 	font-size: 35px;
// 	line-height: 35px;
// 	letter-spacing: -0.02em;
// 	color: #6d0062;
// 	${media.mobile`
// 	font-size: 25px;
// 	text-align: center;
// 	`}
// `
// export const ProfileLable = styled.p`
// 	font-family: "Montserrat";
// 	font-style: normal;
// 	font-weight: 400;
// 	font-size: 14px;
// 	line-height: 14px;
// 	letter-spacing: 0.025em;
// 	color: #1e1e1e;
// 	margin-top: 20px;
// 	${media.mobile`
// 	text-align: center;
// 	`}
// `
// export const ProfileRightDiv = styled.div`
// 	margin-left: 20px;
// `

// export const FormContainer = styled.div`
// 	width: 85%;
// 	display: flex;
// 	justify-content: space-between;
// 	${media.mobile`
// 	flex-direction: column;
// 	`}
// `
// export const ProLeft = styled.div`
// 	width: 48%;
// 	display: flex;
// 	flex-direction: column;
// 	${media.mobile`
// 	width: 100%;
// 	`}
// `
// export const ProRight = styled.div`
// 	width: 48%;
// 	display: flex;
// 	flex-direction: column;
// 	${media.mobile`
// 	width: 100%;
// 	`}
// `
// export const UploadSelect = styled.button`
// 	width: 100%;
// 	margin-top: 3rem;
// 	height: 35px;
// 	border: 2px solid #d7d7d7;
// 	border-radius: 15px;
// 	${media.mobile`
// 	height: 45px;
// 	`}
// `
// export const ProDiv = styled.form`
// 	width: 100%;
// 	display: flex;
// 	flex-direction: column;
// 	align-items: center;
// 	margin-bottom: 6rem;
// `
// export const SubmitButton = styled.button`
// 	width: 200px;
// 	height: 42px;
// 	background: linear-gradient(73.6deg, rgba(57, 26, 96, 0.97) 4.94%, rgba(109, 0, 98, 0.97) 71.35%);
// 	border-radius: 10px;
// 	color: ${Colors.white};
// 	margin-top: 2rem;
// 	margin-bottom: 2rem;
// 	cursor: pointer;
// `
