import { observer } from "mobx-react-lite"
import authStore from "mobx/AuthStore"
import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { DASHBOARD_ROUTE } from "routes"
import ForgotPassword from "./ForgotPassword"

function ResetPassPage() {
	const navigate = useNavigate()
	useEffect(() => {
		authStore.getCurrentStep()
		const status = authStore.loggedInStatus()
		if (status) {
			navigate(`${DASHBOARD_ROUTE}`)
		}
	}, [navigate])
	return (
		<div>
			<ForgotPassword />
		</div>
	)
}

export default observer(ResetPassPage)
