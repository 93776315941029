import { WlButton } from "./style"

function CloseFormBtn({ onClick, disabled, ref, w = "40px", h = "40px", mb = "" }) {
	return (
		<WlButton type="button" w={w} h={h} br="50%" mb={mb} ref={ref} bc="transparent" onClick={onClick} disabled={disabled}>
			<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M1 1L13.7742 13.7742" stroke="#B0B0B0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M1 13.7744L13.7742 1.00022" stroke="#B0B0B0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
			</svg>
		</WlButton>
	)
}

export default CloseFormBtn
