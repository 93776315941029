import TextInput from "components/TextInput"
import * as Yup from "yup"
import { useFormik } from "formik"
import authStore from "mobx/AuthStore"
import React, { useEffect } from "react"
import { DASHBOARD_ROUTE, LOGIN_ROUTE } from "routes"
import { AppLogo2 } from "utils/assets"
import { AuthBottomLink, AuthButton, AuthHeader } from ".."
import { LoginContainer, LoginDiv, LoginFormContainer } from "../Login/style"
import { AuthCard, Authcontainer, AuthContent } from "../style"
import { resetPasswordEndpoint } from "services/AuthService"
import { observer } from "mobx-react-lite"
import { Img } from "globalStyles"
import { useNavigate } from "react-router-dom"

function ResetPassword() {
	const navigate = useNavigate()
	const formik = useFormik({
		enableReinitialize: false,
		initialValues: {
			password: "",
			confirm_password: "",
			otp: ""
		},
		onSubmit: async (data, helpers) => {
			const payload = { ...data }
			payload.email = authStore.userEmail
			const response = await resetPasswordEndpoint(payload)
			if (!response.error) {
				navigate(`${LOGIN_ROUTE}`)
				helpers.resetForm()
			}
		},
		validationSchema: Yup.object({
			password: Yup.string().min(8).max(255).required().label("New Password"),
			confirm_password: Yup.string()
				.oneOf([Yup.ref("password"), null], "Confirm Password didn't match")
				.required("Confirm Password is required.")
		}).label("Confirm Password"),
		otp: Yup.string().min(6).max(6).required().label("Email")
	})
	useEffect(() => {
		const status = authStore.loggedInStatus()
		if (status) {
			navigate(`${DASHBOARD_ROUTE}`)
		}
	}, [navigate])

	return (
		<Authcontainer>
			<AuthContent>
				<Img src={AppLogo2} alt="BSL" />
				<LoginContainer>
					<LoginDiv onSubmit={formik.handleSubmit}>
						<AuthCard>
							<LoginFormContainer>
								<AuthHeader text="Reset Password" />
								<TextInput
									type="password"
									label="New password"
									name="password"
									id="password"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.password}
									error={formik.errors.password}
									visible={formik.touched.password}
									placeholder="Enter your new Passwword"
									mt="0.5rem"
								/>
								<TextInput
									type="password"
									label="Confirm password"
									name="confirm_password"
									id="confirm_password"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.confirm_password}
									error={formik.errors.confirm_password}
									visible={formik.touched.confirm_password}
									placeholder="Enter Confirm Password"
									mt="0.5rem"
								/>
								<TextInput
									type="password"
									label="Otp"
									name="otp"
									id="otp"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.otp}
									error={formik.errors.otp}
									visible={formik.touched.otp}
									placeholder="Enter OTP"
									mt="0.5rem"
								/>
								<AuthButton title={authStore.isLoading ? "Loading..." : "Submit"} />
								<AuthBottomLink link={LOGIN_ROUTE} text="Did remember your password?" linktext="Login here" />
							</LoginFormContainer>
						</AuthCard>
					</LoginDiv>
				</LoginContainer>
			</AuthContent>
		</Authcontainer>
	)
}

export default observer(ResetPassword)
