import FullScreenLoader from "components/FullScreenLoader"
import AuthLayout from "layout/AuthLayout"
import React, { useEffect } from "react"
import { toast } from "react-hot-toast"
import { useNavigate, useParams } from "react-router-dom"
import { BASE_URL } from "services"
import http from "services/httpService"
import useSWR from "swr"

import LoanProTab from "./LoanProTab"
import { observer } from "mobx-react-lite"
import authStore from "mobx/AuthStore"
import { DASHBOARD_NEXTOFKINROUTE, DASHBOARD_PAYMENT_DETAILS_ROUTE, DASHBOARD_PROFILEROUTE } from "routes"
import { onBoardStep } from "routes/apirRoutes"
import { checkOnBoarding } from "services/MiscService"

const LoanDetailsComp = () => {
	let params = useParams()
	const navigate = useNavigate()
	const url = `${BASE_URL}/loans/${params?.id}`

	async function getLoan() {
		try {
			const { data } = await http.get(`${BASE_URL}/loans/${params?.id}`)
			return data
		} catch (err) {
			if (err && err.response) {
				err?.response?.data?.error && toast.error(err.response.data.message)
			} else {
				toast.error("Network Error")
			}
		}
	}

	const { data: Loan } = useSWR(url, getLoan)
	useEffect(() => {
		const handleOnBoarded = async () => {
			const info = await checkOnBoarding();
			if(info?.error) { 
			  toast.error(info.error);
			}else{
			  // reroute to onboarding page
			  if(!info.onBoarded && info.nextStep === onBoardStep.profile){
				navigate(DASHBOARD_PROFILEROUTE)
			  }
			  else if(!info.onBoarded && info.nextStep === onBoardStep.next_of_kin){
				navigate(DASHBOARD_NEXTOFKINROUTE)
			  }
			  else if(!info.onBoarded && info.nextStep === onBoardStep.bank_account){
				navigate(DASHBOARD_PAYMENT_DETAILS_ROUTE)
			  }
			}
		  }
		handleOnBoarded();
		authStore.loggedInStatus()
	}, [navigate])
	if (!Loan) return <FullScreenLoader show={true} />
	return (
		<AuthLayout>
			<LoanProTab loan={Loan} />
		</AuthLayout>
	)
}

export default observer(LoanDetailsComp)
