import React from "react"
import SettingsComponent from "components/Dashboard/Settings"
import AuthLayout from "layout/AuthLayout"

const SettingsPage = () => {
	return (
		<AuthLayout>
			<SettingsComponent />
		</AuthLayout>
	)
}

export default SettingsPage
