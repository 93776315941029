import styled from "styled-components"
import Colors from "utils/colors"
import media from "utils/media"

export const FormContainer = styled.form`
	width: 100%;
	margin: 0 auto;
	${media.smallDesktopMinimum`
	width: 80%;
	`}
	${media.tablet`
	width: 80%;
	`}
	${media.mobile`
	width: 100%;
	`}
`
export const SubmitBtn = styled.button`
	border: none;
	outline: none;
	background: ${Colors.white};
	width: 100%;
	height: 45px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border-radius: 8px;
	color: #6d0062;
	margin-top: 2rem;

	${media.mobile`
  margin-top: 1rem;
  `}
`
export const TextArea = styled.textarea`
	width: 100%;
	background: #ffffff;
	::placeholder {
		color: ${Colors.muted};
	}
	border-radius: 15px;
	height: 200px;
	margin-top: 0.5rem;
	padding: 10px 20px;

	${media.mobile`
  `}
`

export const TextAreaLabel = styled.p`
	color: ${Colors.white};
	margin-top: 30px;
`

export const TextAreaErrMsg = styled.p`
	color: #dd0e3b;
	font-size: 14px;
`

export const ContactSectionMain = styled.div`
	width: 100%;
`
export const ContactSectionDiv = styled.div`
	width: 100%;
	display: flex;
	margin: 6rem 0px;
	justify-content: center;
	${media.mobile`
  flex-direction: column	
	`}
`

export const ContactLeft = styled.div`
	width: 40%;
	padding: 30px 80px;
	background: linear-gradient(73.6deg, rgba(57, 26, 96, 0.97) 4.94%, rgba(109, 0, 98, 0.97) 71.35%);
	border-radius: 13px;
	${media.mobile`
    width: 85%;
	padding: 20px 20px;
  margin: 0 auto;
	`}
`

export const ContactRight = styled.div`
	width: 40%;
	padding: 30px 80px;
	${media.mobile`
  display: none;
	width: 100%;
	padding: 10px 20px;
   
	`}
`

export const MobileContactDiv = styled.div`
	display: none;

	${media.mobile`
display: flex;
flex-direction: column;
width: 85%;
  padding: 0px 10px;
  margin: 0 auto;
  margin-bottom: 1rem;
`}
`
export const ContactItemDiv = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	margin: 15px 0px;
	.text-contact {
		margin-left: 10px;
		font-family: "Montserrat";
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 29px;

		color: #000000;
	}

	${media.mobile`
  .text-contact {
    line-height: 20px;
  }
  `}
`

export const ContactHeader = styled.h3`
	font-family: 'Montserrat', sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 45px;
	line-height: 52px;
	letter-spacing: -0.04em;
	color: #6d0062;

	${media.mobile`
  font-size: 35px;
  line-height: 36px;
  `}
`

export const ContactPara = styled.p`
	font-family: 'Montserrat', sans-serif;
	font-weight: 400;
	font-size: 18px;
	line-height: 29px;

	${media.mobile`
  font-size: 16px;
  `}
`
