import React from "react"
import AuthLayout from "layout/AuthLayout"
import { MainContainer } from "globalStyles"
import Onboarding from "components/Dashboard/Onboarding"

const OnboardingPage = () => {
	return (
		<AuthLayout>
			<MainContainer>
				<Onboarding />
			</MainContainer>
		</AuthLayout>
	)
}

export default OnboardingPage
