import toast from "react-hot-toast"
import loanStore from "mobx/LoanStore"

import { BASE_URL } from "services"
import http from "./httpService"
import apiRoute from "routes/apirRoutes"


export async function addInstitutionUnder(payload) {
	loanStore.loading() 
	try {
		const { data } = await http.post(apiRoute.addLoanInstition, payload)
		toast.success(data.message)
		loanStore.loading() 
		return data
	} catch (err) {
		if (err && err.response) {
			loanStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			loanStore.loading()
			toast.error("Network Error")
		}
	}
}export async function addPostGradInstitution(payload) {
	loanStore.loading()
	try {
		const { data } = await http.post(apiRoute.post_graduate_institution, payload)
		toast.success(data.message)
		loanStore.loading() 
		return data
	} catch (err) {
		if (err && err.response) {
			loanStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			loanStore.loading()
			toast.error("Network Error")
		}
	}
}
export async function editInstitution(payload) {
	loanStore.loading() 
	try {
		const { data } = await http.post(apiRoute.edit_institution, payload)
		toast.success(data.message)
		loanStore.loading() 
		return data
	} catch (err) {
		if (err && err.response) {
			loanStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			loanStore.loading()
			toast.error("Network Error")
		}
	}
}
export async function addLoanDetailsUnder(payload) {
	loanStore.loading()
	try {
		const { data } = await http.put(`${BASE_URL}/loans/others`, payload)
		toast.success(data.message)
		loanStore.loading()
		loanStore.setCurrentStepUnder("next-of-kin")
		return data
	} catch (err) {
		if (err && err.response) {
			loanStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			loanStore.loading()
			toast.error("Network Error")
		}
	}
}
export async function addPostGradLoanDetails(payload) {
	loanStore.loading()
	try {
		const { data } = await http.put(`${BASE_URL}/loans/postgradute/others`, payload)
		toast.success(data.message)
		loanStore.loading()
		loanStore.setCurrentStepUnder("next-of-kin")
		return data
	} catch (err) {
		if (err && err.response) {
			loanStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			loanStore.loading()
			toast.error("Network Error")
		}
	}
}
export async function editLoanDetails(payload) {
	loanStore.loading()
	try {
		const { data } = await http.put(`${BASE_URL}/loans/edit/others`, payload)
		toast.success(data.message)
		loanStore.loading()
		loanStore.setCurrentStepUnder("next-of-kin")
		return data
	} catch (err) {
		if (err && err.response) {
			loanStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			loanStore.loading()
			toast.error("Network Error")
		}
	}
}

export async function addLoanGuarantorOne(payload) {
	loanStore.loading()
	try {
		const { data } = await http.put(`${BASE_URL}/loans/guarantor_one`, payload)
		toast.success(data.message)
		loanStore.loading()

		return data
	} catch (err) {
		if (err && err.response) {
			loanStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			loanStore.loading()
			toast.error("Network Error")
		}
	}
}
export async function addPostGuarantorOne(payload) {
	loanStore.loading()
	try {
		const { data } = await http.put(`${BASE_URL}/loans/guarantor_one`, payload)
		toast.success(data.message)
		loanStore.loading()

		return data
	} catch (err) {
		if (err && err.response) {
			loanStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			loanStore.loading()
			toast.error("Network Error")
		}
	}
}
export async function editGuarantorOne(payload) {
	loanStore.loading()
	try {
		const { data } = await http.put(`${BASE_URL}/loans/edit/guarantor_one`, payload)
		toast.success(data.message)
		loanStore.loading()

		return data
	} catch (err) {
		if (err && err.response) {
			loanStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			loanStore.loading()
			toast.error("Network Error")
		}
	}
}
export async function addLoanGuarantorTwo(payload) {
	loanStore.loading()
	try {
		const { data } = await http.put(`${BASE_URL}/loans/guarantor_two`, payload)
		toast.success(data.message)
		loanStore.loading()

		return data
	} catch (err) {
		if (err && err.response) {
			loanStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			loanStore.loading()
			toast.error("Network Error")
		}
	}
}
export async function addPostLoanGuarantorTwo(payload) {
	loanStore.loading()
	try {
		const { data } = await http.put(`${BASE_URL}/loans/guarantor_two`, payload)
		toast.success(data.message)
		loanStore.loading()

		return data
	} catch (err) {
		if (err && err.response) {
			loanStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			loanStore.loading()
			toast.error("Network Error")
		}
	}
}
export async function editGuarantorTwo(payload) {
	loanStore.loading()
	try {
		const { data } = await http.put(`${BASE_URL}/loans/edit/guarantor_two`, payload)
		toast.success(data.message)
		loanStore.loading()

		return data
	} catch (err) {
		if (err && err.response) {
			loanStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.message || "Sorry could not connect")
		} else {
			loanStore.loading()
			toast.error("Network Error")
		}
	}
}
export async function saveEdit(id) {
	loanStore.loading()
	try {
		const { data } = await http.post(`${BASE_URL}/loans/edit/`, {id})
		toast.success(data.message)
		loanStore.loading()
		return data
	} catch (err) {
		if (err && err.response) {
			loanStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			loanStore.loading()
			toast.error("Network Error")
		}
	}
}

export async function addNextOfKinUnder(payload) {
	loanStore.loading()
	try {
		const { data } = await http.put(apiRoute.addNextOfKin, payload)
		toast.success(data.message)
		loanStore.loading()
		loanStore.setCurrentStepUnder("guarantor")
		return data
	} catch (err) {
		if (err && err.response) {
			loanStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			loanStore.loading()
			toast.error("Network Error")
		}
	}
}

export async function addGuarantorUnder(payload) {
	loanStore.loading()
	try {
		const { data } = await http.put(`${BASE_URL}/loans/undergraduate/guarantor`, payload)
		toast.success(data.message)
		loanStore.loading()
		loanStore.setCurrentStepUnder("file-upload")
		return data
	} catch (err) {
		if (err && err.response) {
			loanStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			loanStore.loading()
			toast.error("Network Error")
		}
	}
}

export async function addFilesPostGrads(payload) {
	loanStore.loading()
	try {
		const { data } = await http.put(`${BASE_URL}/loans/document/postgrads`, payload)
		toast.success(data.message)
		loanStore.loading()
		return data
	} catch (err) {
		if (err && err.response) {
			loanStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			loanStore.loading()
			toast.error("Network Error")
		}
	}
}
export async function editFiles(payload) {
	loanStore.loading()
	try {
		const { data } = await http.put(`${BASE_URL}/loans/edit/document`, payload)
		toast.success(data.message)
		loanStore.loading()
		return data
	} catch (err) {
		if (err && err.response) {
			loanStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			loanStore.loading()
			toast.error("Network Error")
		}
	}
}
export async function addFilesUnder(payload) {
	loanStore.loading()
	try {
		const { data } = await http.put(`${BASE_URL}/loans/document`, payload)
		toast.success(data.message)
		loanStore.loading()
		return data
	} catch (err) {
		if (err && err.response) {
			loanStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			loanStore.loading()
			toast.error("Network Error")
		}
	}
}
export async function addPostGradFilesUnder(payload) {
	loanStore.loading()
	try {
		const { data } = await http.put(`${BASE_URL}/loans/document/postgrads`, payload)
		toast.success(data.message)
		loanStore.loading()
		return data
	} catch (err) {
		if (err && err.response) {
			loanStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			loanStore.loading()
			toast.error("Network Error")
		}
	}
}
export async function editFilesUnder(payload) {
	loanStore.loading()
	try {
		const { data } = await http.put(`${BASE_URL}/loans/edit/document`, payload)
		toast.success(data.message)
		loanStore.loading()
		return data
	} catch (err) {
		if (err && err.response) {
			loanStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			loanStore.loading()
			toast.error("Network Error")
		}
	}
}
export async function addFilesUnderReapply(payload) {
	loanStore.loading()
	try {
		const { data } = await http.put(`${BASE_URL}/loans/document/reapply`, payload)
		toast.success(data.message)
		loanStore.loading()
		return data
	} catch (err) {
		if (err && err.response) {
			loanStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			loanStore.loading()
			toast.error("Network Error")
		}
	}
}
export async function addPostGradFilesUnderReapply(payload) {
	loanStore.loading()
	try {
		const { data } = await http.put(`${BASE_URL}/loans/document/postgrads/reapply`, payload)
		toast.success(data.message)
		loanStore.loading()
		return data
	} catch (err) {
		if (err && err.response) {
			loanStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			loanStore.loading()
			toast.error("Network Error")
		}
	}
}
export async function addFilesPostReapply(payload) {
	loanStore.loading()
	try {
		const { data } = await http.put(`${BASE_URL}/loans/document/postgrads/reapply`, payload)
		toast.success(data.message)
		loanStore.loading()
		return data
	} catch (err) {
		if (err && err.response) {
			loanStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			loanStore.loading()
			toast.error("Network Error")
		}
	}
}
export async function addFilesGuarantor(payload) {
	loanStore.loading()
	try {
		const { data } = await http.put(`${BASE_URL}/loans/document/guarantor`, payload)
		toast.success(data.message)
		loanStore.loading()
		return data
	} catch (err) {
		if (err && err.response) {
			loanStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			loanStore.loading()
			toast.error("Network Error")
		}
	}
}

export async function verifyPayment(payload) {
	loanStore.togglePaymentLoading()
	try {
		const { data } = await http.post(`${BASE_URL}/loans/verify_app_payment`, payload)
		toast.success(data.message)
		loanStore.togglePaymentLoading()
		loanStore.setCurrentStepUnder("verify-bvn")
		return data
	} catch (err) {
		if (err && err.response) {
			loanStore.togglePaymentLoading()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			loanStore.togglePaymentLoading()
			toast.error("Network Error")
		}
	}
}
export async function getCurrentLoanStage (payload){
	try{
		const data = await http.put(apiRoute.currentLoanProgressStage, payload);
		return data;
	} catch (err) {
		if (err && err.response) {
			loanStore.loading()
		return	err?.response?.data?.error
		} else {
			toast.error(err)
		}
	}
	finally{
		loanStore.loading()
	}
}
export async function deleteLoan(id) {
	try {
		const { data } = await http.delete(`${BASE_URL}/loans/${id}`)
		return {
			data,
			err:null,
			status:"success"
		}
	} catch (err) {
		console.log(err)
		return {
			data:null,
			status:"failed",
			err,
			error_message:err.message
		}
	}
}
export async function deleteLoans(selectedLoans) {
	try {
		const { data } = await http.post(`${BASE_URL}/loans/deleted`,{selectedLoans})
		return {
			data,
			err:null,
			status:"success"
		}
	} catch (err) {
		console.log(err)
		return {
			data:null,
			status:"failed",
			err,
			error_message:err.message
		}
	}
}
// export async function deleteLoans(loans) {
// 	try {
// 		const { data } = await http.delete(`${BASE_URL}/${id}`)
// 		return [data,null]
// 	} catch (err) {
// 		return [null,err]
// 	}
// }