import axios from "axios"
import { Link } from "react-router-dom"
import { useState } from "react"
import { BASE_URL } from "services"
import { message,Spin } from "antd"

class LoanOfferService{
    
    async accept(id) {
        try{
            const response = await axios.patch(`${BASE_URL}/loans/offer/${id}/accepted`)
            return [response.data,null]
        }
        catch(error){
            return [null,error]
        }
    }
    async reject(id) {
        try{
            const response = await axios.patch(`${BASE_URL}/loans/offer/${id}/rejected`)
            return [response.data,null]
        }
        catch(error){
            return [null,error]
        }
    }
}

const useLoanOffer = (id)=>{
    const [loading,setLoading] = useState(false)
    const [loadingReject,setLoadingReject] = useState(false)

    const OfferService = new LoanOfferService()

    async function handleReject(){
        setLoadingReject(true)
        const [data,error] = await OfferService.accept(id)
        console.log("",data)
        setLoadingReject(false)

        if(error){
            return message.error(error.message)
        }
        message.success("You Have successfully accepted this loan, your loan would be disbursed soon")
    }
    async function handleAccept(){
        setLoading(true)
        const [data,error] = await OfferService.accept(id)
        console.log("",data)
        setLoading(false)

        if(error){
            return message.error(error.message)
        }
        message.success("You Have successfully accepted this loan, your loan would be disbursed soon")
    }

    return {
        loading,
        handleAccept,
        loadingReject,
        handleReject
    }
}


export function EditAlert({loan}){
    return (
    <div className="p-4 my-2">
        <div className="bg-indigo-50 w-full border border-solid border-indigo-200 rounded-md p-2 px-4 py-4 flex justify-between items-center gap-2">
            <div>
                <p className="font-semibold text-indigo-600 font-poppins text-lg">Loan Edit Requested</p>
                <p className="font-poppins">{loan.request_edit_reason}</p>
            </div>
             <Link to={`/dashboard/edit/${loan.id}`}>
             <button className="ml-auto px-6 py-2 rounded-md font-semibold bg-indigo-400 text-white">Click To Edit</button>
            </Link>
        </div>
    </div>
    )
}

export function ApproveALert({loan}){
    const {loading,loadingReject,handleAccept,handleReject} = useLoanOffer(loan.id)
    return (
    <div className="p-4 my-2">
        <div className="bg-green-100 border-[1px] border-green-800 w-full rounded-lg p-2 py-4 flex items-center gap-2">
            <p>Congratulations your loan application of <b>₦{loan.amount.toLocaleString()}</b> has been approved</p>
             <button onClick={handleAccept} disabled={loading || loadingReject} className="ml-auto px-8 py-1 rounded-md font-semibold bg-green-500 text-green-800" >
                {loading?<Spin/>:"Accept"}
            </button>
             <button onClick={handleReject} disabled={loading || loadingReject} className="px-8 py-1 rounded-md font-semibold bg-red-500 text-red-800">
                {loadingReject?<Spin/>:"Reject"}
            </button>
        </div>
    </div>
    )
}