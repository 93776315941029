import { createContext, useState,useEffect } from "react";
import http from "services/httpService"
import { BASE_URL } from "services"
import { useParams } from "react-router-dom";

export const loanSteps = ["","details","surety_1","surety_2","upload_docs","loan_review","payment"]


export const UndergraduateLoanStepContext = createContext({
    step: 0,
    next:()=>{},
    prev:()=>{},
    goToStep:(newStep)=>console.log(newStep),
})


export default function UndergraduateLoanStepProvider({children}){
    const [step,setStep] = useState(0)
    const [completedSteps,setCompletedSteps] = useState([0])
    const params = useParams()

    const next = ()=>{
        if(step < loanSteps.length - 1){
            setStep(prev=> prev + 1)
            if(!completedSteps.includes(step + 1)){
                setCompletedSteps(prev=>[...prev,step +1])
            }
        }
    }
    const prev = ()=>{
        if(step > 0){
            setStep(prev=>prev - 1)
        }
    }

    const getLoanData = async (apiURL) => {
		const res = await http.get(apiURL)
		const loan = res?.data?.data
        const currentStep = loan[0].loan_step
        console.log(currentStep)
        // console.log(loan)
        if(!loan) return;
        if(loan.length <= 0) return;
        const getStep = ()=>{
			if(loan[0].loan_step === "payment") {
                setCompletedSteps([0,1,2,3,4,5,6])
                setStep(6)
                return;
            };
			if(loan[0].loan_step === "upload_docs" || loan[0].loan_step === "guarantor") {
                setCompletedSteps([0,1,2,3,4,])
                setStep(4)
                return;
            };
			if(loan[0].loan_step === "surety_2") {
                setCompletedSteps([0,1,2,3,])
                setStep(3)
                return;
            };
			if(loan[0].loan_step === "surety_1") {
                setCompletedSteps([0,1,2])
                setStep(2)
                return;
            };
			if(loan[0].loan_step === "details") {
                setCompletedSteps([0,1])
                setStep(1)
                return;
            };
            setCompletedSteps([0])
            setStep(0)
		}
		getStep()

	}

    const goToStep = (newStep) => {
        if(completedSteps.includes(newStep)) {
            setStep(newStep)
        }
    }

    useEffect( ()=>{
        if(params.id){
            const url = `${BASE_URL}/loans/${params?.id}`
            getLoanData(url)
        }
	},[params?.id])
    return (
        <UndergraduateLoanStepContext.Provider value={{step,next,prev,goToStep}}>
            {children}
        </UndergraduateLoanStepContext.Provider>
    )
}