export const nextOfKinRelData = [

    {
        id: 'gdhjsk',
        label: 'Sibling',
        value: 'sibling',
    },
    {
        id: 'hfjkdl',
        label: 'Spouse',
        value: 'spouse',
    },
    {
        id: 'hdgfdk',
        label: 'Friend',
        value: 'friend',
    },
    {
        id: 'jjfhf',
        label: 'Relative',
        value: 'relative',
    },
    {
        id: 'jjfhf',
        label: 'Child',
        value: 'child',
    },
    {
        id: 'jjfhf',
        label: 'Parent',
        value: 'parent',
    },

]