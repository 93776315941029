import { Link } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'
import { StdMeeting2 } from 'utils/assets'
import Colors from 'utils/colors'
import media from 'utils/media'

export const HeroSecCon = styled.div`
  margin-top: 0.2rem;

  ${media.mobile`
  margin-top: 0;

`}
`

export const GetStartedBtn = styled(Link)`
  width: 350px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  text-decoration: none;
  font-weight: 550;
  font-size: 18px;
  margin-top: 20px;
  padding: 10px;
  cursor: pointer;
  background: ${(props) =>
    props.variant === 'whitebg' ? Colors.white : Colors.gradient};
  border-radius: 10px;
  color: ${(props) =>
    props.variant === 'whitebg' ? Colors.primary : Colors.white};

  ${media.mobile`
 width: 300px;
  height: 50px;
  padding: 15px 10px;
  font-size: 16px;
  font-weight: 600px
 `}
`
export const zoomInOut = keyframes`
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
`

export const HeroContainer = styled.div`
  width: 100%;
  height: 100vh;
  background: url(${StdMeeting2}),
    linear-gradient(
      75.6deg,
      rgba(5, 0, 5, 0.97) 4.94%,
      rgba(5, 0, 5, 0.97) 75.35%
    );
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${media.smallDesktopMinimum`
padding: 10px 0px;
height: 100%;
`}

  ${media.mobile`
	padding: 0px 30px;
	height: 100vh;
`}

${media.smallMobile`
padding: 0 5px;
margin-left: -10px;
`}
`

// export const BackgroundContainer = styled.div`
//   width: 100%;
//   height: 100%;
//   background: url(${StdMeeting2}),
//     linear-gradient(
//       75.6deg,
//       rgba(5, 0, 5, 0.97) 4.94%,
//       rgba(5, 0, 5, 0.97) 75.35%
//     );
//   background-repeat: no-repeat;
//   background-size: cover;
//   background-position: center center;
//   position: absolute;
//   animation: ${zoomInOut} 5s ease-in-out infinite;
//   overflow: hidden;
//   z-index: -1;
// `

export const HeroOverlay = styled.div`
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(57, 26, 96, 0.7), rgba(57, 26, 96, 0.7));
  z-index: 0;
`

export const HeroDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 60px;
  z-index: 1;
  overflow: hidden;

  ${media.smallDesktopMinimum`
margin-top:5rem;
	`}

  ${media.mobile`
  width: 100%;
  flex-direction: column;
  padding: 0px 0px;
  `}
`
export const LeftDiv = styled.div`
  width: 55%;
  display: flex;
  flex-direction: column;

  ${media.mobile`
	width: 100%;
  margin-left: 0px;
	`}
`

export const HeadGroup = styled.div`
  display: flex;
  flex-direction: column;
`

export const HeroHeader = styled.h3`
  width: 95%;
  font-family: 'Montserrat', sans-serif;
  font-weight: 550;
  font-size: 30px;
  color: ${Colors.white};

  span {
    color: #ed3237;
    font-weight: 700;
  }

  ${media.smallDesktopMinimum`
font-size: 26px;
	`}

  ${media.mobile`
  width: 100%;
  font-size: 26px;
  word-spacing: 0.5px;
  
  `}
`

export const RightDiv = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10%;
  padding: 50px;
  overflow: hidden;
  
  img {
    width: 95%;
    object-fit: cover;
  }

  ${media.smallDesktopMinimum`
 width: 100%;
 padding: 0px;
 margin-top: 15%;
 

  `}

  ${media.mobile`
  display: none;

	`}
`

export const ZoomImage = styled.img`
  width: 70%;
`
