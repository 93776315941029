import { Img } from "globalStyles"
import React from "react"
import { Link, useLocation } from "react-router-dom"
import { ABOUT_ROUTE, BLOG_ROUTE, CONTACT_ROUTE, FAQ_ROUTE, HOME_ROUTE, LOGIN_ROUTE } from "routes"
import { CloseIcon } from "utils/assets"
import NavButtonMobile from "./NavButtonMobile"
import { AuthButtonDiv, CloseMenuContainer, JoinBtnMobile, MenuContainer, MenuOverlay } from "./style"

function MobileMenu({ isOpen, close }) {
	const location = useLocation()

	return (
		<MenuOverlay open={isOpen} onClick={close}>
			<MenuContainer>
				<CloseMenuContainer>
					<button onClick={close}>
						<Img src={CloseIcon} alt={"Close Menu"} />
					</button>
				</CloseMenuContainer>
				<NavButtonMobile style={{ color: location.pathname === "/" ? "red" : "" }} to={HOME_ROUTE} title="Home" mt="20px" />
				<NavButtonMobile style={{ color: location.pathname.includes(ABOUT_ROUTE) ? "red" : "" }}  to={ABOUT_ROUTE} title="About" />
				<NavButtonMobile style={{ color: location.pathname.includes(FAQ_ROUTE) ? "red" : "" }} to={FAQ_ROUTE} title="Faqs" />
				<NavButtonMobile style={{ color: location.pathname.includes(CONTACT_ROUTE) ? "red" : "" }} to={CONTACT_ROUTE} title="Contact" />
				<NavButtonMobile style={{ color: location.pathname.includes(BLOG_ROUTE) ? "red" : "" }} to={BLOG_ROUTE} title="Blog" />
				<AuthButtonDiv> 
					<Link style={{ width: "100%", display: "flex", justifyContent: "center" }} to={LOGIN_ROUTE}>
						<JoinBtnMobile>Sign In</JoinBtnMobile>
					</Link>
				</AuthButtonDiv>
			</MenuContainer>
		</MenuOverlay>
	)
}

export default MobileMenu
