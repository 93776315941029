import MobileMenu from "components/MobileMenu"
import { Img } from "globalStyles"
import { observer } from "mobx-react-lite"
import authStore from "mobx/AuthStore"
import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { DASHBOARD_ROUTE } from "routes"
import { ByshelbDescLogo, HamburgerIcon, HamburgerWhiteIcon } from "utils/assets"
import Colors from "utils/colors"
import { CreateButton, HamBurgerBtn, NavActionButton, NavBarEndDiv, NavContainer, Overlay, Ulist } from "./style"

function UniqueNavbar() {
	const [isTop, setIsTop] = useState(false)
	const [open, setOpen] = useState(false)

	const toggleNav = () => {
		setOpen(!open)
	}

	const changeNavBackground = () => {
		if (window.scrollY >= 100) {
			setIsTop(true)
		} else {
			setIsTop(false)
		}
	}
	window.addEventListener("scroll", changeNavBackground)
	const navigate = useNavigate()
	useEffect(() => {
		const status = authStore.loggedInStatus()
		if (status) {
			navigate(`${DASHBOARD_ROUTE}`)
		}
	}, [navigate])
	return (
		<>
			{open && <MobileMenu isOpen={open} close={() => toggleNav()} />}
			<NavContainer bgColor={isTop ? Colors.white : "transparent"}>
				<Link className="home-link" to="/">
					<Img src={ByshelbDescLogo} alt="BSHELF" />
				</Link>
				<Ulist className="link-list" fontColor={isTop ? Colors.black : Colors.white}>
					<li>
						<Link className="nav-link-item" to="/">
							Home
						</Link>
					</li>

					<li>
						<Link className="nav-link-item" to="/about" style={{ color: "red" }}>
							About Us
						</Link>
					</li>

					<li>
						<Link className="nav-link-item" to="/faqs">
							FAQS
						</Link>
					</li>
					<li>
						<Link className="nav-link-item" to="/contact">
							Contact
						</Link>
					</li>
					<li>
						<Link className="nav-link-item" to="/blog">
							Blog
						</Link>
					</li>
				</Ulist>
				<NavBarEndDiv>
					<Link className="nav-link-item" to="/login">
						<NavActionButton>Sign in</NavActionButton>
					</Link>
					<Link className="nav-link-item" to="/signup">
						<CreateButton createBtnColor={isTop ? "#F3F3F3" : Colors.white}>Create an account</CreateButton>
					</Link>
					<HamBurgerBtn onClick={() => toggleNav()}>
					<Overlay open={open} bgColor={Colors.primaryLight} />
						<Img src={isTop ? HamburgerIcon : HamburgerWhiteIcon} alt="Menu" />
					</HamBurgerBtn>
				</NavBarEndDiv>
			</NavContainer>
		</>
	)
}

export default observer(UniqueNavbar)
