import { configure, makeAutoObservable, observable } from "mobx"

configure({
	enforceActions: "never"
})

class LoanStore {
	isLoading = false
	isLoading_bvn = false
	isLoading_pay = false
	userId = ""
	currentStepUnder = ""
	userEmail = ""
	currentUser = {}
	constructor() {
		makeAutoObservable(this, {
			isAuth: observable,
			isLoading: observable,
			resendingOtp: observable,
			currentUser: observable,
			userId: observable,
			isLoading_pay: observable,
			currentStep: observable
		})
	}

	loading() {
		this.isLoading = !this.isLoading
	}

	loadingBvn() {
		this.isLoading_bvn = !this.isLoading_bvn
	}
	togglePaymentLoading() {
		this.isLoading_pay = !this.isLoading_pay
	}

	getUserToken = () => {
		const token = sessionStorage.getItem("token")

		if (!token) return null
		return token
	}

	setCurrentStepUnder(payload) {
		this.currentStep = localStorage.setItem("progress-state", payload)
		this.getCurrentStepUnder()
	}

	getCurrentStepUnder() {
		const status = localStorage.getItem("progress-state")
		this.currentStepUnder = status ? status : ""
	}

	setUserEmail(payload) {
		localStorage.setItem("auth-email", payload)
		this.getUserEmail()
	}

	getUserEmail() {
		const email = localStorage.getItem("auth-email")
		this.userEmail = email ? email : ""
	}
}

const loanStore = new LoanStore()

export default loanStore
