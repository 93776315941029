import styled from "styled-components"
import { Aboutbg, amas, ebi } from "utils/assets"
import Colors from "utils/colors"
import media from "utils/media"

export const AboutContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	padding-top: 0rem;
	margin-top: 0rem;
	.about-title {
		text-align: center;
		margin-bottom: 2rem;
	}
	${media.mobile`
	padding-top: 0rem;
	`}
`

export const AboutHeroDiv = styled.div`
	width: 100%;
	height: 100vh;
	background-image: url(${Aboutbg}), linear-gradient(73.6deg, rgba(57, 26, 96, 0.97) 4.94%, rgba(109, 0, 98, 0.97) 71.35%);
	${media.mobile`
  background-size: cover;
  background-repeat: no-repeat
  
  `}
`

export const AboutContent = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	/* margin-top: 3rem; */
	padding: 2rem 60px;
	${media.tablet`
	width: 100%;
	padding: 0rem 20px;
	flex-direction: column;
	`}
	${media.mobile`
	width: 100%;
	padding: 0rem 20px;
	flex-direction: column;
  justify-content:center;
	`}
`

export const HeroDetailsDiv = styled.div`
	display: flex;
	width: 40%;
	flex-direction: column;
	margin-left: 200px;
	margin-top: 10%;
	h3 {
		font-family: 'Montserrat', sans-serif;
		font-style: normal;
		font-weight: 700;
		font-size: 80px;
		line-height: 107.5%;
		letter-spacing: -0.04em;
		color: #ffffff;
		margin-top: 10px;
	}

	p {
		font-family: 'Montserrat', sans-serif;
		font-style: normal;
		font-weight: 700;
		font-size: 14px;
		line-height: 21px;
		letter-spacing: 0.5px;
		color: #ffffff;
		margin-top: 10px;
	}

	button {
		background: #ffffff;
		border-radius: 10px;
		width: 226px;
		height: 60px;
		outline: none;
		border: none;
		font-family: "Montserrat";
		font-style: normal;
		font-weight: 800;
		font-size: 20px;
		line-height: 22px;
		color: #6d0062;
		margin-top: 25px;
		cursor: pointer;
	}
	${media.mobile`
  width: 100%;
  margin-left: 0px;
  padding: 0px 20px;
  margin-top:8rem;
  h3 {
    font-size: 50px;
  }
  `}
`
export const AboutSectionOne = styled.div`
	width: 80%;
	display: flex;
	justify-content: center;
	margin: 0 auto;
	/* background: red; */
	margin-top: 2rem;
	${media.mobile`
  flex-direction: column;
  width: 90%;
  
  `}
`
export const SectionOneLeft = styled.div`
	width: 30%;
	background: ${Colors.gradient};
	border-radius: 56px;
	padding: 15px 20px;
	padding-left: 25px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	/* align-items: center; */

	.title-gothrough {
		font-family: 'Montserrat', sans-serif;
		font-style: normal;
		font-weight: 700;
		font-size: 40px;
		line-height: 107.5%;
		letter-spacing: -0.04em;
		color: ${Colors.white};
	}
	.desc-gothrough {
		font-family: 'Montserrat', sans-serif;
		font-style: normal;
		font-weight: 400;
		font-size: 18px;
		line-height: 29px;
		color: ${Colors.white};
	}
	${media.mobile`
  width: 100%;
  
  `}
`
export const SectionOneRight = styled.div`
	width: 50%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-left: 40px;

	.title-brand-header {
		font-family: 'Montserrat', sans-serif;
		font-style: normal;
		font-weight: 700;
		font-size: 18px;
		line-height: 25px;
		color: #6d0062;
	}
	.desc-brand {
		font-family: "Montserrat";
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 25px;
		color: ${Colors.black};
	}
	${media.mobile`
  width: 100%;
  margin-left: 0px;
  margin-top:2rem;
  .title-brand-header {
		font-family: 'Montserrat', sans-serif;
		font-style: normal;
		font-weight: 700;
		font-size: 18px;
		line-height: 25px;
		color: #6d0062;
	}
	.desc-brand {
		font-family: "Montserrat";
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 25px;
		color: ${Colors.black};
	}
  
  `}
`

export const AboutSectionTwo = styled.div`
	width: 70%;
	margin: 0 auto;
	margin-top: 4rem;
	p {
		margin-bottom: 15px;
		display: block;
	}
	.seriake {
		color: ${Colors.primaryLight};
		font-weight: 700;
	}
	${media.mobile`
  width: 100%;
  padding:20px 20px;
  `}
`

export const AboutLeft = styled.div`
	width: 49%;
	/* height: 320px; */
	display: flex;
	flex-wrap: wrap;
	img {
		height: 100%;
	}
	${media.tablet`
	width: 100%;
	margin: 0 auto;
	margin-top:0rem;
	img {
		width: 98%;
	}
	`}
	${media.mobile`
	width: 100%;
	margin: 0 auto;
	margin-top:0rem;
	img {
		width: 98%;
	}
	`}
`
export const AboutRight = styled.div`
	width: 49%;
	display: flex;
	flex-wrap: wrap;
	p {
		font-family: "TT Norms Pro";
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 32px;
		letter-spacing: 0.0075em;
		/* margin-bottom: -10px; */
	}
	${media.tablet`
	height: 200px;
	width:100%;
	/* padding:0px 20px; */

	P{
		font-size: 14px;
		line-height: 32px;
		letter-spacing: 0.0075em;
		/* margin-top: -2px; */
	}
	`}
	${media.mobile`
	height: 100%;
	width:100%;
	margin-top:1rem;
	margin-bottom: -2rem;
	/* padding:0px 20px; */

	P{
		margin-bottom:-30px;
	}
	`}
	${media.smallMobile`
	height: 100%;
	width:100%;
	margin-top:1rem;
	margin-bottom: -2rem;
	/* padding:0px 20px; */

	P{
		margin-bottom:0px;
	}
	`}
`

export const AboutHeaderDiv = styled.div`
	width: 100%;
	height: 857px;
	left: 0px;
	top: -7px;
	background-image: url(${Aboutbg}), linear-gradient(73.6deg, rgba(57, 26, 96, 0.97) 4.94%, rgba(109, 0, 98, 0.97) 71.35%);

	h3 {
		position: absolute;
		left: 202px;
		top: 190px;

		font-family: 'Montserrat', sans-serif;
		font-style: normal;
		font-weight: 700;
		font-size: 86px;
		line-height: 107.5%;

		letter-spacing: -0.04em;

		color: #ffffff;
	}

	p {
		position: absolute;
		width: 588px;
		height: 75px;
		left: 202px;
		top: 460px;

		font-family: 'Montserrat', sans-serif;
		font-style: normal;
		font-weight: 700;
		font-size: 22px;
		line-height: 103%;
		color: #ffffff;
	}

	button {
		position: absolute;
		background: #ffffff;
		border-radius: 10px;
		width: 226px;
		height: 60px;
		outline: none;
		border: none;
		left: 202px;
		top: 580px;

		font-family: "Montserrat";
		font-style: normal;
		font-weight: 800;
		font-size: 20px;
		line-height: 22px;

		color: #6d0062;
	}
`

export const BodyDivLeft = styled.div`
	position: absolute;
	width: 450px;
	height: 400px;
	left: 125px;
	padding-left: 100px;
	background: linear-gradient(73.6deg, rgba(57, 26, 96, 0.97) 4.94%, rgba(109, 0, 98, 0.97) 71.35%);
	border-radius: 76px;

	h3 {
		font-family: 'Montserrat', sans-serif;
		font-style: normal;
		font-weight: 700;
		font-size: 64px;
		line-height: 107.5%;
		letter-spacing: -0.04em;

		color: #ffffff;
	}

	p {
		position: absolute;
		margin-top: -35px;
		font-family: 'Montserrat', sans-serif;
		font-style: normal;
		font-weight: 400;
		font-size: 24px;
		line-height: 29px;
		color: #ffffff;
	}
`
export const BodyDivRight = styled.div`
	position: absolute;
	left: 780px;
	top: 160px;

	h3 {
		font-family: 'Montserrat', sans-serif;
		font-style: normal;
		font-weight: 700;
		font-size: 24px;
		line-height: 103%;
		color: #6d0062;
	}

	p {
		font-family: "Montserrat";
		font-style: normal;
		font-weight: 700;
		font-size: 16px;
		line-height: 20px;
	}
`

export const AboutBody2 = styled.div`
	position: absolute;
	width: 698px;
	height: 255px;
	left: 125px;
	top: 1415px;

	p {
		font-family: "Montserrat";
		font-style: normal;
		font-weight: 700;
		font-size: 16px;
		line-height: 20px;

		color: #000000;
		font-size: 18px;
	}

	span {
		color: #6d0062;
	}
`

export const AboutBody3 = styled.div`
	position: absolute;
	width: 100%;
	height: 400px;
	top: 1715px;

	h3 {
		position: relative;
		left: 95px;
		font-family: 'Montserrat', sans-serif;
		font-style: normal;
		font-weight: 700;
		font-size: 64px;
		line-height: 107.5%;
		letter-spacing: -0.04em;

		color: #6d0062;
	}

	p {
		position: relative;
		margin-top: -50px;
		font-family: "Montserrat";
		left: 95px;
		font-style: normal;
		font-weight: 700;
		font-size: 16px;
		line-height: 20px;

		color: #000000;
	}
`

export const AboutBodyImgCon = styled.div`
	position: absolute;
	width: 100%;
	height: 400px;
	display: grid;
	grid-template-columns: 30% 30% 30%;
	gap: 10%;
	margin-top: 30px;

	h3 {
		position: absolute;
		top: 430px;
		left: 35px;
		font-family: 'Montserrat', sans-serif;
		font-style: normal;
		font-weight: 700;
		font-size: 24px;
		line-height: 103%;
		color: #000000;
	}

	p {
		top: 560px;
		left: 35px;
		font-family: "Montserrat";
		font-style: normal;
		font-weight: 700;
		font-size: 12px;
		line-height: 12px;
		letter-spacing: 0.025em;
		text-transform: capitalize;
		color: #6d0062;
	}
`

export const ApaingoloDiv = styled.div`
	position: relative;
	width: 345px;
	height: 442px;
	left: 95px;
	top: 0px;
	background-image: url(${ebi});
	border-radius: 76px;
`

export const AmaegberiDiv = styled.div`
	position: absolute;
	width: 345px;
	height: 442px;
	left: 515px;
	top: 0px;
	background-image: url(${amas});
	border-radius: 76px;
`

export const AndersonDiv = styled.div`
	position: absolute;
	left: 930px;
	width: 345px;
	height: 442px;
	top: 0px;
	background: linear-gradient(73.6deg, rgba(57, 26, 96, 0.97) 4.94%, rgba(109, 0, 98, 0.97) 71.35%);
	border-radius: 76px;

	h3 {
		top: 455px;
	}
`

export const AboutletterContainer = styled.div`
	position: absolute;
	width: 100%;
	height: 180px;
	top: 2530px;
	padding-bottom: 70px;
	display: flex;
	flex-direction: column;
	align-items: center;
`
export const AboutLetterHead = styled.h3`
	position: relative;
	width: 649px;
	height: 51px;
	/* left: 99px; */
	span {
		color: rgba(109, 0, 98, 0.97);
	}
	font-family: 'Montserrat', sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 48px;
	line-height: 106.5%;
	text-align: center;
	letter-spacing: -0.02em;

	color: #000000;
`

export const AboutLetterCTA = styled.div`
	position: relative;
	display: flex;
	gap: 20px;
	top: -15px;
	/* left: 99px; */
	input {
		box-sizing: border-box;

		width: 314px;
		height: 42px;
		padding: 10px;

		border: 2px solid #d9d9d9;
		border-radius: 15px;

		font-family: "Montserrat";
		font-style: normal;
		font-weight: 700;
		font-size: 12px;
		line-height: 12px;
		letter-spacing: 0.025em;
		text-transform: capitalize;

		color: #a7a7a7;
	}
	button {
		top: 0;
		left: 1.5rem;
		height: 42px;
		width: 168px;
		border: none;
		outline: none;
		background: linear-gradient(73.6deg, rgba(57, 26, 96, 0.97) 4.94%, rgba(109, 0, 98, 0.97) 71.35%);
		border-radius: 10px;
		font-family: "Montserrat";
		font-style: normal;
		font-weight: 700;
		font-size: 12px;
		line-height: 12px;
		letter-spacing: 0.025em;
		text-transform: capitalize;

		color: #ffffff;
	}
`
