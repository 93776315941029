import styled from "styled-components"
import Colors from "utils/colors"
import media from "utils/media"

export const GeneralTypeSelect = styled.div`
	position: relative;
	width: 100%;
	background-color: ${Colors.white};
	height: 35px;
	border: 1px solid #b5b3b3;
	/* box-shadow: 0px 3px 3px 4px rgba(0, 0, 0, 0.25); */
	border-radius: 15px;
	padding-left: 15px;
	padding-right: 15px;
	margin-top: 0.2rem;
	outline: none;
	:active {
		background-color: ${Colors.white};
	}
	:valid {
		background-color: ${Colors.white};
	}
	border-radius: 15px;
	${media.tablet`
	width:100%;
  `}
	${media.mobile`
   width:100%;
   padding: 0px 15px;
   margin-left: 0px;
   height: 45px;
  `}
 


  select {
		font-size: 14px;
		height: 100%;
		width: 100%;
		/* padding: 0em 1em 0em 1.5em; */
		background: transparent;
		border: none;
		outline: none;
		border-radius: 15px;
		appearance: none;
		:active {
			background-color: ${Colors.white};
			color: ${Colors.muted};
		}
		:valid {
			background-color: ${Colors.white};
		}
		:disabled {
			color: ${Colors.muted};
		}
		.active-option {
			color: ${Colors.black};
			background: transparent;
			border: none;
			outline: none;
		}
		.inactive-option {
			color: ${Colors.muted};
			background: transparent;
			border: none;
			outline: none;
		}
	}
	div {
		position: absolute;
		display: flex;
		right: 2%;
		top: 0%;
		color: white;
		background: #ffffff;
		border: none;
		height: 100%;
		width: 2em;
		pointer-events: none;
		border-radius: 15px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
`

export const ContactTextInputContainer = styled.div`
	width: ${({ width }) => (width ? width : "100%")};
	display: flex;
	flex-direction: column;
	margin-top: ${({ mt }) => (mt ? mt : "1.2rem")};
	margin-bottom: ${({ mt }) => (mt ? mt : "-10px")};
	label {
		color: ${Colors.black};
	}
`
