import React, { useEffect } from 'react'
import classes from './SingleNewsBlog.module.css'
import userIcon from 'assets/icons/userfe.svg'
import Skeleton from 'react-loading-skeleton'
import BlogSlider from './BlogSlider'
import { FacebookIcon, InstagramIcon, TwitterIcon } from 'utils/assets'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'
import { BASE_URL } from 'services'
import http from 'services/httpService'
import SkeletonLoader from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { formatDate } from 'utils'
import { BlogContainer } from './style'
import Newsletter from 'components/Newsletter'

const SingleNewsBlog = () => {
  const params = useParams()

  const urlSingle = `${BASE_URL}/blog/single/${params?.title}`
  const single_fetcher = async () => {
    const res = await http.get(urlSingle)
    return res?.data?.data
  }

  const random_url = `${BASE_URL}/blog/random-posts`
  const random_fetcher = async () => {
    const res = await http.get(random_url)
    return res?.data?.data
  }

  const { data: randomPosts } = useSWR(random_url, random_fetcher)
  const { data: singPost } = useSWR(urlSingle, single_fetcher)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <BlogContainer>
        <main className={classes.SingleNewsBlogMain}>
          <aside className={classes.socialIconContainer}>
            <div className={classes.social}>
              <div className={classes.socialIcons}>
                <a
                  href="/www.twitter.com/byshelb"
                  className={[classes.twitter, classes.icons].join(' ')}
                >
                  <img src={TwitterIcon} alt="Twitter" />
                </a>
                <a
                  href="/www.facebook.com/byshelb"
                  className={[classes.facebook, classes.icons].join(' ')}
                >
                  <img src={FacebookIcon} alt="Facebook" />
                </a>
                <a
                  href="/www.instagram.com/byshelb"
                  className={[classes.instagram, classes.icons].join(' ')}
                >
                  <img src={InstagramIcon} alt="Instagram" />
                </a>
              </div>
            </div>
          </aside>
          <h1 className={classes.mainHeader}>
            {singPost?.title || (
              <Skeleton
                baseColor="#7e7a75"
                highlightColor="#606660"
                count={1}
                height={10}
              />
            )}{' '}
          </h1>
          <h3 className={classes.subHeader}>
            {' '}
            {singPost?.excerpt || (
              <Skeleton
                baseColor="#7e7a75"
                highlightColor="#606660"
                count={2}
                height={10}
              />
            )}{' '}
          </h3>
          {/* {/* blogger info */}
          <div className={classes.blogger}>
            <div className={classes.bloggerImage}>
              {singPost?.title ? (
                <img src={userIcon} alt={singPost?.title} />
                
              ) : (
                <Skeleton
                  baseColor="#7e7a75"
                  circle
                  highlightColor="#606660"
                  count={1}
                  height={30}
                  width={30}
                />
              )}
            </div>
            <div className={classes.bloggerInfo}>
              {!singPost?.author ? (
                
                <Skeleton
                  baseColor="#7e7a75"
                  highlightColor="#606660"
                  count={1}
                  height={5}
                  width={'150px'}
                />
              ) : (
                <p>
                  {singPost?.author?.first_name}{singPost?.author?.surname}
                </p>
              )}
              {!singPost?.title ? (
                <Skeleton
                  baseColor="#7e7a75"
                  highlightColor="#606660"
                  count={1}
                  height={5}
                  width={'150px'}
                />
              ) : (
                <p>
                  {singPost?.createdAt
                    ? formatDate(new Date(singPost?.createdAt))
                    : ''}
                </p>
              )}
              {!singPost?.readTime ? (
                <Skeleton
                  baseColor="#7e7a75"
                  highlightColor="#606660"
                  count={1}
                  height={5}
                  width={'100px'}
                />
              ) : (
                <p>{singPost?.readTime} mins Read</p>
              )}
            </div>
          </div>
          {/* -main- blog-info-secton  */}
          <section className={classes.mainBlog}>
            <div className={classes.mainBlogImageContainer}>
              {singPost?.image?.url ? (
                <img
                  className={classes.mainBlogImage}
                  src={singPost?.image?.url}
                  alt={'blogPost.title'}
                />
              ) : (
                <Skeleton
                  baseColor="#7e7a75"
                  highlightColor="#606660"
                  count={1}
                  height={300}
                />
              )}
            </div>

            <div className={classes.mainBlogContent}>
              <div dangerouslySetInnerHTML={{ __html: singPost?.slug }} />
            </div>
          </section>
        </main>
        <section className={classes.articles}>
          <h1 className={classes.header}>Related Articles</h1>
          {!randomPosts ? <SkeletonLoader /> : <BlogSlider blog={randomPosts} />}
        </section>
        <Newsletter />
    </BlogContainer>
  )
}

export default SingleNewsBlog
