import React, { useState } from 'react'
import { useKeenSlider } from 'keen-slider/react'
import 'keen-slider/keen-slider.min.css'
import Skeleton from 'react-loading-skeleton'
import { ArticlesDiv, NewsCard, NewsCardCont } from './style'
import { Link } from 'react-router-dom'

function SkeletonLoaderPost() {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [loaded, setLoaded] = useState(false)
  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 0,
    breakpoints: {
      '(min-width: 400px)': {
        slides: { perView: 1.2, spacing: 10 },
      },
      '(min-width: 760px)': {
        slides: { perView: 2, spacing: 10 },
      },
      '(min-width: 1000px)': {
        slides: { perView: 3, spacing: 10 },
      },
    },
    slides: { perView: 1.1, spacing: 15 },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel)
    },
    created() {
      setLoaded(true)
    },
  })

  return (
    <ArticlesDiv>
      <div className="navigation-wrapper">
        <div ref={sliderRef} className="keen-slider">
          {[...new Array(5)].map((item, idx) => (
            <div
              key={`${idx}hghshhjshja`}
              className="keen-slider__slide slide_bg"
            >
              <NewsCard>
                <Link
                  to={`/blog/posts/Recently Katerina Gousia, Anna Baranowska-Rataj/877-9-797{blog._id}`}
                >
                  <NewsCardCont>
                    <Skeleton
                      baseColor="#7e7a75"
                      highlightColor="#606660"
                      count={1}
                      height={200}
                    />

                    <h1>
                      {' '}
                      <Skeleton
                        baseColor="#7e7a75"
                        highlightColor="#606660"
                        count={2}
                        height={10}
                      />
                    </h1>
                  </NewsCardCont>
                </Link>
              </NewsCard>
            </div>
          ))}
        </div>
      </div>
      {loaded && instanceRef.current && (
        <div className="dots">
          {[
            ...Array(instanceRef.current.track.details.slides.length).keys(),
          ].map((idx) => {
            return (
              <button
                key={idx}
                onClick={() => {
                  instanceRef.current?.moveToIdx(idx)
                }}
                className={'dot' + (currentSlide === idx ? ' active' : '')}
              ></button>
            )
          })}
        </div>
      )}
    </ArticlesDiv>
  )
}

export default SkeletonLoaderPost
