import { createContext, useState, } from "react";

export const loanSteps = ["","details","surety_1","surety_2","upload_docs"]


export const EditLoanStepContext = createContext({
    step: 0,
    next:()=>{},
    prev:()=>{},
    goToStep:(newStep)=>console.log(newStep),
})


export default function EditLoanStepProvider({children}){
    const [step,setStep] = useState(0)




    const goToStep = (newStep) => {
        setStep(newStep)
    }

    return (
        <EditLoanStepContext.Provider value={{step,goToStep}}>
            {children}
        </EditLoanStepContext.Provider>
    )
}