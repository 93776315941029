import React, { useEffect } from "react"
import classes from "./NewsBlog.module.css"
import { BlogContainer, FeaturedNews, Hero, HeroText, NewsBlogMain } from "./style"
import BlogSlider from "./BlogSlider"
import PostSlider from "./PostSlider"
import { FacebookIcon, InstagramIcon, TwitterIcon } from "utils/assets"
import useSWR from "swr"
import { BASE_URL } from "services"
import http from "services/httpService"
import SkeletonLoader from "./SkeletonLoader"
import SkeletonLoaderPost from "./SkeletonLoaderPost"
// import axios from "axios"
// import { blogApi } from "../../utils/api"
// import { CircleSpinner } from "react-spinners-kit"
// import { categoryBtn } from "./../../utils/api"

const NewsBlog = () => {
	const url = `${BASE_URL}/blog`
	const fetcher = async () => {
		const res = await http.get(url)
		return res?.data?.data
	}
	const random_url = `${BASE_URL}/blog/random-posts`
	const random_fetcher = async () => {
		const res = await http.get(random_url)
		return res?.data?.data
	}

	const { data: blogPosts } = useSWR(url, fetcher)
	const { data: randomPosts } = useSWR(random_url, random_fetcher)

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	const allblog = blogPosts ? blogPosts : []

	return (
		<>
			{/* <Meta title="News and Blog" /> */}
			<BlogContainer>
				<Hero>
					<HeroText>News &amp; Blog</HeroText>
				</Hero>
				<NewsBlogMain>
					<FeaturedNews>
						{/* social icons */}
						<aside className={classes.socialIconContainer}>
							<div className={classes.social}>
								<div className={classes.socialIcons}>
									<a href="/" className={[classes.twitter, classes.icons].join(" ")}>
										<img src={TwitterIcon} alt="Twitter" />
									</a>
									<a href="/" className={[classes.facebook, classes.icons].join(" ")}>
										<img src={FacebookIcon} alt="Facebook" />
									</a>
									<a href="/" className={[classes.instagram, classes.icons].join(" ")}>
										<img src={InstagramIcon} alt="Intagram" />
									</a>
								</div>
							</div>
						</aside>
						<h1 className="news">Featured News</h1>
						{blogPosts && allblog.length <= 0 && <h2>No posts yet</h2>}
						{!blogPosts ? <SkeletonLoaderPost /> : <PostSlider blog={allblog} />}
					</FeaturedNews>

					<section className={classes.articles}>
						<h1 className={classes.header}>Recent Articles</h1>
						{!randomPosts ? <SkeletonLoader /> : <BlogSlider blog={randomPosts} />}
					</section>
				</NewsBlogMain>
			</BlogContainer>
		</>
	)
}

export default NewsBlog
