import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Toaster } from 'react-hot-toast'
import "bootstrap-icons/font/bootstrap-icons.css"
import HomePage from 'pages'
import SignupPage from 'pages/signup'
import LoginPage from 'pages/Login'
import FaqPage from 'pages/Faq'

import {
  ABOUT_ROUTE,
  BLOG_ROUTE,
  CONTACT_ROUTE,
  DASHBOARD_DOWNLOADROUTE,
  DASHBOARD_NEXTOFKINROUTE,
  DASHBOARD_ROUTE,
  FAQ_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  HOME_ROUTE,
  LOGIN_ROUTE,
  RESET_PASSWORD_ROUTE,
  SIGNUP_ROUTE,
} from 'routes'

import GlobalStyles from 'globalStyles'
import ContactPage from 'pages/contact'
import AboutPage from 'pages/About'
import BlogPage from 'pages/Blog'
import DashboardPage from 'pages/dashboard'
import AuthNav from 'components/AuthNav'
import ProfilePage from 'pages/dashboard/Profile'
import ApplyLoanPage from 'pages/dashboard/apply'
import SettingsPage from 'pages/dashboard/settings'
import PaymentPage from 'pages/dashboard/payment'
import LogoutPage from 'pages/dashboard/Logout'
import ResetPassPage from 'components/Auth/ForgetPassword'
import ResetPassword from 'components/Auth/ForgetPassword/ResetPassword'
import UnderGraduateLoan from 'components/Dashboard/Apply/UndergraduateLoan'
import ProfessionalLoanPage from 'components/Dashboard/Apply/ProfessionalLoan'
import LoanDetailsComp from 'components/Dashboard/Apply/LoanDetails'
import Error404Page from 'pages/ErrorPage'
import SingleNewsBlogPage from 'pages/SingleNewsBlogPage'
import DownloadForm from 'pages/dashboard/downloadform'
import NOKPage from 'pages/dashboard/NextOfKinPage'
import UndergraduateLoanStepProvider from 'context/LoanStepContext'
import UndergraduateApplication from 'components/Dashboard/Apply/UndergraduateLoan/UndergraduateApplication'
import EditLoanStepProvider from 'context/EditLoanContext'
import EditLoan from 'components/Dashboard/EditLoan'
import { PostGraduateApplication } from 'components/Dashboard/Apply/PostGraduateApplication/ApplicationForm'
import PostGraduateLoan from 'components/Dashboard/Apply/PostGraduateApplication/index.jsx'
import OnboardingPage from 'pages/dashboard/Onboarding'

function App() {
  return (
    <main className="bg-gray-100">
      <Toaster />
      <GlobalStyles />
      <BrowserRouter>
        <Routes>
          <Route path={HOME_ROUTE} element={<HomePage />} />
          {/* <Route path={SIGNUP_ROUTE} element={<Portal />} /> */}
          <Route path={SIGNUP_ROUTE} element={<SignupPage />} />
          {/* <Route path={LOGIN_ROUTE} element={<Portal />} /> */}
          <Route path={LOGIN_ROUTE} element={<LoginPage />} />
          <Route path={ABOUT_ROUTE} element={<AboutPage />} />
          <Route path={FAQ_ROUTE} element={<FaqPage />} />
          <Route path={CONTACT_ROUTE} element={<ContactPage />} />
          <Route path={BLOG_ROUTE} element={<BlogPage />} />
          <Route
            path={`${BLOG_ROUTE}/posts/:title/:id`}
            element={<SingleNewsBlogPage />}
          />
          <Route path={FORGOT_PASSWORD_ROUTE} element={<ResetPassPage />} />
          <Route path={RESET_PASSWORD_ROUTE} element={<ResetPassword />} />

          <Route path={DASHBOARD_ROUTE} element={<AuthNav />}>
            <Route index element={<DashboardPage />} />
            <Route path="profile" element={<ProfilePage />} />
            <Route path="onboarding" element={<OnboardingPage />} />
            <Route path="onboarding/payment-details" element={<OnboardingPage />} />
            <Route path={DASHBOARD_NEXTOFKINROUTE} element={<NOKPage />} />
            <Route path={DASHBOARD_DOWNLOADROUTE} element={<DownloadForm />} />
            <Route path="apply" element={<ApplyLoanPage />} />
            <Route path="apply/details/:id" element={<LoanDetailsComp />} />
            <Route path="apply/undergraduate" element={<UndergraduateApplication/>}/>
            <Route
                path="apply/undergraduate/:id"
                element={
                <UndergraduateLoanStepProvider>
                  <UnderGraduateLoan />
                </UndergraduateLoanStepProvider>
                }
            />
            <Route
                path="edit/:id"
                element={
                <EditLoanStepProvider>
                  <EditLoan/>
                </EditLoanStepProvider>
                }
            />
            <Route path="apply/postgraduate" element={<PostGraduateApplication/>}/>
            <Route path="apply/postgraduate/:id" element={<PostGraduateLoan />}/>
            {/* <Route path="apply/postgraduate" element={<PostGraduateLoan />}/> */}
            <Route path="apply/professional" element={<ProfessionalLoanPage />}>
              <Route path=":id" element={<ProfessionalLoanPage />} />
            </Route>
            <Route path="payment-details" element={<PaymentPage />} />
            <Route path="settings" element={<SettingsPage />} />
            <Route path="logout" element={<LogoutPage />} />
          </Route>
          <Route path="*" element={<Error404Page />} />
        </Routes>
      </BrowserRouter>
    </main>
  )
}

export default App
