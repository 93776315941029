import React from "react"
// import LoanTable from "components/table"
import { AllApplications } from "../Home/OngoingApplications"

function ApplyForLoan() {
	return (
		<div className="p-4 py-8">
			<AllApplications/>
		</div>
	)		
}

export default ApplyForLoan
