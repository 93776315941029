import styled from "styled-components"
import Colors from "utils/colors"
import media from "utils/media"

export const SettingsContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`
export const Settingsheader = styled.h1`
	font-family: 'Montserrat', sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 48px;
	line-height: 49.5%;
	letter-spacing: -0.02em;
	color: #391a60;
	margin-top: 3rem;
	${media.mobile`
	font-size: 38px;
	`}
`

export const SettingTabContainer = styled.div`
	margin-top: 40px;
	margin-bottom: 20px;
	display: flex;
`
export const ToggleTabBtn = styled.button`
	width: 150px;
	height: 40px;
	margin-left: ${({ ml }) => (ml ? ml : null)};
`
export const FormSettings = styled.form`
	width: 40%;
	${media.smallDesktopMinimum`
	width: 50%;
	`}
	${media.tablet`
	width: 80%;
	`}
	${media.mobile`
	width: 90%;
	`}
`
export const ConnectBvnDiv = styled.form`
	width: 50%;
	${media.smallDesktopMinimum`
	width: 60%;
	`}
	${media.tablet`
	width: 80%;
	`}
	${media.mobile`
	width: 90%;
	`}
`
export const FormSettingsSubmit = styled.button`
	width: 120px;
	height: 35px;
	border: none;
	outline: none;
	border-radius: 10px;
	background: ${Colors.gradient};
	color: ${Colors.white};
	padding: 5px 20px;
	margin-top: 20px;
	cursor: pointer;
`
