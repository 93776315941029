import React from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import { observer } from "mobx-react-lite"
import TextInput from "components/TextInput"
import { FormContainer, SubmitBtn, TextArea, TextAreaErrMsg, TextAreaLabel } from "./style"
import Colors from "utils/colors"
import { sendContactMessage } from "services/MiscService"
import authStore from "mobx/AuthStore"

function ContactForm() {
	const formik = useFormik({
		enableReinitialize: false,
		initialValues: {
			fullname: "",
			phone: "",
			message: "",
			email: ""
		},
		onSubmit: async (data, helpers) => {
			const response = await sendContactMessage(data)
			if (!response.error) {
				helpers.resetForm()
			}
		},
		validationSchema: Yup.object({
			fullname: Yup.string().min(5).max(60).required("Full Namre is required.").label("Full Name"),
			phone: Yup.string().min(11).max(11).required().label("Phone Number"),
			email: Yup.string().max(255).email().required().label("Email"),
			message: Yup.string().required().label("Message")
		})
	})

	return (
		<FormContainer onSubmit={formik.handleSubmit}>
			<TextInput
				label="Full Name"
				labelColor={Colors.white}
				placeholder="Enter your name"
				name="fullname"
				id="fullname"
				disabled={authStore.isSending}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
				value={formik.values.fullname}
				error={formik.errors.fullname}
				visible={formik.touched.fullname}
			/>
			<TextInput
				label="Phone Number"
				labelColor={Colors.white}
				placeholder="Enter your phone number"
				name="phone"
				id="phone"
				type="tel"
				disabled={authStore.isSending}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
				value={formik.values.phone}
				error={formik.errors.phone}
				visible={formik.touched.phone}
			/>
			<TextInput
				label="Email"
				labelColor={Colors.white}
				placeholder="Enter your email"
				name="email"
				id="email"
				type="email"
				disabled={authStore.isSending}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
				value={formik.values.email}
				error={formik.errors.email}
				visible={formik.touched.email}
			/>
			<TextAreaLabel> Message</TextAreaLabel>
			<TextArea
				name="message"
				disabled={authStore.isSending}
				id="message"
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
				value={formik.values.message}
				placeholder="Enter your message here..."
			/>
			{formik.touched.message && formik.errors.message && <TextAreaErrMsg>You have to enter a message</TextAreaErrMsg>}

			<SubmitBtn type="submit" disabled={authStore.isSending}>
				{authStore.isSending ? "Sending..." : "Send Message"}
			</SubmitBtn>
		</FormContainer>
	)
}

export default observer(ContactForm)
