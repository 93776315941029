const lgaData = [
	{
		id: "riurfghj",
		label: "Brass",
		value: "brass"
	},
	{
		id: "jhjjkjk",
		label: "Ekeremor",
		value: "ekeremor"
	},
	{
		id: "dfgghgg",
		label: "Kolokuma/Opokuma",
		value: "kolokuma/opokuma"
	},
	{
		id: "dsghsfg",
		label: "Nembe",
		value: "nembe"
	},
	{
		id: "fddgd",
		label: "Ogbia",
		value: "ogbia"
	},
	{
		id: "rissdsur",
		label: "Sagbama",
		value: "sagbama"
	},
	{
		id: "riursdd",
		label: "Southern Ijaw",
		value: "southern Ijaw"
	},
	{
		id: "riursddsa",
		label: "Yenagoa",
		value: "yenagoa"
	}
]

export default lgaData
