import styled from 'styled-components'
import media from 'utils/media'

export const LoanDetailsHead = styled.h3`
  font-weight: 900;
  font-size: 24px;
  line-height: 20px;
  color: #920583;
`
export const ProTabCon = styled.div`
  display: flex;

  width: calc(100% - 200px);

  margin-left: 20px;
  margin-top: 1.5%;

  ${media.mobile`
  
  flex-direction: column;
  width: 100%;
  margin-left: 0px;
  
  `}
`
export const ProTabDiv = styled.div`
  display: flex;
  width: 30%;
  flex-direction: column;
  ${media.mobile`
  flex-direction: row;
  width: 100%;
  height: 45px;
  margin-top: 30px;
  margin-bottom: 0;
  overflow-x: scroll;
  `}
  .tabs {
    padding: 15px;
    text-align: left;
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    background: rgba(128, 128, 128, 0.075);
    cursor: pointer;
    border-bottom: 1px solid rgba(0, 0, 0, 0.274);
    border-right: 1px solid rgba(0, 0, 0, 0.274);
    /* box-sizing: content-box; */
    position: relative;
    outline: none;

    ${media.mobile`
    width: 200px;
    `}
   
  }
  .tabs:not(:last-child) {
    border-right: 1px solid rgba(0, 0, 0, 0.274);
  }

  .active-tabs {
    background: white;
    border-bottom: 1px solid transparent;
  }

  .active-tabs::before {
    content: '';
    display: block;
    position: absolute;
    bottom: -2px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% + 2px);
    height: 5px;
    background: rgb(88, 147, 241);
  }

  button {
    border: none;
  }
`
// export const ProtabBtn = styled.button`
// background-color: red;
// `

export const ProTabBtnDiv = styled.div`
  display: flex;

  img {
    width: 20px;
    ${media.mobile`
    display: none;
    `}
  }
`
export const ProTabContent = styled.div`
  flex-grow: 1;
  width: 70%;
  ${media.mobile`
  width: 100%;
  `}
  .content {
    background: white;
    margin-left: 70px;
    padding: 20px;
    width: 100%;
    height: 100%;
    display: none;

    ${media.mobile`
    margin-left: 0px;
   
    
    `}
  }
  .content h2 {
    padding: 0px 0 5px 0px;
  }
  /* .content hr {
    width: 100px;
    height: 2px;
    background: #222;
    margin-bottom: 5px;
  } */
  /* .content p {
    width: 100%;
    height: 100%;
  } */
  .active-content {
    display: block;
  }
`

export const StpContent = styled.div`
  display: flex;
  width: 100%;
  /* gap: 10px; */
  justify-content: space-between;
  align-items: center;
  background: rgba(128, 128, 128, 0.075);
  border-radius: 10px;
  padding: 10px;

  img {
    width: 18%;
    ${media.mobile`
    
  width: 30%;
  margin-bottom: 20px;
  `}
  }

  ${media.mobile`
  flex-direction: column;
  background: white;

  `}
`
export const StpConRight = styled.div`
  width: 80%;
  ${media.mobile`
  width: 100%;
  `}
`
export const ConFirst = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;

  ${media.mobile`
 flex-direction: column;

  `}
  div {
    width: 35%;

    ${media.mobile`
  width: 100%;
  margin-bottom: 10px;
  gap: 0px;
  `}
  }
`
export const ConSec = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  div {
    width: 100%;
    display: flex;
    gap: 10px;
    ${media.mobile`
flex-direction: column;
gap: 0px;
  `}
  }
`
export const StpContentDiv = styled.div`
  width: 70%;
  display: flex;
  justify-content: space-between;
  ${media.mobile`
 flex-direction: column;
 width: 100%;
 padding: 0px 10px;
  `}
`
export const StpLeft = styled.div`
  width: 70%;
  ${media.mobile`
  width: 100%;
div{
  margin-top: 10px;
}
  `}
`
export const StpRight = styled.div`
  width: 30%;
  ${media.mobile`
  width: 100%;
  div{
  margin-top: 10px;
}
  `}
`

//Institution block Designs

export const InsConDiv = styled.div`
  width: 100%;
  display: flex;
  ${media.mobile`
  flex-direction: column;
  `}
`
export const InsLeft = styled.div`
  width: 50%;
  ${media.mobile`
  width: 100%;
  div{
    margin-top: 10px;
  }
  `}
`
export const InsRight = styled.div`
  width: 50%;
  ${media.mobile`
  width: 100%;
  div{
    margin-top: 10px;
  }
  `}
`

// Next of kin block design

export const NokConDiv = styled.div`
  width: 100%;
  display: flex;
  ${media.mobile`
  flex-direction: column;
  `}
`
export const NokLeft = styled.div`
  width: 50%;
  ${media.mobile`
  width: 100%;
  div{
    margin-top: 10px;
  }
  `}
`
export const NokRight = styled.div`
  width: 50%;
  ${media.mobile`
  width: 100%;
  div{
    margin-top: 10px;
  }
  `}
`

// Files block design
export const FileBloc = styled.div``
export const LoanDoc = styled.div`
  display: flex;
`
export const DocHeader = styled.h3`
  font-weight: 900;
  font-size: 16px;
  line-height: 20px;
  ${media.mobile`
  text-align: center;
  margin-bottom: 10px;
  `}/* color: #920583; */
`
export const FileCardCon = styled.div`
  width: 180px;
  height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #4ab6ff;
  border-radius: 10px;
  font-size: 14px;

  img {
    width: 70px;
    height: 50px;
    padding-top: 10px;
    cursor: pointer;
  }

  ${media.mobile`
  width: 250px;
  height: 200px;
  `}
`
export const PdfCardCon = styled.div`
  width: 180px;
  height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid #4ab6ff;
  border-radius: 10px;
  font-size: 14px;

  ${media.mobile`
  width: 250px;
  height: 200px;
  `}
`

export const CardFlex = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 20px;

  ${media.mobile`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px; 
  `}
`

export const LoanDocDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
// Account Block design

export const AccDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${media.mobile`
  div{
    margin-top: 10px;
  }
  `}
`

//Bvn Block design

export const BvnConDiv = styled.div`
  width: 100%;
  display: flex;
`
export const BvnLeft = styled.div`
  width: 50%;
`
export const BvnRight = styled.div`
  width: 50%;
`

// Call to action buttons

export const ProtabCta = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
`

//Modal Styles...

export const ModalDiv = styled.div`
  background-color: red;
  body.active-modal {
    overflow-y: hidden;
  }

  .btn-modal {
    padding: 10px 20px;
    display: block;
    margin: 100px auto 0;
    font-size: 18px;
  }

  .modal,
  .overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
  }

  .overlay {
    background: rgba(49, 49, 49, 0.8);
  }
  .modal-content {
    z-index: 700;
    position: fixed;
    top: 10%;
    left: 30%;
    /* transform: translate(-50%, -50%); */
    line-height: 1.4;
    background: white;
    /* background: #f1f1f1; */
    padding: 14px 28px;
    border-radius: 3px;
    max-width: 600px;
    min-width: 600px;
    height: 400px;
  }

  .close-modal {
    position: absolute;
    background: red;
    color: white;
    cursor: pointer;
    border-radius: 3px;
    top: 10px;
    right: 10px;
    padding: 5px 7px;
  }
`

export const CtaMemo = styled.textarea`
  width: 100%;
  height: 200px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  font-size: 14px;
  resize: none;
`
export const ProMemoBtn = styled.button`
  width: 150px;
  height: 35px;
  background: rgb(88, 147, 241);
  color: white;
  border-radius: 4px;
  cursor: pointer;
`

//PDF screen styles...
export const PdfDivScreen = styled.div`
  width: 100%;
  height: 100vh;
  margin-top: 100px;
  position: fixed;
  /* body.active-screen {
    overflow-y: hidden;
} */

  .btn-screen {
    padding: 10px 20px;
    display: block;
    margin: 100px auto 0;
    /* font-size: 18px; */
  }

  .screen,
  .overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    /* left: 0; */
    /* right: 0; */
    /* bottom: 0; */
    position: fixed;
  }

  .overlay {
    background: red;
    width: 600px;
    height: 600px;

    /* background: rgba(49,49,49,0.8); */
  }
  .screen-content {
    width: calc(100% - 56px);
    min-height: 100vh;

    transform: translate(0, 0);
    line-height: 1.4;
    background: #f1f1f1;
    padding: 14px 28px;
    display: flex;
    /* border-radius: 3px; */
    /* max-width: 600px; */
    /* min-width: 300px; */
    /* width: 100%;
  display: flex;
  height: 100%;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: green;
    padding: 14px 28px;
    margin: 0 auto;
    border-radius: 3px; */
  }

  .close-screen {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 7px;
  }
`
export const CtaBtn = styled.button`
  /* display: flex; */
  /* align-items: center; */
  background: ${(props) => (props.bg ? 'red' : 'green')};
  width: 140px;
  height: 35px;
  border-radius: 10px;
  cursor: pointer;
  font-family: 'Tahoma';
  font-size: 12px;
  line-height: 15px;

  letter-spacing: 0.025em;
  text-transform: capitalize;

  color: #ffffff;
`
