import { useContext } from "react";
import { Step, StepHeaderContainer } from "../styled";
import { loanSteps, EditLoanStepContext } from "context/EditLoanContext";


export default function LoanStepsHeader(){
    const {step:currentStep,goToStep} = useContext(EditLoanStepContext)
    return (
        <StepHeaderContainer className="sticky top-0 py-6 bg-white z-[100]">
            {loanSteps.map((step,i) => (
                <Step onClick={()=>goToStep(i)} active={currentStep === i} key={step}>{i + 1}</Step>
            ))}
        </StepHeaderContainer>
    )
}