import React from "react"
import { MobileMenuBtn, MobileNavLink } from "./style"

function NavButtonMobile({ onClick, title, mt, to, ...rest }) {
	return (
		<MobileNavLink to={to}>
			<MobileMenuBtn {...rest} mt={mt} onClick={onClick}>
				{title}
			</MobileMenuBtn>
		</MobileNavLink>
	)
}

export default NavButtonMobile
