import styled from 'styled-components'
import blogImage from 'assets/images/news_reading.jpg'
import media from 'utils/media'

export const BlogPageContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  `
export const BlogContainer = styled.div`
  width: 100%;
  flex-direction: column;
  display: flex;
`
export const Hero = styled.div`
  width: 100%;
  margin-top: -75px;
  background: url(${blogImage});
  background-position: center;
  background-size: cover;
  background-color: #cbabcd;
  background-blend-mode: overlay;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  height: 25rem;
  align-items: center;
  line-height: 30rem;
  ${media.mobile`
	height: 20rem;
	margin-top: 0px;
	`}
`

export const HeroText = styled.h1`
  text-align: center;
  color: #fff;
  font-size: 5rem;
  ${media.mobile`
	font-size: 2.5rem;
	`}
`
export const NewsBlogMain = styled.section`
  a h1 {
    text-decoration: underline;
  }
  a h2 {
    color: #000;
  }

  .news,
  .articles .header {
    text-align: center;
    -webkit-text-stroke-width: 1.2px;
    font-size: clamp(1.5rem, 2vw, 2rem);
    margin: 1.5rem 0;
    font-weight: 900;
    -webkit-text-fill-color: white;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #694a98;
    text-transform: uppercase;
  }
`
export const FeaturedNews = styled.section`
  margin-bottom: 2rem;
`
export const NewsCard = styled.div`
  width: 30rem;
  height: 20rem;
  padding: 0.5rem;
  h1 {
    padding: 1rem;
    font-size: clamp(0.7rem, 2vw, 1rem);
  }

  ${media.mobile`
	width: 100%;
	`}
`

export const NewsCardCont = styled.div`
  box-shadow: 2px 3px 8px rgba(0, 0, 0, 0.1);
  height: 20rem;
`
export const ImageCard = styled.div`
  width: 100%;
  height: 15rem;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
export const Sort = styled.section`
  margin-bottom: 2rem;
  h1 {
    text-align: center;
  }
`
export const BtnContainer = styled.section`
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-column-gap: 1rem;
  button {
    width: 100%;
    padding: 0.3rem;
    border-radius: 2px;
    border: solid 1px #694a98;
    border-bottom-color: #1b95c8;
    outline: none;
    font-weight: 600;
    margin: 0.5rem 0;
    cursor: pointer;
  }
  button:hover,
  button:active {
    background: linear-gradient(#694a98, #1b95c8);
    color: #fff;
  }
`
export const Articles = styled.article`
  .article {
    display: grid;
    /* grid-template-columns: 15rem 1fr; */
    grid-template-columns: 8rem 1fr;
    height: 10rem;
    box-shadow: 2px 3px 8px rgba(0, 0, 0, 0.2);
    margin-bottom: 2rem;
  }
`
export const ArticleContent = styled.div`
  width: 100%;
  padding: 1rem;
  height: 10rem;
  h1 {
    font-size: clamp(0.7rem, 2vw, 1.2rem);
  }
  h2 {
    margin-top: 0.3rem;
    font-size: clamp(0.6rem, 2vw, 1rem);
  }
  .blogger {
    display: grid;
    grid-template-columns: 48px 1fr;
    grid-column-gap: 0.5rem;
    /* margin-top: 1rem; */
  }
  .bloggerImage {
    /* width: 48px;
  border-radius: 50%;
  height: 48px; */
    margin-top: 20%;
    align-self: flex-end;
  }
  .bloggerImage img {
    height: 38px;
    width: 38px;
    /* width: 100%; */
    border-radius: 50%;
    object-fit: cover;
  }
`
/* export const articleContent = styled.article` */
export const ArticlesDiv = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0px 40px;
  ${media.tablet`
	width: 60%;
	`}
  ${media.mobile`
	width: 100%;
	padding: 0px 10px;
	.slide_bg {
		width: 100%;
	}
	`}
`

export const ArticleMess = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0px 40px;
  ${media.tablet`
	width: 90%;
	`}
  ${media.mobile`
	width: 100%;
	padding: 0px 10px;
	.slide_bg {
		width: 100%;
	}
	`}
`
