const faqsData = [
  {
    id: 1,
    firstpagraph: `Bayelsa indigines presently studying in Nigerian universities.`,

    topic: 'Who can apply for the loan',
  },
  {
    id: 2,
    firstpagraph: `You can only apply for the BYSHELB loan at the Bayelsa State Higher Education Student Loan Board's 
    office located at Okaka Estate, off Sani Abacha Express Way, Yenagoa or via the official website, www.byshelb.by.gov.ng.`,

    topic: 'How do I apply?',
  },
  {
    id: 3,
    firstpagraph: `Student's loan amount per session depends on a couple of factors, including tuition fee requirement. Kindly contact 08161859727 for more information. `,

    topic: 'How much can I apply for?',
  },
]
export default faqsData
