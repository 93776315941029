import { Link } from "react-router-dom"
import styled, { keyframes } from "styled-components"
import Colors from "utils/colors"
import media from "utils/media"

export const SlideInPopUp = keyframes`
from {
    opacity: 0
}
to{
    opacity: 1
}
`
export const BlinkingBtn = keyframes`
0% {
		background: #6D0062;
	}

	10% {
		background: #8e44ad;
	}

	20% {
		background: ${Colors.gradient};
	}

	30% {
		background: #6D0062;
	}

	40% {
		background: #8e44ad;
	}

	50% {
		background: ${Colors.whiteGradient};
	}

	60% {
		background: ${Colors.gradient};
	}

	70% {
		background: ##391A60;
	}
	80% {
		background: #8e44ad;
	}

	90% {
		background: #6D0062;
	}

	100% {
		background: pink;
	}
`
export const MenuOverlay = styled.div`
	display: none;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	position: fixed;
	z-index: 10000;
	background: ${Colors.whiteGradient};
	display: ${({ open }) => (open ? "flex" : "none")};
	transition: all 0.5s ease-out;
	animation-name: ${SlideInPopUp};
	animation-duration: 0.5s;
	${media.tablet`
    display:flex;
    `}
	${media.mobile`
    display:flex;
    
    `}
`
export const MenuContainer = styled.div`
	/* background: ${Colors.white}; */
	background: transparent;
	display: flex;
	height: 60vh;
	width: 100%;
	margin-top: 20%;
	flex-direction: column;
	align-items: center;
`
export const CloseMenuContainer = styled.div`
	width: calc(100% - 40px);
	height: 23px;
	padding: 0px 20px;
	display: flex;
	justify-content: flex-end;

	margin-top: -13%;

	img{
		width: 100%;
	}
	button {
		background: transparent;
		outline: none;
		border: none;
	}


`
export const MobileNavLink = styled(Link)`
	text-decoration: none;
`
export const MobileMenuBtn = styled.button`
	width: 100%;
	height: 35px;
	outline: none;
	border: none;
	background: transparent;
	color: ${Colors.white};
	margin: 5px 0px;
	padding: 0px 20px;
	margin-top: ${({ mt }) => (mt ? mt : null)};
	font-size: 22px;
	font-weight: bold;
`
export const AuthButtonDiv = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	margin-top: 20px;
	/* padding: 0px 20px; */
`

export const JoinBtnMobile = styled.button`
width: 100%;
	height: 35px;
	outline: none;
	border: none;
	background: transparent;
	color: ${Colors.white};
	margin: 5px 0px;
	padding: 0px 20px;
	margin-top: ${({ mt }) => (mt ? mt : null)};
	font-size: 22px;
	font-weight: 900;
	
`
