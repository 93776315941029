import AuthSideNavDesk from "components/AuthNav/SideBar/SideBarMobile"
import { DashboardBody, DashboardContainer, Main } from "components/AuthNav/SideBar/style"
import { observer } from "mobx-react-lite"
import authStore from "mobx/AuthStore"
import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { LOGIN_ROUTE } from "routes"
import { setJwt } from "services/httpService"

const AuthLayout = ({ children }) => {
	const navigate = useNavigate()
	useEffect(() => {
		const status = authStore.loggedInStatus()
		const userStatus = sessionStorage.getItem("token")
		userStatus ? setJwt(userStatus) : setJwt("")
		if (!status) {
			navigate(`${LOGIN_ROUTE}`)
		}
	}, [navigate])
	return (
		<Main>
			{/* <MainContainer> */}
			<DashboardContainer>
				<AuthSideNavDesk />
				<DashboardBody>
					
					{" "}
					{children}
					{/* <Outlet /> */}
				</DashboardBody>
			</DashboardContainer>
			{/* </MainContainer> */}
		</Main>
	)
}

export default observer(AuthLayout)
