


import TextInput from "components/TextInput"
import { useFormik } from "formik"
import * as Yup from "yup"
import React, { useEffect, useState } from "react"
import { InfoText } from "components/Dashboard/Apply/style"
import { observer } from "mobx-react-lite"
import { editGuarantorTwo, getCurrentLoanStage } from "services/LoanService"
import { Spin } from "antd"

const GuarantorTwoForm = ({  id, user, mutate }) => {
	let segment;
    const fullUrl = window.location.href;
    segment = fullUrl.split('/').pop();

    const [initialValues, setInitialValues] = useState({
        first_name: "",
        middle_name: "",
        surname: "",
        phone: "",
        email: "",
        work_place: "",
        nin: "",
    });

	const [loading, setLoading] = useState(false)

    useEffect(() => {
        const currentLoanProgress = async () => {
            const res = await getCurrentLoanStage({ search: "guarantor_two", loanId: segment });
			const {guarantor_two} = res?.data?.data;
            setInitialValues({
                first_name: guarantor_two?.first_name || "",
                middle_name: guarantor_two?.middle_name || "",
                surname: guarantor_two?.surname || "",
                phone: guarantor_two?.phone || "",
                email: guarantor_two?.email || "",
                work_place: guarantor_two?.work_place || "",
                nin: guarantor_two?.nin || "",
            });
        };

        currentLoanProgress();

    }, [segment]);

    const formik = useFormik({
        initialValues: initialValues,
        enableReinitialize: true,
        onSubmit: async (data, helpers) => {
            const payload = { ...data }
            payload.id = id
			setLoading(true)
            const underResponse = await editGuarantorTwo(payload)
			setLoading(false)
            if (!underResponse.error) {
                mutate()
            }
        },

        validationSchema: Yup.object({
            first_name: Yup.string().max(50).required().label("First Name"),
            middle_name: Yup.string().max(50).label("Middle Name"),
            surname: Yup.string().max(50).required().label("Surname"),
            phone: Yup.string().max(100).required().label("Phone No."),
            email: Yup.string().max(255).required().label("Email"),
            work_place: Yup.string().max(255).required().label("Place of Work"),
            nin: Yup.string().length(11).required().label("NIN")
        })
    });

    return (
        <>
		<form onSubmit={formik.handleSubmit}>
			<div>
				<div >
					<header className="text-center p-4">
						<InfoText>Second Guarantor</InfoText>
					</header>
					<div className="bg-white max-w-xl mx-auto mb-10 p-4 pb-10 rounded-lg">
						<div>
							<TextInput
								astheric={true}
								label="Surname"
								placeholder="Surname"
								name="surname"
								id="surname"
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.surname}
								error={formik.errors.surname}
								visible={formik.touched.surname}
							/>
							<TextInput
								label="Middle Name"
								placeholder="Middle Name"
								name="middle_name"
								id="middle_name"
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.middle_name}
								error={formik.errors.middle_name}
								visible={formik.touched.middle_name}
							/>

							<TextInput
								astheric={true}
								label="First Name"
								placeholder="First Name"
								name="first_name"
								id="first_name"
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.first_name}
								error={formik.errors.first_name}
								visible={formik.touched.first_name}
							/>

							<TextInput
								astheric={true}
								label="Phone No."
								placeholder="Phone No."
								name="phone"
								id="phone"
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.phone}
								error={formik.errors.phone}
								visible={formik.touched.phone}
								type="tel"
							/>
						</div>

						<div>


							<TextInput
								astheric={true}
								label="Place of Work"
								placeholder="State Secretariat"
								name="work_place"
								id="work_place"
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.work_place}
								error={formik.errors.work_place}
								visible={formik.touched.work_place}
							/>

							<TextInput
								astheric={true}
								label="Email"
								placeholder="Enter your email"
								type="email"
								name="email"
								id="email"
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.email}
								error={formik.errors.email}
								visible={formik.touched.email}
							/>
							<TextInput
								astheric={true}
								label="NIN"
								placeholder="Enter your NIN"
								type="number"
								name="nin"
								id="nin"
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.nin}
								error={formik.errors.nin}
								visible={formik.touched.nin}
							/>
						</div>
					</div>
                    {/* <ProceedBtn onClick={prev}>Back</ProceedBtn>
					<ProceedBtn type="submit" disabled={loanStore.isLoading}>
						{loanStore.isLoading ? "Loading..." : "Proceed"}
					</ProceedBtn> */}
				</div>
			</div>
			<footer className="flex py-2 gap-2 px-4 pt-4 border-t border-gray-200 justify-end border-solid sticky bottom-0 bg-gray-50">
				<button className="bg-secondary-50 border border-solid border-secondary-500 rounded-full py-2 text-secondary-500 disabled:opacity-60 disabled:cursor-not-allowed  px-6" type="submit" disabled={loading}>
					{loading
					?<Spin/>:
					<span>Save</span>}
				</button>
			</footer>
		</form>
		</>
    )
}

export default observer(GuarantorTwoForm)