import FullScreenLoader from "components/FullScreenLoader"
import AuthLayout from "layout/AuthLayout"
import { observer } from "mobx-react-lite"
import React, { useContext, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { BASE_URL } from "services"
import useSWR from "swr"
import AppFee from "../AppFee"
// import GuarantorFormFile from "../GuarantorFormFile"
import { DASHBOARD_APPLY_UNDERGRAD_ROUTE, DASHBOARD_NEXTOFKINROUTE, DASHBOARD_PAYMENT_DETAILS_ROUTE, DASHBOARD_PROFILEROUTE } from "routes"
import http from "services/httpService"
import CompleteYourProfile from "components/CompleteProfile"
import apiRoute, { onBoardStep } from "routes/apirRoutes"
import toast from "react-hot-toast"
import { checkOnBoarding } from "services/MiscService"
import {PostStepsHeader as StepsHeader} from "./StepsHeader"
import LoanDetailReview from "../LoanReview/LoanDetailReview"
import PostGradUploadForm from "./PostGradUploadForm"
import PostGradInstitution from "../PostGradInstitution"
import PostGradLoanDetails from "../PostGradLoanDetails"
import PostGuarantorForm from "../PostGuarantorForm"
import PostGuarantorFormTwo from "../PostGuarantorFormTwo"
import { PostGradLoanStepContext, withProvider } from "context/PostLoanStepContext"

const PostGraduateLoan = () => {
	const {step} =  useContext(PostGradLoanStepContext)
	console.log({step})
	const navigate = useNavigate()
	let params = useParams()
	const url = `${BASE_URL}/loans/${params?.id}`

	const fetcher = async (apiURL) => {
		const res = await http.get(apiURL)
		return res?.data?.data
	}
	const { data: loan, mutate,} = useSWR(url, fetcher)
	const { data: userProfile } = useSWR(apiRoute.whoIAm, fetcher)

	useEffect( ()=>{
		const handleOnBoarded = async () => {
			const info = await checkOnBoarding();
			if(info?.error) {
				toast.error(info.error);
				return;
			}
			// reroute to onboarding page
			if(!info.onBoarded && info.nextStep === onBoardStep.profile){
				navigate(DASHBOARD_PROFILEROUTE)
				return;
			}
			if(!info.onBoarded && info.nextStep === onBoardStep.next_of_kin){
				navigate(DASHBOARD_NEXTOFKINROUTE)
				return;
			}
			if(!info.onBoarded && info.nextStep === onBoardStep.bank_account){
				navigate(DASHBOARD_PAYMENT_DETAILS_ROUTE)
				return;
			}
		}
		handleOnBoarded();
	},[navigate]);

	const loanForms = [
		<PostGradInstitution mutate={mutate} />,
		<PostGradLoanDetails id={params?.id} mutate={mutate} loanType={"postgraduate"} />,
		<PostGuarantorForm id={params?.id} mutate={mutate} loanType={"postgraduate"} />,
		<PostGuarantorFormTwo id={params?.id} mutate={mutate} loanType={"postgraduate"} />,
		<PostGradUploadForm mutate={mutate} id={params?.id} />,
		<LoanDetailReview loanType="postgraduate"/>,
		<AppFee loanType="postgraduate" id={params?.id} mutate={mutate} />
	]

	if (!loan) return <FullScreenLoader show={true} />
	if (params?.id && loan && loan.length <= 0) {
		navigate(`${DASHBOARD_APPLY_UNDERGRAD_ROUTE}`)
	}
	return (
		<AuthLayout>
			<>
			<StepsHeader/>
				{userProfile && !userProfile?.hometown ? (
					<CompleteYourProfile />
				) : userProfile && userProfile?.hometown && !userProfile?.next_of_kin?.first_name ? (
					<CompleteYourProfile title="Next of Kin" desc="Kindly update your next of kin details under the profile menu to apply" or="goto profile menu, next of kin and update" />
				) : userProfile && userProfile?.hometown && userProfile?.next_of_kin?.first_name && !userProfile?.bank_account?.account_number ? (
					<CompleteYourProfile
						link={DASHBOARD_PAYMENT_DETAILS_ROUTE}
						btnText="Payment"
						title="Account Details"
						desc="Kindly update your bank details under the payment detail menu"
						or="goto payment details menu"
					/>
				)
				: loanForms[step]
				}
			</>
		</AuthLayout>
	)
}

export default withProvider(observer(PostGraduateLoan))
