import {ReviewDetailSection, ReviewField, ReviewRow,ReviewFieldTitle, ReviewFieldValue } from "../style";
// email
// : 
// "jamsbrownedih@gmail.com"
// first_name
// : 
// "Goodluck"
// middle_name
// : 
// "Brown"
// nin
// : 
// 67778788889
// phone
// : 
// "09032137909"
// surname
// : 
// "Edih"
// work_place
// : 
// "Oil company"

export default function GuarantorDetail({guarantor,title}){
    return (
        <ReviewDetailSection>
                <h4 style={{fontSize:"24px",marginBottom:"16px"}}>{title}</h4>
                <ReviewRow>
                   <ReviewField>
                        <div className="field">
                            <ReviewFieldTitle>FirstName</ReviewFieldTitle>
                            <ReviewFieldValue >{guarantor.first_name}</ReviewFieldValue>
                        </div>
                   </ReviewField>
                   <ReviewField>
                        <div className="field">
                            <ReviewFieldTitle>Middlename</ReviewFieldTitle>
                            <ReviewFieldValue >{guarantor.middle_name}</ReviewFieldValue>
                        </div>
                   </ReviewField>
                </ReviewRow>
                <ReviewRow>
                    <ReviewField>
                        <div className="field">
                            <ReviewFieldTitle>LastName</ReviewFieldTitle>
                            <ReviewFieldValue >{guarantor.surname}</ReviewFieldValue>
                        </div>
                    </ReviewField>
                    <ReviewField>
                        <div className="field">
                            <ReviewFieldTitle>Email</ReviewFieldTitle>
                            <ReviewFieldValue >{guarantor.email}</ReviewFieldValue>
                        </div>
                    </ReviewField>
                </ReviewRow>
                <ReviewRow>
                    <ReviewField>
                        <div className="field">
                            <ReviewFieldTitle>NIN</ReviewFieldTitle>
                            <ReviewFieldValue >{guarantor.nin}</ReviewFieldValue>
                        </div>
                    </ReviewField>
                    <ReviewField>
                        <div className="field">
                            <ReviewFieldTitle>Phone Number</ReviewFieldTitle>
                            <ReviewFieldValue >{guarantor.phone}</ReviewFieldValue>
                        </div>
                    </ReviewField>
                </ReviewRow>
                <ReviewRow>
                    <ReviewField>
                        <div className="field">
                            <ReviewFieldTitle>Place of Work</ReviewFieldTitle>
                            <ReviewFieldValue >{guarantor.work_place}</ReviewFieldValue>
                        </div>
                    </ReviewField>
                </ReviewRow>
            </ReviewDetailSection>
    )
}