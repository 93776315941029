import React from 'react'
import { SIGNUP_ROUTE } from 'routes'
import { headImg } from 'utils/assets'
import {
  GetStartedBtn,
  HeadGroup,
  HeroContainer,
  HeroDiv,
  HeroHeader,
  HeroOverlay,
  LeftDiv,
  RightDiv,
  ZoomImage,
} from './style'
const HeroDefault = () => {
  
  return (
    <HeroContainer>
      {/* <BackgroundContainer /> */}
      <HeroOverlay />
      <HeroDiv>
        <LeftDiv>
          <HeadGroup>
            <HeroHeader>
              The Bayelsa state <span>Higher Education Students Loan Board</span> helps
              Bayelsans presently studying in Nigerian tertiary institutions to
              scale their educational career without fear.
            </HeroHeader>
            <GetStartedBtn to={SIGNUP_ROUTE}> Apply For A Zero-Interest Loan </GetStartedBtn>
          </HeadGroup>
        </LeftDiv>

        <RightDiv>
          <ZoomImage src={headImg} alt="student" />
        </RightDiv>
      </HeroDiv>
    </HeroContainer>
  )
}

export default HeroDefault
