import React, { useState } from "react"
import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
import { ArticlesDiv, ImageCard, NewsCard, NewsCardCont } from "./style"
import { Link } from "react-router-dom"

function PostSlider({ blog }) {
	const [currentSlide, setCurrentSlide] = useState(0)
	const [loaded, setLoaded] = useState(false)
	const [sliderRef, instanceRef] = useKeenSlider({
		initial: 0,
		breakpoints: {
			"(min-width: 400px)": {
				slides: { perView: 1.2, spacing: 10 }
			},
			"(min-width: 760px)": {
				slides: { perView: 2, spacing: 10 }
			},
			"(min-width: 1000px)": {
				slides: { perView: 3, spacing: 10 }
			}
		},
		slides: { perView: 1.1, spacing: 15 },
		slideChanged(slider) {
			setCurrentSlide(slider.track.details.rel)
		},
		created() {
			setLoaded(true)
		}
	})

	return (
		<ArticlesDiv>
			{blog.length > 0 && (
				<div className="navigation-wrapper">
					<div ref={sliderRef} className="keen-slider">
						{blog?.map(item => (
							<div key={`${item.id}kldldk`} className="keen-slider__slide slide_bg">
								<NewsCard>
									<Link to={`/blog/posts/${item?.blogUrl}/${item?.id}`}>
										<NewsCardCont>
											<ImageCard>
												<img src={item?.image?.url} alt={"hile"} />
											</ImageCard>
											{/* <h1>Helllo new title from above</h1> */}
											<h1>{item?.title}</h1>
										</NewsCardCont>
									</Link>
								</NewsCard>
							</div>
						))}
					</div>
				</div>
			)}
			{blog.length > 0 && loaded && instanceRef.current && (
				<div className="dots">
					{[...Array(instanceRef.current.track.details.slides.length).keys()].map(idx => {
						return (
							<button
								key={idx}
								onClick={() => {
									instanceRef.current?.moveToIdx(idx)
								}}
								className={"dot" + (currentSlide === idx ? " active" : "")}
							></button>
						)
					})}
				</div>
			)}
		</ArticlesDiv>
	)
}

export default PostSlider
