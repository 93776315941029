import React from "react"
import PaymentDetailsComp from "../Apply/PaymentDetailsComp"

import { PaymentContainer } from "./style"

function PaymentDetails({onboarded=true}) {
	return (
		<PaymentContainer>
			<PaymentDetailsComp onboarded={onboarded} />
		</PaymentContainer>
	)
}

export default PaymentDetails
