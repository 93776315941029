import React from "react"
import { useLocation } from "react-router-dom"
import BigMenu from "./BigMenu"
import { menuData } from "./menuData"
import { BigMenuItemsContainer } from "./style"

function MenuDas() {
	const location = useLocation()

	return (
		<BigMenuItemsContainer>
			{menuData?.map((menu, i) => (
				<BigMenu
					link={menu.link}
					text={menu.text}
					key={i}
					icon={location?.pathname === menu.link ? menu.activeIcon : menu.link.includes(location.pathname.split("/")[2]) ? menu.activeIcon : menu.icon}
				/>
			))}
		</BigMenuItemsContainer>
	)
}

export default MenuDas
