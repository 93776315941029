import Footer from 'components/Footer'
import Navbar from 'components/Navbar'
import { observer } from 'mobx-react-lite'
import React from 'react'


const Mainlayout = (props) => {
  
  return (
    <div>
    {/* <div className="body-bg"> */}
      <Navbar />
      <div>{props.children}</div>
      {/* <MainContainer>{props.children}</MainContainer> */}
      <Footer />
    </div>
  )
}

export default observer(Mainlayout)
