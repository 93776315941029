import { useNavigate } from "react-router-dom"
import { DASHBOARD_APPLY_POSTGRAD_ROUTE, DASHBOARD_APPLY_ROUTE, DASHBOARD_APPLY_UNDERGRAD_ROUTE } from "routes"

export default function Application(){
    const router = useNavigate()
    const goToRoute = (route)=>{
        return ()=>{
            router(route)
        }
    }
    return(
        <div className="bg-white border border-solid border-gray-300 p-2 rounded-lg">
           <header className="flex justify-between py-2 border-b border-secondary-100 border-solid">
                <p className="flex gap-2 text-sm sm:text-lg text-gray-[#242424] items-center">
                    <span><i className="bi bi-folder-plus"></i></span>
                    <span className="font-medium">Apply For Loan</span>
                </p>
                <button onClick={goToRoute(DASHBOARD_APPLY_ROUTE)} className="bg-primary-100 border border-solid border-secondary-500 text-secondary-600 px-2 sm:px-4 py-2 text-xs sm:text-sm rounded-full">See Applications</button>
            </header>
            <div className="text-center mt-4">
                <button onClick={goToRoute(DASHBOARD_APPLY_UNDERGRAD_ROUTE)} className="bg-secondary text-white px-6 py-2 text-sm sm:text-base rounded-full">Apply for Undergraduate Loan</button>
            </div>
            <div className=" my-4 text-center">
                <button onClick={goToRoute(DASHBOARD_APPLY_POSTGRAD_ROUTE)} className="bg-secondary-100 text-secondary-800 text-sm sm:text-base border border-solid border-secondary px-6 py-2 rounded-full">Apply for Postgraduate Loan</button>
            </div>
        </div>
    )
}