import styled from "styled-components"
import Colors from "utils/colors"
import media from "utils/media"

export const AuthNavContainer = styled.div`
	background: ${Colors.white};
	z-index: 600;
	width: 100%;
	height: 75px;
	display: flex;
	justify-content: space-between;
	padding: 0px 60px;
	align-items: center;
	position: fixed;
	top: 0;
	border-bottom: 1px solid #ccc;
	/* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */

	${media.mobile`
    padding: 0px 20px;
    `}
`

export const AuthNavLogo = styled.div`
	display: none;
	cursor: pointer;
	img {
		width: 40px;
		height: 40px;
	}
	${media.smallDesktopMinimum`
	display: flex;

  img {
    width:40px;
    height:40px;
  }
  `}
	${media.mobile`

  img {
    width:40px;
    height:40px;
  }
  `}
`
export const AuthNavLogoMain = styled.div`
	cursor: pointer;
	display: flex;

	${media.smallDesktopMinimum`
	display: none;

 
  `}
`

export const AuthNavRight = styled.div`
	display: flex;
	align-items: center;
`

export const AuthNavText = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;

	.student__name {
		font-size: 14px;
		font-weight: 500;
	}

	.student__category {
		font-size: 12px;
	}
`

export const AuthUserIcon = styled.div`
	width: 60px;
	height: 60px;
	border-radius: 50%;
	background-color: yellow;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 15px;
	img {
		width: 100%;
		height: 100%;
		border-radius: 50%;
	}
`
