export const uniData = [
  {
    id: 'tafuni',
    label: 'Abubakar Tafawa Balewa University, Bauchi',
    value: 'Abubakar Tafawa Balewa University, Bauchi',
  },
  {
    id: 'abu',
    label: 'Ahmadu Bello University, Zaria',
    value: 'Ahmadu Bello University, Zaria',
  },
  {
    id: 'buk',
    label: 'Bayero University, Kano',
    value: 'Bayero University, Kano',
  },
  {
    id: 'fg',
    label: 'Federal University Gashua, Yobe',
    value: 'Federal University Gashua, Yobe',
  },
  {
    id: 'fupe',
    label: 'Federal University of Petroleum Resources, Effurun',
    value: 'Federal University of Petroleum Resources, Effurun',
  },
  {
    id: 'futa',
    label: 'Federal University of Technology, Akure',
    value: 'Federal University of Technology, Akure',
  },
  {
    id: 'futminna',
    label: 'Federal University of Technology, Minna',
    value: 'Federal University of Technology, Minna',
  },
  {
    id: 'futo',
    label: 'Federal University of Technology, Owerri',
    value: 'Federal University of Technology, Owerri',
  },
  {
    id: 'futjig',
    label: 'Federal University, Dutse, Jigawa State',
    value: 'Federal University, Dutse, Jigawa State',
  },
  {
    id: 'futminna',
    label: 'Federal University, Dutsin-Ma, Katsina',
    value: 'Federal University, Dutsin-Ma, Katsina',
  },
  {
    id: 'fudkas',
    label: 'Federal University of Technology, Minna',
    value: 'Federal University of Technology, Minna',
  },
  {
    id: 'fukgo',
    label: 'Federal University, Kashere, Gombe State',
    value: 'Federal University, Kashere, Gombe State',
  },
  {
    id: 'fulns',
    label: 'Federal University, Lafia, Nasarawa State',
    value: 'Federal University, Lafia, Nasarawa State',
  },
  {
    id: 'fulkos',
    label: 'Federal University, Lokoja, Kogi State',
    value: 'Federal University, Lokoja, Kogi State',
  },
  {
    id: 'alundi',
    label: 'Alex Ekwueme University, Ndufu-Alike, Ebonyi State',
    value: 'Alex Ekwueme University, Ndufu-Alike, Ebonyi State',
  },
  {
    id: 'fuo',
    label: 'Federal University, Otuoke, Bayelsa',
    value: 'Federal University, Otuoke, Bayelsa',
  },
  {
    id: 'fuekiti',
    label: 'Federal University, Oye-Ekiti, Ekiti State',
    value: 'Federal University, Oye-Ekiti, Ekiti State',
  },
  {
    id: 'futaraba',
    label: 'Federal University, Wukari, Taraba State',
    value: 'Federal University, Wukari, Taraba State',
  },
  {
    id: 'fukebi',
    label: 'Federal University, Birnin Kebbi',
    value: 'Federal University, Birnin Kebbi',
  },
  {
    id: 'fugusau',
    label: 'Federal University, Gusau Zamfara',
    value: 'Federal University, Gusau Zamfara',
  },
  {
    id: 'mikeokpara',
    label: 'Michael Okpara University of Agricultural Umudike',
    value: 'Michael Okpara University of Agricultural Umudike',
  },
  {
    id: 'moadama',
    label: 'Modibbo Adama University of Technology, Yola',
    value: 'Modibbo Adama University of Technology, Yola',
  },
  {
    id: 'futminna',
    label: 'National Open University of Nigeria, Lagos',
    value: 'National Open University of Nigeria, Lagos',
  },
  {
    id: 'nounlagos',
    label: 'Federal University of Technology, Minna',
    value: 'Federal University of Technology, Minna',
  },
  {
    id: 'npawudil',
    label: 'Nigeria Police Academy Wudil',
    value: 'Nigeria Police Academy Wudil',
  },
  {
    id: 'ndakaduna',
    label: 'Nigerian Defence Academy Kaduna',
    value: 'Nigerian Defence Academy Kaduna',
  },
  {
    id: 'namzik',
    label: 'Nnamdi Azikiwe University, Awka',
    value: 'Nnamdi Azikiwe University, Awka',
  },
  {
    id: 'oauife',
    label: 'Obafemi Awolowo University,Ile-Ife',
    value: 'Obafemi Awolowo University,Ile-Ife',
  },
  {
    id: 'uniabuja',
    label: 'University of Abuja, Gwagwalada',
    value: 'University of Abuja, Gwagwalada',
  },
  {
    id: 'fuabiokuta',
    label: 'Federal University of Agriculture, Abeokuta',
    value: 'Federal University of Agriculture, Abeokuta',
  },
  {
    id: 'uamakurdi',
    label: 'University of Agriculture, Makurdi',
    value: 'University of Agriculture, Makurdi',
  },
  {
    id: 'uniben',
    label: 'University of Benin',
    value: 'University of Benin',
  },
  {
    id: 'unical',
    label: 'University of Calabar',
    value: 'University of Calabar',
  },
  {
    id: 'unibadan',
    label: 'University of Ibadan',
    value: 'University of Ibadan',
  },
  {
    id: 'unilorin',
    label: 'University of Ilorin',
    value: 'University of Ilorin',
  },
  {
    id: 'unijos',
    label: 'University of Jos',
    value: 'University of Jos',
  },
  {
    id: 'unilag',
    label: 'University of Lagos',
    value: 'University of Lagos',
  },
  {
    id: 'unimadu',
    label: 'University of Maiduguri',
    value: 'University of Maiduguri',
  },
  {
    id: 'unn',
    label: 'University of Nigeria, Nsukka',
    value: 'University of Nigeria, Nsukka',
  },
  {
    id: 'uniport',
    label: 'University of Port-Harcourt',
    value: 'University of Port-Harcourt',
  },
  {
    id: 'uniuyo',
    label: 'University of Uyo',
    value: 'University of Uyo',
  },
  {
    id: 'usmandu',
    label: 'Usumanu Danfodiyo University',
    value: 'Usumanu Danfodiyo University',
  },
  {
    id: 'nmudelta',
    label: 'Nigerian Maritime University Okerenkoko, Delta State',
    value: 'Nigerian Maritime University Okerenkoko, Delta State',
  },
  {
    id: 'aitkd',
    label: 'Air Force Institute of Technology, Kaduna',
    value: 'Air Force Institute of Technology, Kaduna',
  },
  {
    id: 'ngarmybiu',
    label: 'Nigerian Army University Biu',
    value: 'Nigerian Army University Biu',
  },
  {
    id: 'ndu',
    label: 'Niger Delta University, Wilberforce Island, Bayelsa State',
    value: 'Niger Delta University, Wilberforce Island, Bayelsa State',
  },
  {
    id: 'fuhtben',
    label: 'Federal University of Health Technology, Otukpo Benue State',
    value: 'Federal University of Health Technology, Otukpo Benue State',
  },
  {
    id: 'fuazuru',
    label: 'Federal University of Agriculture, Zuru, Kebbi State',
    value: 'Federal University of Agriculture, Zuru, Kebbi State',
  },
  {
    id: 'futjig',
    label: 'Federal University of Technology, Babura, Jigawa State',
    value: 'Federal University of Technology, Babura, Jigawa State',
  },
  {
    id: 'futak',
    label: 'Federal University of Technology, Ikot Abasi, Akwa Ibom State',
    value: 'Federal University of Technology, Ikot Abasi, Akwa Ibom State',
  },
  {
    id: 'fuhsaz',
    label: 'Federal University of Health Sciences, Azare, Bauchi State',
    value: 'Federal University of Health Sciences, Azare, Bauchi State',
  },
  {
    id: 'fuhss',
    label: 'Federal University of Health Sciences, Ila Orangun, Osun State',
    value: 'Federal University of Health Sciences, Ila Orangun, Osun State',
  },
  {
    id: 'afpu',
    label:
      'Akanu Ibiam Federal Polytechnic Unwana, P.M.B 1007, Afikpo, Ebonyi State',
    value:
      'Akanu Ibiam Federal Polytechnic Unwana, P.M.B 1007, Afikpo, Ebonyi State',
  },
  {
    id: 'apauchi',
    label: 'Auchi Polytechnic, Auchi, Edo State',
    value: 'Auchi Polytechnic, Auchi, Edo State',
  },
  {
    id: 'fpadoekiti',
    label: 'Federal Polytechnic Ado Ekiti, Ekiti State',
    value: 'Federal Polytechnic Ado Ekiti, Ekiti State',
  },
  {
    id: 'fpolytaraba',
    label: 'Federal Polytechnic Bali, Taraba State',
    value: 'Federal Polytechnic Bali, Taraba State',
  },
  {
    id: 'fpolybauchi',
    label: 'Federal Polytechnic Bauchi, P.M. B 231, Bauchi State',
    value: 'Federal Polytechnic Bauchi, P.M. B 231, Bauchi State',
  },
  {
    id: 'fpolyniger',
    label: 'Federal Polytechnic Bida, P.M.B 55, Niger State',
    value: 'Federal Polytechnic Bida, P.M.B 55, Niger State',
  },
  {
    id: 'fpdam',
    label: 'Federal Polytechnic Damaturu, P.M.B 1006, Yobe State',
    value: 'Federal Polytechnic Damaturu, P.M.B 1006, Yobe State',
  },
  {
    id: 'fpdaura',
    label: 'Federal Polytechnic, Daura, Katsina State',
    value: 'Federal Polytechnic, Daura, Katsina State',
  },
  {
    id: 'fpede',
    label: 'Federal Polytechnic Ede, Osun State',
    value: 'Federal Polytechnic Ede, Osun State',
  },
  {
    id: 'fpekowe',
    label: 'Federal Polytechnic Ekowe, Bayelsa State',
    value: 'Federal Polytechnic Ekowe, Bayelsa State',
  },
  {
    id: 'fpidahkogi',
    label: 'Federal Polytechnic Idah P.M.B 1035, Kogi State',
    value: 'Federal Polytechnic Idah P.M.B 1035, Kogi State',
  },
  {
    id: 'fpilaro',
    label: 'Federal Polytechnic Ilaro, P.M.B 50, Ogun State',
    value: 'Federal Polytechnic Ilaro, P.M.B 50, Ogun State',
  },
  {
    id: 'fpile',
    label: 'Federal Polytechnic Ile-Oluji, Ondo State',
    value: 'Federal Polytechnic Ile-Oluji, Ondo State',
  },
  {
    id: 'fpkaura',
    label: 'Federal Polytechnic Kaura Namoda, P.M.B, 1012, Zamfara State',
    value: 'Federal Polytechnic Kaura Namoda, P.M.B, 1012, Zamfara State',
  },
  {
    id: 'fpolykaltungo',
    label: 'Federal Polytechnic Kaltungo, Gombe State',
    value: 'Federal Polytechnic Kaltungo, Gombe State',
  },
  {
    id: 'fpolymubi',
    label: 'Federal Polytechnic Mubi, PMB 35 Mubi, Adamawa State',
    value: 'Federal Polytechnic Mubi, PMB 35 Mubi, Adamawa State',
  },
  {
    id: 'fpnasara',
    label: 'Federal Polytechnic Nasarawa, P.M.B. 01 Nasarawa State',
    value: 'Federal Polytechnic Nasarawa, P.M.B. 01 Nasarawa State',
  },
  {
    id: 'fpnekede',
    label: 'Federal Polytechnic Nekede, P.M.B 1036, Owerri, Imo State',
    value: 'Federal Polytechnic Nekede, P.M.B 1036, Owerri, Imo State',
  },
  {
    id: 'fpoffa',
    label: 'Federal Polytechnic Offa, P.M.B 420, Kwara State',
    value: 'Federal Polytechnic Offa, P.M.B 420, Kwara State',
  },
  {
    id: 'fpoko',
    label: 'Federal Polytechnic Oko, Anambra State',
    value: 'Federal Polytechnic Oko, Anambra State',
  },
  {
    id: 'fpoigbonny',
    label: 'Federal Polytechnic of Oil and Gas Bonny, Rivers State',
    value: 'Federal Polytechnic of Oil and Gas Bonny, Rivers State',
  },
  {
    id: 'fpukana',
    label: 'Federal Polytechnic Ukana,P.M.B 2014, Akwa Ibom State',
    value: 'Federal Polytechnic Ukana,P.M.B 2014, Akwa Ibom State',
  },
  {
    id: 'hafp',
    label:
      'Hussaini Adamu Federal Polytechnic, P.M.B 5004, Kazaure Jigawa State',
    value:
      'Hussaini Adamu Federal Polytechnic, P.M.B 5004, Kazaure Jigawa State',
  },
  {
    id: 'kpkad',
    label: 'Kaduna Polytechnic, Kaduna',
    value: 'Kaduna Polytechnic, Kaduna',
  },
  {
    id: 'nicitu',
    label: 'National Institute of Construction Technology Uromi',
    value: 'National Institute of Construction Technology Uromi',
  },
  {
    id: 'wufpoly',
    label: 'Waziri Umaru Federal Polytechnic, P.M.B. 1034, Birnin Kebbi',
    value: 'Waziri Umaru Federal Polytechnic, P.M.B. 1034, Birnin Kebbi',
  },
  {
    id: 'ycot',
    label: 'Yaba College of Technology, P.M.B. 2011, Yaba, Lagos State',
    value: 'Yaba College of Technology, P.M.B. 2011, Yaba, Lagos State',
  },
  {
    id: 'fpayede',
    label: 'Federal Polytechnic Ayede, Oyo State',
    value: 'Federal Polytechnic Ayede, Oyo State',
  },
  {
    id: 'fpmungono',
    label: 'Federal Polytechnic Munguno, Borno State',
    value: 'Federal Polytechnic Munguno, Borno State',
  },
  {
    id: 'fpnyak',
    label: 'Federal Polytechnic N’yak, Shendam, Plateau State',
    value: 'Federal Polytechnic N’yak, Shendam, Plateau State',
  },
  {
    id: 'fpohodo',
    label: 'Federal Polytechnic Ohodo, Enugu State',
    value: 'Federal Polytechnic Ohodo, Enugu State',
  },
  {
    id: 'fpugep',
    label: 'Federal Polytechnic Ugep, Cross Rivers State',
    value: 'Federal Polytechnic Ugep, Cross Rivers State',
  },
  {
    id: 'fpwannu',
    label: 'Federal Polytechnic Wannune, Benue State',
    value: 'Federal Polytechnic Wannune, Benue State',
  },
  {
    id: 'afitkad',
    label: 'Airforce Institute of Technology (AFIT),NAF Base Kaduna',
    value: 'Airforce Institute of Technology (AFIT),NAF Base Kaduna',
  },
  {
    id: 'ptidelta',
    label:
      'Petroleum Training Institute Effurun, P.M.B 20, Effurun. Delta State',
    value:
      'Petroleum Training Institute Effurun, P.M.B 20, Effurun. Delta State',
  },
  {
    id: 'naites',
    label:
      'Nigerian Army Institute of Technology and Environmental Science (NAITES) Makurdi',
    value:
      'Nigerian Army Institute of Technology and Environmental Science (NAITES) Makurdi',
  },
  {
    id: 'nkat',
    label:
      'Nigerian College of Aviation Technology (NCAT), Sokoto Road, Zaria, Kaduna State',
    value:
      'Nigerian College of Aviation Technology (NCAT), Sokoto Road, Zaria, Kaduna State',
  },
  {
    id: 'fceasaba',
    label: 'Federal College of Education (Technical), Asaba',
    value: 'Federal College of Education (Technical), Asaba',
  },
  {
    id: 'fcekano',
    label: 'Federal College of Education, Kano',
    value: 'Federal College of Education, Kano',
  },
  {
    id: 'fceoyo',
    label: 'Federal College of Education (Special), Oyo',
    value: 'Federal College of Education (Special), Oyo',
  },
  {
    id: 'fceabeokuta',
    label: 'Federal College of Education, Abeokuta',
    value: 'Federal College of Education, Abeokuta',
  },
  {
    id: 'fceamufu',
    label: 'Federal College of Education, Eha-Amufu',
    value: 'Federal College of Education, Eha-Amufu',
  },
  {
    id: 'fcegombe',
    label: 'Federal College of Education (Technical), Gombe',
    value: 'Federal College of Education (Technical), Gombe',
  },
  {
    id: 'fcekontagora',
    label: 'Federal College of Education, Kontagora',
    value: 'Federal College of Education, Kontagora',
  },
  {
    id: 'fceokene',
    label: 'Federal College of Education, Okene',
    value: 'Federal College of Education, Okene',
  },
  {
    id: 'fceomoku',
    label: 'Federal College of Education (Technical), Omoku',
    value: 'Federal College of Education (Technical), Omoku',
  },
  {
    id: 'fcepotiskum',
    label: 'Federal College of Education (Tech), Potiskum',
    value: 'Federal College of Education (Tech), Potiskum',
  },
  {
    id: 'alvanice',
    label: 'Alvan Ikoku College of Education, Owerri',
    value: 'Alvan Ikoku College of Education, Owerri',
  },
  {
    id: 'fceakoka',
    label: 'Federal College of Education (Technical), Akoka',
    value: 'Federal College of Education (Technical), Akoka',
  },
  {
    id: 'fcebichi',
    label: 'Federal College of Education (Technical), Bichi',
    value: 'Federal College of Education (Technical), Bichi',
  },
  {
    id: 'fcegusau',
    label: 'Federal College of Education (Technical), Gusau',
    value: 'Federal College of Education (Technical), Gusau',
  },
  {
    id: 'fcekats',
    label: 'Federal College of Education, Katsina',
    value: 'Federal College of Education, Katsina',
  },
  {
    id: 'fceobudu',
    label: 'Federal College of Education, Obudu',
    value: 'Federal College of Education, Obudu',
  },
  {
    id: 'aceondo',
    label: 'Adeyemi College of Education, Ondo',
    value: 'Adeyemi College of Education, Ondo',
  },
  {
    id: 'fcepankshin',
    label: 'Federal College of Education, Pankshin',
    value: 'Federal College of Education, Pankshin',
  },
  {
    id: 'fceyola',
    label: 'Federal College of Education, Yola',
    value: 'Federal College of Education, Yola',
  },
  {
    id: 'fcezaria',
    label: 'Federal College of Education, Zaria',
    value: 'Federal College of Education, Zaria',
  },
  {
    id: 'naseilorin',
    label: 'Nigerian Army School of Education (NASE), Ilorin',
    value: 'Nigerian Army School of Education (NASE), Ilorin',
  },
  {
    id: 'fceumunze',
    label: 'Federal College of Education (T), Umunze',
    value: 'Federal College of Education (T), Umunze',
  },
  {
    id: 'ogsghat',
    label: 'Others',
    value: 'others',
  },
]
