import TextInput from "components/TextInput"
import * as Yup from "yup"
import { useNavigate } from "react-router-dom"
import { useFormik } from "formik"
import authStore from "mobx/AuthStore"
import React from "react"
import { LOGIN_ROUTE, RESET_PASSWORD_ROUTE } from "routes"
import { AppLogo2 } from "utils/assets"
import { AuthBottomLink, AuthButton, AuthHeader } from ".."
import { LoginContainer, LoginDiv, LoginFormContainer } from "../Login/style"
import { AuthCard, Authcontainer, AuthContent } from "../style"
import { forgotPasswordEndPoint } from "services/AuthService"
import { observer } from "mobx-react-lite"
import { Img } from "globalStyles"

function ForgotPassword() {
	const navigate = useNavigate()

	const formik = useFormik({
		enableReinitialize: false,
		initialValues: {
			email: ""
		},
		onSubmit: async (data, helpers) => {
			const response = await forgotPasswordEndPoint(data)
			if (!response.error) {
				helpers.resetForm()
				navigate(`${RESET_PASSWORD_ROUTE}`)
			}
		},
		validationSchema: Yup.object({
			email: Yup.string().max(255).email().required().label("Email")
		})
	})
	return (
		<Authcontainer>
			<AuthContent>
				<Img src={AppLogo2} alt="BSL" />
				<LoginContainer>
					<LoginDiv onSubmit={formik.handleSubmit}>
						<AuthCard>
							<LoginFormContainer>
								<AuthHeader text="Password Reset" />
								<p>Forget your password</p>
								<TextInput
									type="email"
									label="Email Address"
									name="email"
									id="email"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.email}
									error={formik.errors.email}
									visible={formik.touched.email}
									placeholder="Email Address"
									mt="0.5rem"
								/>
								<AuthButton title={authStore.isLoading ? "Loading..." : "Submit"} />
								<AuthBottomLink link={LOGIN_ROUTE} text="Did remember your password?" linktext="Login here" />
							</LoginFormContainer>
						</AuthCard>
					</LoginDiv>
				</LoginContainer>
			</AuthContent>
		</Authcontainer>
	)
}

export default observer(ForgotPassword)
