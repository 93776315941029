import Footer from "components/Footer"
import UniqueNavbar from "components/Navbar/UniqueNavbar"
import { observer } from "mobx-react-lite"
import authStore from "mobx/AuthStore"
import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { DASHBOARD_ROUTE } from "routes"

function UniqueLayout(props) {
	const navigate = useNavigate()
	useEffect(() => {
		const status = authStore.loggedInStatus()
		if (status) {
			navigate(`${DASHBOARD_ROUTE}`)
		}
		window.scrollTo(0, 0)
	}, [navigate])
	return (
		<>
			<UniqueNavbar />
			{props.children}
			<Footer />
		</>
	)
}

export default observer(UniqueLayout)
