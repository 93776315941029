import { Img } from "globalStyles"
import React, { useEffect } from "react"
import { AuthNavUser } from "utils/assets"
import MenuDas from "../Menu"
import BigMenu from "../Menu/BigMenu"
import { BigMenuDiv, LogoutDiv, SideDesktoImageDiv, SideMenuStatic, UserDpName } from "./style"
import logoutActive from "assets/icons/logoutActive.svg"
import { DASHBOARD_LOGOUT_ROUTE } from "routes"
import { BASE_URL } from "services"
import http, { setJwt } from "services/httpService"
import useSWR from "swr"
import FullScreenLoader from "components/FullScreenLoader"
import authStore from "mobx/AuthStore"
import { observer } from "mobx-react-lite"

const AuthSideNavDesk = ({ isOpen, onClick }) => {
	const url = `${BASE_URL}/users/who_i_am`
	const fetcher = async () => {
		const res = await http.get(url)
		return res?.data?.data
	}

	const { data: user } = useSWR(url, fetcher)
	useEffect(() => {
		const userStatus = sessionStorage.getItem("token")
		userStatus ? setJwt(userStatus) : setJwt("")
		const in_user = user ? user : {}
		authStore.setCurrentUser(in_user)
	}, [user])

	if (!user) return <FullScreenLoader />

	return (
		<SideMenuStatic>
			<BigMenuDiv>
				<SideDesktoImageDiv>
					<Img src={user?.avatar ? user?.avatar : AuthNavUser} alt="byshelb user" />
				</SideDesktoImageDiv>
				<UserDpName>
					{user?.first_name} {user?.surname}
				</UserDpName>
				<MenuDas />
				<LogoutDiv>
					<BigMenu link={DASHBOARD_LOGOUT_ROUTE} text="Logout" icon={logoutActive} />
				</LogoutDiv>
			</BigMenuDiv>
		</SideMenuStatic>
	)
}

export default observer(AuthSideNavDesk)
