import ApplyForLoan from "components/Dashboard/Apply"
// import { MainContainer } from "globalStyles"
import AuthLayout from "layout/AuthLayout"
// import MainLayout from "layout/MainLayout"
import React from "react"

function ApplyLoanPage() {
	return (
		<AuthLayout>
			<ApplyForLoan />
		</AuthLayout>
	)
}

export default ApplyLoanPage
