import React, { useState } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import { FormSettings, FormSettingsSubmit, SettingTabContainer, SettingsContainer, Settingsheader } from "./style"
import TextInput from "components/TextInput"
import ConnectBvn from "./verifyBvn"

import authStore from "mobx/AuthStore"
import { AButtonContainer, ScrollDiv } from "../Profile/ProfileBtn"
import SettingsBtn from "./SettingsBtn"
import { useNavigate } from "react-router-dom"
import { LOGIN_ROUTE } from "routes"
import { setPassword } from "services/AuthService"

function SettingsComponent() {
	const navigate = useNavigate();
	const [key, setKey] = useState("settings")
	const formik = useFormik({
		enableReinitialize: false,
		initialValues: {
			old_password: "",
			password: "",
			confirm_password: ""
		},
		onSubmit: async (data, helpers) => {
			const response = await setPassword(data)
			if (!response.error) {
				helpers.resetForm()
				navigate(`${LOGIN_ROUTE}`)
			}
		
		},
		validationSchema: Yup.object({
			old_password: Yup.string().min(8).required("Old Password is required.").label("Old Password"),
			password: Yup.string().min(8).required("New Password is required.").label("New Password"),
			confirm_password: Yup.string()
				.oneOf([Yup.ref("password"), null], "Confirm Password must match New Password")
				.required("Confirm Password is required.")
		}).label("Confirm Password")
	})

	return (
		<SettingsContainer>
			<Settingsheader>Account Settings</Settingsheader>
			<SettingTabContainer>
				<AButtonContainer>
					<ScrollDiv>
						<SettingsBtn iconType="password" active={key === "settings" ? true : false} onClick={() => setKey("settings")} />
						<SettingsBtn active={key === "bvn" ? true : false} onClick={() => setKey("bvn")} title="Update BVN" />
					</ScrollDiv>
				</AButtonContainer>
				{/* <ToggleTabBtn onClick={() => setKey("settings")}>Password settings</ToggleTabBtn>
				<ToggleTabBtn ml="10px" onClick={() => setKey("bvn")}>
					Update BVN
				</ToggleTabBtn> */}
			</SettingTabContainer>

			{key === "bvn" && <ConnectBvn />}

			{key === "settings" && (
				<FormSettings onSubmit={formik.handleSubmit}>
					<TextInput
						label="Old Password"
						name="old_password"
						type="password"
						id="old_password"
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.old_password}
						error={formik.errors.old_password}
						visible={formik.touched.old_password}
						placeholder="Old Password"
						mt="0.5rem"
					/>
					<TextInput
						label="New Password"
						name="password"
						id="password"
						type="password"
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.password}
						error={formik.errors.password}
						visible={formik.touched.password}
						placeholder="New password"
						mt="0.5rem"
					/>
					<TextInput
						label="Confirm Password"
						name="confirm_password"
						id="confirm_password"
						type="password"
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.confirm_password}
						error={formik.errors.confirm_password}
						visible={formik.touched.confirm_password}
						placeholder="Confirm Password"
					/>
					<FormSettingsSubmit disabled={authStore.isLoading ? true : false}>
						{authStore.isLoading ? "Loading..." : "Submit"}
					</FormSettingsSubmit>
				</FormSettings>
			)}
		</SettingsContainer>
	)
}

export default SettingsComponent
