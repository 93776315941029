import { Img } from "globalStyles"
import React from "react"
import { AuthNavUser } from "utils/assets"
import MenuDas from "../Menu"
import BigMenu from "../Menu/BigMenu"
import { AuthOverlay, BigMenuDiv, LogoutDiv, SideImageDiv, UserDpName } from "./style"
import logoutActive from "assets/icons/logoutActive.svg"
import { DASHBOARD_LOGOUT_ROUTE } from "routes"
const AuthSideNav = ({ isOpen, onClick, user }) => {
	return (
		<AuthOverlay open={isOpen} onClick={onClick}>
			<BigMenuDiv>
				<SideImageDiv>
					<Img src={user?.avatar ? user?.avatar : AuthNavUser} alt="byshelb user" />
				</SideImageDiv>
				<UserDpName>
					{user?.first_name} {user?.surname}
				</UserDpName>
				<MenuDas />
				<LogoutDiv>
					<BigMenu link={DASHBOARD_LOGOUT_ROUTE} text="Logout" icon={logoutActive} />
				</LogoutDiv>
			</BigMenuDiv>
		</AuthOverlay>
	)
}

export default AuthSideNav
