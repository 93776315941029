export const empData = [
	{
		id: "rstgtst",
		label: "Employed",
		value: "employed"
	},
	{
		id: "dghsjkal",
		label: "Unemployed",
		value: "unemployed"
	}
	// {
	//     id: 'fsewy',
	//     label: 'Civil Servant',
	//     value: 'civil servant',
	// },
	// {
	//     id: 'vdcegj',
	//     label: 'Self-employed',
	//     value: 'self-employed',
	// },
	// {
	//     id: 'erstdu',
	//     label: 'Artisan',
	//     value: 'artisan',
	// },
]
