import { Img } from "globalStyles"
import React from "react"
import { UploadIcon } from "utils/assets"
import { FileContent, FileContentDiv,FormatType, FileDescText, FileFooterText, FileHeaderText, FileUploadCon } from "./style"

const FileUploadForm = ({ title = "Signed Letter Of Identification", indescribe = "", instruction = false, formats }) => {
	return (
		<FileUploadCon>
			<FileContentDiv>
				<FileHeaderText>{title}</FileHeaderText>
				<FormatType>{formats}</FormatType>
				<FileContent>
					<Img src={UploadIcon} alt="Upload Icon" />
					{indescribe ? (
						<FileDescText>{indescribe}</FileDescText>
					) : (
						<FileDescText>
							Drag And Drop Or <span>Browse Files</span>
						</FileDescText>
					)}
				</FileContent>
				{instruction ? <FileFooterText>From Registrar or Dean Of Student's affairs</FileFooterText> : null}
			</FileContentDiv>
		</FileUploadCon>
	)
}

export default FileUploadForm
