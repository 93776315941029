import { Link } from 'react-router-dom'
import styled, { createGlobalStyle } from 'styled-components/macro'
import Colors from 'utils/colors'
import FontLoader from './FontLoader'

const GlobalStyle = createGlobalStyle`

* {
  border: 0;
  padding: 0;
  margin: 0;
  outline: 0;
  /* list-style: none; */
  box-sizing: border-box;
  text-decoration: none;
}
body {
  margin: 0;
  font-family: 'Noto Serif', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
 
}
[class^="number-slide"],
[class*=" number-slide"] {
	background: grey;
	display: flex;
	align-items: center;
	color: #fff;
	height: 300px;
	max-height: 100vh;
}
.navigation-wrapper {
  position: relative;
  width: 100%;
}

.dots {
  display: flex;
  padding: 10px 0;
  justify-content: center;
}

.dot {
  border: none;
  width: 10px;
  height: 10px;
  background: #D9D9D9;
  border-radius: 50%;
  margin: 0 5px;
  /* margin-top:10px; */
  padding: 5px;
  cursor: pointer;
}

.dot:focus {
  outline: none;
}

.dot.active {
  background: ${Colors.primary};
}
.arrow {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  /* fill: ${Colors.primary}; */
  cursor: pointer;
}

.arrow--left {
  left: 0px;
}

.arrow--right {
  left: auto;
  right: 0px;
}

.arrow--disabled {
  /* fill: ${Colors.primary}; */
  opacity:0.5;
}
h1, h2, h3, h4, h5 {

	font-family: 'Montserrat', sans-serif !important;
}


p{
	font-family: 'Georgia', serif !important;
}
.flutter-btn {
	background:transparent;
}

`
export const Img = styled.img`
  width: ${({ w }) => (w ? w : null)};
  height: ${({ h }) => (h ? h : null)};
  margin: ${({ m }) => (m ? m : null)};
  margin-left: ${({ ml }) => (ml ? ml : null)};
  padding: ${({ p }) => (p ? p : null)};
  border-radius: ${({ br }) => (br ? br : null)};
  align-self: ${({ align }) => (align ? align : null)};
`
export const MainContainer = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  min-height: calc(100vh - 75px);
  margin-top: 75px;
`

export const AppLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  color: ${({ color }) => (color ? color : '')};
`
export const Div = styled.div`
  width: ${({ width }) => (width ? width : null)};
  height: ${({ height }) => (height ? height : null)};
  margin: ${({ margin }) => (margin ? margin : null)};
  padding: ${({ padding }) => (padding ? padding : null)};
  display: ${({ display }) => display};
  align-items: ${({ alignI }) => alignI};
  flex: ${({ fg }) => (fg ? fg : null)};
  align-self: ${({ align }) => (align ? align : null)};
  margin: ${({ margin }) => (margin ? margin : null)};
  margin-top: ${({ mt }) => (mt ? mt : null)};
  margin-left: ${({ ml }) => (ml ? ml : null)};
  margin-right: ${({ ml }) => (ml ? ml : null)};
  margin-bottom: ${({ mb }) => (mb ? mb : null)};
  display: ${({ display }) => display || null};
  flex-direction: ${({ fd }) => fd || null};
  flex-wrap: ${({ wrap }) => wrap || null};
  justify-content: ${({ justify }) => (justify ? justify : null)};
  background-color: ${({ bc }) => (bc ? bc : null)};
  background-image: ${({ image }) => (image ? `url(${image})` : null)};
  box-shadow: ${({ boxShadow }) => (boxShadow ? boxShadow : null)};
  box-sizing: ${({ boxSizing }) => (boxSizing ? boxSizing : null)};
  background-size: cover;
  cursor: ${({ cursor }) => (cursor ? cursor : null)};
  background-repeat: no-repeat;
  border: ${({ border }) => (border ? border : null)};
  border-radius: ${({ br }) => (br ? br : null)};
  top: ${({ top }) => (top ? top : null)};
  color: ${({ color }) => (color ? color : null)};
  position: ${({ position }) => (position ? position : '')};
`
const GlobalStyles = () => (
  <>
    <FontLoader />
    <GlobalStyle />
  </>
)

export default GlobalStyles
