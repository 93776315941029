import TextInput from "components/TextInput"
import { useFormik } from "formik"
import * as Yup from "yup"
import React, { useContext, useEffect, useState } from "react"
import { InfoText} from "./style"

import { observer } from "mobx-react-lite"
import { addLoanGuarantorOne, getCurrentLoanStage } from "services/LoanService"
import { DASHBOARD_NEXTOFKINROUTE, DASHBOARD_PAYMENT_DETAILS_ROUTE, DASHBOARD_PROFILEROUTE } from "routes"
import { onBoardStep } from "routes/apirRoutes"
import { checkOnBoarding } from "services/MiscService"
import toast from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import { UndergraduateLoanStepContext } from "context/LoanStepContext"
import FooterButtons from "./FooterButtons"

const GuarantorForm = ({ id, mutate }) => {
	const {next} = useContext(UndergraduateLoanStepContext)
	const [loading,setLoading] = useState(false)
	let segment;
	const navigate = useNavigate();
	const [initialValues, setInitialValues] = useState({
        first_name: "",
        middle_name: "",
        surname: "",
        phone: "",
        email: "",
        work_place: "",
        nin: "",
    });

	const formik = useFormik({
        initialValues: initialValues,
        enableReinitialize: true,
        onSubmit: async (data, helpers) => {
            const payload = { ...data }
            payload.id = id ? id: segment;
			setLoading(true);
            const underResponse = await addLoanGuarantorOne(payload)
			setLoading(false);
						console.log("routing")
            if (!underResponse.error) {
                mutate()
				next()
            }
        },

        validationSchema: Yup.object({
            first_name: Yup.string().max(50).required().label("First Name"),
            middle_name: Yup.string().max(50).label("Middle Name"),
            surname: Yup.string().max(50).required().label("Surname"),
            phone: Yup.string().max(100).required().label("Phone No."),
            email: Yup.string().max(255).required().label("Email"),
            work_place: Yup.string().max(255).required().label("Place of Work"),
            nin: Yup.string().length(11).required().label("NIN")
        })
    });
	  const fullUrl = window.location.href;
	  segment = fullUrl.split('/').pop();
      useEffect( ()=>{
		const handleOnBoarded = async () => {
			const info = await checkOnBoarding();
			if(info?.error) {
			  toast.error(info.error);
			}else{
			  // reroute to onboarding page
			  if(!info.onBoarded && info.nextStep === onBoardStep.profile){
				navigate(DASHBOARD_PROFILEROUTE)
			  }
			  else if(!info.onBoarded && info.nextStep === onBoardStep.next_of_kin){
				navigate(DASHBOARD_NEXTOFKINROUTE)
			  }
			  else if(!info.onBoarded && info.nextStep === onBoardStep.bank_account){
				navigate(DASHBOARD_PAYMENT_DETAILS_ROUTE)
			  }
			}
		  }
        handleOnBoarded();
		const currentLoanProgress = async () => {
            const res = await getCurrentLoanStage({ search: "guarantor_one", loanId: segment });
			const {guarantor_one} = res?.data?.data;

            setInitialValues({
                first_name: guarantor_one?.first_name || "",
                middle_name: guarantor_one?.middle_name || "",
                surname: guarantor_one?.surname || "",
                phone: guarantor_one?.phone || "",
                email: guarantor_one?.email || "",
                work_place: guarantor_one?.work_place || "",
                nin: guarantor_one?.nin || "",
            });
        };

        currentLoanProgress();
	}, [segment,navigate]);

	return (
		<>
		<form onSubmit={formik.handleSubmit}>
			<div>
				<div >
					<header className="text-center p-4">
						<InfoText>First Guarantor</InfoText>
					</header>
					<div className="bg-white max-w-xl mx-auto mb-10 p-4 pb-10 rounded-lg">
						<div>
							<TextInput
								astheric={true}
								label="Surname"
								placeholder="Surname"
								name="surname"
								id="surname"
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.surname}
								error={formik.errors.surname}
								visible={formik.touched.surname}
							/>
							<TextInput
								label="Middle Name"
								placeholder="Middle Name"
								name="middle_name"
								id="middle_name"
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.middle_name}
								error={formik.errors.middle_name}
								visible={formik.touched.middle_name}
							/>

							<TextInput
								astheric={true}
								label="First Name"
								placeholder="First Name"
								name="first_name"
								id="first_name"
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.first_name}
								error={formik.errors.first_name}
								visible={formik.touched.first_name}
							/>

							<TextInput
								astheric={true}
								label="Phone No."
								placeholder="Phone No."
								name="phone"
								id="phone"
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.phone}
								error={formik.errors.phone}
								visible={formik.touched.phone}
								type="tel"
							/>
						</div>

						<div>
							
							<TextInput
								astheric={true}
								label="Place of Work"
								placeholder="State Secretariat"
								name="work_place"
								id="work_place"
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.work_place}
								error={formik.errors.work_place}
								visible={formik.touched.work_place}
							/>

							<TextInput
								astheric={true}
								label="Email"
								placeholder="Enter your email"
								type="email"
								name="email"
								id="email"
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.email}
								error={formik.errors.email}
								visible={formik.touched.email}
							/>
							<TextInput
								astheric={true}
								label="NIN"
								placeholder="Enter your NIN"
								type="number"
								name="nin"
								id="nin"
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.nin}
								error={formik.errors.nin}
								visible={formik.touched.nin}
							/>
						</div>
					</div>
                    {/* <ProceedBtn onClick={prev}>Back</ProceedBtn>
					<ProceedBtn type="submit" disabled={loanStore.isLoading}>
						{loanStore.isLoading ? "Loading..." : "Proceed"}
					</ProceedBtn> */}
				</div>
			</div>
			<FooterButtons loading={loading}/>
		</form>
		</>
	)
}

export default observer(GuarantorForm)
