import { useNavigate } from "react-router-dom"
import TextInput from "components/TextInput"
import { useFormik } from "formik"
import * as Yup from "yup"
import { Div, Img } from "globalStyles"
import { observer } from "mobx-react-lite"
import authStore from "mobx/AuthStore"
import React, { useEffect} from "react"
import { SIGNUP_ROUTE, DASHBOARD_ROUTE, FORGOT_PASSWORD_ROUTE, DASHBOARD_ONBOARDING_ROUTE } from "routes"
import { AppLogo2 } from "utils/assets"

import { AuthBottomLink, AuthButton, AuthHeader } from ".."
import { AuthCard, Authcontainer, AuthContent } from "../style"
import { LoginContainer, LoginDiv, LoginFormContainer } from "./style"
import { loginUser } from "services/AuthService"
import NextOfKinPage from "components/Dashboard/Apply/NextOfKinPage"

function LoginComponent() {
	// const [key, setKey] = useState("personal")
	const navigate = useNavigate()
	const formik = useFormik({
		enableReinitialize: false,
		initialValues: {
			email: "",
			password: ""
		},
		onSubmit: async (data, helpers) => {
			// authStore.loading()
			const response = await loginUser(data)
			const onboarding = response?.data?.data;
			if (!response.error) {
				helpers.resetForm()
				// START ONBOARDING PROCESS
				if(!onboarding.profile_completed){
					navigate(DASHBOARD_ONBOARDING_ROUTE);
				}else if (!onboarding.next_of_kin.surname ||onboarding.next_of_kin.surname === " " || onboarding.next_of_kin.first_name === " "){
					// setKey("kin");
					<NextOfKinPage loanType = 'user' user = {onboarding}/>
					navigate(DASHBOARD_ONBOARDING_ROUTE);
					
				}else{
					navigate(`${DASHBOARD_ROUTE}`)
				}
			}
			if (response.error && response.data === "pending") {
				navigate(`${SIGNUP_ROUTE}`)
			}
		},
		validationSchema: Yup.object({
			email: Yup.string().max(255).email().required().label("Email"),
			password: Yup.string().min(8).required("Password is required.").label("Password")
		})
	})

	useEffect(() => {
		localStorage.removeItem("auth-state");
		const status = authStore.loggedInStatus()
		if (status) {
			navigate(`${DASHBOARD_ROUTE}`)
		}
	}, [navigate])

	return (
		<Authcontainer>
			<AuthContent>
				<Img src={AppLogo2} alt="BSL" />
				<LoginContainer>
					<LoginDiv onSubmit={formik.handleSubmit}>
						<AuthCard>
							<LoginFormContainer>
								<AuthHeader text="Log In" />
								<TextInput
									label="Email"
									name="email"
									id="email"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.email}
									error={formik.errors.email}
									visible={formik.touched.email}
									placeholder="e.g john@domain.com"
									mt="0.5rem"
								/>
								<TextInput
									label="Password"
									type="password"
									name="password"
									id="password"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.password}
									error={formik.errors.password}
									visible={formik.touched.password}
									placeholder="Enter your password"
								/>
								<Div width="100%" display="flex" justify="flex-end">
									<AuthBottomLink link={FORGOT_PASSWORD_ROUTE} text="" linktext="Forgot Password" />
								</Div>
								<AuthButton title={authStore.isLoading ? "Loading..." : "Login"} />
								<AuthBottomLink link={SIGNUP_ROUTE} text="Don't have an account?" linktext="Create Account" />
							</LoginFormContainer>
						</AuthCard>
					</LoginDiv>
				</LoginContainer>
			</AuthContent>
		</Authcontainer>
	)
}

export default observer(LoginComponent)
