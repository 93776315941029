import SingleNewsBlog from 'components/BlogComponent/SingleNewsBlog'
import MainLayout from 'layout/MainLayout'
import React from 'react'

function SingleNewsBlogPage() {
  return (
    <MainLayout>
    <SingleNewsBlog />
    </MainLayout>
  )
}

export default SingleNewsBlogPage