import FaqComponent from "components/FAQS"
import Mainlayout from "layout/MainLayout"
import React from "react"

const FaqPage = () => {
	return (
		<Mainlayout>
			<FaqComponent />
		</Mainlayout>
	)
}

export default FaqPage
