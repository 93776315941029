import {ReviewDetailSection, ReviewField, ReviewRow,ReviewFieldTitle, ReviewFieldValue } from "../style";

export default function InstitutionDetailSection({institutionDetail,loanType}){
    return (
        <ReviewDetailSection>
                <h4 style={{fontSize:"24px",marginBottom:"16px"}}>Institution Information</h4>
                <ReviewRow>
                   <ReviewField>
                        <div className="field">
                            <ReviewFieldTitle>Name of Institution</ReviewFieldTitle>
                            <ReviewFieldValue >{institutionDetail.name}</ReviewFieldValue>
                        </div>
                   </ReviewField>
                   <ReviewField>
                        <div className="field">
                            <ReviewFieldTitle>Course of Study</ReviewFieldTitle>
                            <ReviewFieldValue >{institutionDetail.course}</ReviewFieldValue>
                        </div>
                   </ReviewField>
                   
                </ReviewRow>
                <ReviewRow>
                    <ReviewField>
                        <div className="field">
                            <ReviewFieldTitle>Faculty</ReviewFieldTitle>
                            <ReviewFieldValue >{institutionDetail.faculty}</ReviewFieldValue>
                        </div>
                    </ReviewField>
                    <ReviewField>
                        <div className="field">
                            <ReviewFieldTitle>Mat no</ReviewFieldTitle>
                            <ReviewFieldValue >{institutionDetail.matric_number}</ReviewFieldValue>
                        </div>
                    </ReviewField>
                </ReviewRow>
                <ReviewRow>
                    <ReviewField>
                        <div className="field">
                            <ReviewFieldTitle> {loanType === "postgraduate"?"Programme":"Year of Study"}</ReviewFieldTitle>
                            <ReviewFieldValue >{institutionDetail.current_study_year}</ReviewFieldValue>
                        </div>
                    </ReviewField>
                    <ReviewField>
                        <div className="field">
                            <ReviewFieldTitle>Year of Graduation</ReviewFieldTitle>
                            <ReviewFieldValue >{institutionDetail.graduation_year}</ReviewFieldValue>
                        </div>
                    </ReviewField>
                </ReviewRow>
            </ReviewDetailSection>
    )
}