import React from "react"
import { FormsModal, ViewDetailBtn } from "../style"
import { LoanReqDiv } from "./style"
import { Div } from "globalStyles"
import CloseFormBtn from "./CloseBtn"
import { GuarantorPdf } from "utils/assets"

function LoanRequirements({ show, OnClose }) {
	return (
		<FormsModal show={show}>
			<LoanReqDiv>
				<Div display="flex" width="100%" justify="space-between">
					<ViewDetailBtn>
						<a style={{ color: "#FFF" }} href={GuarantorPdf} download>
							Download Guarantor Form
						</a>{" "}
					</ViewDetailBtn>
					<CloseFormBtn onClick={OnClose} />
				</Div>
				<h3>Requirements for BYSHELB loan</h3>
				<p>You will be required to upload the following;</p>
				<br />
				<h4>Student Information:</h4>
				<ul>
					<li>You are required to upload your scanned Admission Letter in image format (JPG, JPEG, PNG)</li>
					<li>You are required to upload your scanned School Identity Card in image format (JPG, JPEG, PNG)</li>
					<li>You are required to upload your scanned Signed Letter of Identification from Registrar or Dean of Students in image format (JPG, JPEG, PNG)</li>
					<li>You are required to upload a scanned copy of your Previous School Fee Payment Receipt in the case of a returning applicant.</li>
				</ul>
				<h4>Guarantor Information:</h4>
				<ul>
					<li>You are required to provide information for two guarantors.</li>
					<li>You are required to fill in their basic details in the required fields on the form.</li>
					<li> Kindly find the "download guarantor form" link on this screen and click on it to download a blank form.</li>
					<li> You are required to print two copies of the form, take to your two guarantors for them to fill. </li>
					<li> After they fill and sign, scan the documents in PDF format and upload on the Guarantors' upload page.</li>
				</ul>
				<ViewDetailBtn>
					<a style={{ color: "#FFF" }} href={GuarantorPdf} download>
						Download Guarantor Form
					</a>{" "}
				</ViewDetailBtn>
				<br />
				<br />
				<h4>Processing Fee:</h4>
				<ul>
					<li>Please note that the application for the BYSHELB loan is free. However, you are required to pay the sum of NGN2,000 to enable us process your loan.</li>
				</ul>

				<ViewDetailBtn>
					<a style={{ color: "#FFF" }} href={GuarantorPdf} download>
						Download Guarantor Form
					</a>{" "}
				</ViewDetailBtn>
			</LoanReqDiv>
		</FormsModal>
	)
}

export default LoanRequirements
