import styled from "@emotion/styled";
import Colors from "utils/colors";
const themeColor = "#6d0062";

export const Step = styled.div`
    background:${props=>props.active?themeColor:Colors.muted};
    color:${props=>props.active?"white":Colors.grey[600]};
    width:30px;
    height:30px;
    border-radius:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    font-weight:600;
    cursor:pointer
`;

export const Line = styled.div`
    flex:1,
    height:10px;
    width:50px;
    background-color:black
`;
