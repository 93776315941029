import { useState } from "react";
import useUserLoans from "./useUserLoans";
import { Checkbox, message } from "antd";
import { Link } from "react-router-dom";
import { DASHBOARD_APPLY_ROUTE } from "routes";
import { deleteLoan, deleteLoans } from "services/LoanService";


function getStatusColor(status){
    if(status === "rejected") return "bg-red-100 text-red-600 border px-6 py-2 rounded-full border-solid border-red-400"
    if(status === "approved") return "bg-green-100 text-green-600 px-6 py-2 rounded-full border border-solid border-green-400"
    if(status === "disbursed") return "bg-blue-100 text-blue-600 px-6 py-2 rounded-full border border-solid border-blue-400"
    return "bg-gray-100 text-gray-600 border border-solid px-6 py-2 rounded-full border-gray-400"
}

export default function useDrafts(){
    const {loading,userLoans,getDrafts} = useUserLoans()
    const [selected,setSelected] = useState([]);
    const [selectedLoanId,setSelectedLoanId] = useState(null)
    const [deleteLoading,setDeleteLoading] = useState(false)
    const [deleteAllLoading,setDeleteAllLoading] = useState(false)
    const [open,setOpen] = useState(false)
    const [deleteAllOpen,setDeleteAllOpen] = useState(false)
    const incompleteLoans = userLoans?userLoans.filter(loan=>loan.loan_step && loan.status.toLowerCase() === "pending"):[]


    const handleSelect = (id)=>{
        if(selected.includes(id)){
            setSelected(selected.filter(loanId=>loanId !== id));
            return;
        }
        setSelected(prev=>[
            ...prev,
            id
        ])
    }

    const handleSelectAll = ()=>{
        const allOptions = incompleteLoans.map(loan=>loan.id)
        if(incompleteLoans.length === selected.length){
            setSelected([])
            return;
        }
        setSelected(allOptions)
    }
    const openDeleteModal = (id)=>{
        return ()=>{
            setSelectedLoanId(id)
            setOpen(true)
        }
    }
    const openDeleteAllModal = ()=>{
        if(selected.length === 0){
            message.info("Please select atleast one application to delete")
            return;
        }
        setDeleteAllOpen(true)
    }

    const closeModal = ()=>{
        setOpen(false)
    } 
    const closeAllDeleteModal = ()=>{
        setDeleteAllOpen(false)
    }

    const handleDelete = async()=>{
        console.log(selectedLoanId)
        setDeleteLoading(true)
        const {status,error_message} = await deleteLoan(selectedLoanId)
        setDeleteLoading(false)
        closeModal()
        if(status === "failed"){
            message.error(error_message)
            return;
        }
        message.success("Loan Deleted Successfully")
        getDrafts()
    }

    const handleDeleteAll = async()=>{
        console.log(selectedLoanId)
        setDeleteAllLoading(true)
        const {status,error_message} = await deleteLoans(selected)
        setDeleteAllLoading(false)
        closeAllDeleteModal()
        if(status === "failed"){
            message.error(error_message)
            return;
        }
        message.success("Loans Deleted Successfully")
        setSelected([])
        getDrafts()
        
    }


    const columns = [
        {
            title: '',
            key:"checked",
            render:(data)=>{
                return(
                    <Checkbox checked={selected.includes(data.id)} onClick={()=>handleSelect(data.id)}/>
                )
            }
        },
        {
            title: 'Application Date',
            render:(data)=>{
                const date = new Date(data.created_at)
                // const application_date = DateTime.fromFormat(data.created_at,DateTime.DATETIME_MED_WITH_SECONDS).toLocaleString()
                // console.log({
                //     date,
                //     application_date
                // })
                return (
                    <p className="font-poppins">{date.toDateString()}</p>
                )
            },
            key: 'application_date',
        },
        {
            title: 'Amount (₦)',
            render: (data)=>data.amount?data.amount.toLocaleString():"0.00",
            key: 'address',
        },
        {
            title: 'Action',
            render:(data)=>{
                if(data.loan_step && data.status.toLowerCase() === "pending"){
                    return(
                        <Link to={`${DASHBOARD_APPLY_ROUTE}/${data.loan_type}/${data.id}`}>
                            <button
                            className="bg-secondary border rounded-full px-6 py-2 bg-secondary-50"
                            >
                                Continue
                            </button>
                        </Link>
                    )
                }
                return(
                    <Link to={`${DASHBOARD_APPLY_ROUTE}/details/${data.id}`}>
                        <button className="bg-secondary border rounded-full px-6 py-2 bg-secondary-50">
                            View
                        </button>
                    </Link>
                )
            },
            key: 'action',
        },
        {
            title: '',
            key:"delete",
            render:(loan)=>{
                if(loan.loan_step && loan.status.toLowerCase() === "pending")
                return(
                    <button onClick={openDeleteModal(loan.id)}><i className="bi bi-trash text-red-600 text-lg font-bold"></i></button>
                )
            }
        },
  ];
  const columnsAllApplications = [
        {
            title: 'Application Date',
            render:(data)=>{
                const date = new Date(data.created_at)
                // const application_date = DateTime.fromFormat(data.created_at,DateTime.DATETIME_MED_WITH_SECONDS).toLocaleString()
                // console.log({
                //     date,
                //     application_date
                // })
                return (
                    <p className="font-poppins">{date.toDateString()}</p>
                )
            },
            key: 'application_date',
        },
        {
            title: 'Amount (₦)',
            render: (data)=>data.amount?data.amount.toLocaleString():"0.00",
            key: 'address',
        },
        {
            title: 'Status',
            render: (data)=>{
                const className = getStatusColor(data.status)
                return (
                    <button className={`capitalize font-poppins ${className}`}>{data.status}</button>
                )
            },
            key: 'status',
        },
        {
            title: 'Action',
            render:(data)=>{
                if(data.loan_step && data.status.toLowerCase() === "pending"){
                    return(
                        <Link to={`${DASHBOARD_APPLY_ROUTE}/${data.loan_type}/${data.id}`}>
                            <button
                            className="bg-secondary border rounded-full px-6 py-2 bg-secondary-50"
                            >
                                Continue
                            </button>
                        </Link>
                    )
                }
                return(
                    <Link to={`${DASHBOARD_APPLY_ROUTE}/details/${data.id}`}>
                        <button className="bg-secondary border rounded-full px-6 py-2 bg-secondary-50">
                            View
                        </button>
                    </Link>
                )
            },
            key: 'action',
        },
        {
            title: '',
            key:"delete",
            render:(loan)=>{
                if(loan.loan_step && loan.status.toLowerCase() === "pending")
                return(
                    <button onClick={openDeleteModal(loan.id)}><i className="bi bi-trash text-red-600 text-lg font-bold"></i></button>
                )
            }
        },
  ];

  return{
    columns,
    userLoans,
    incompleteLoans,
    open,
    deleteAllOpen,
    loading,
    selected,
    deleteLoading,
    deleteAllLoading,
    closeAllDeleteModal,
    closeModal,
    handleDelete,
    handleDeleteAll,
    openDeleteAllModal,
    handleSelectAll,
    columnsAllApplications,
  }
}