import styled from "styled-components/macro"
import media from "utils/media"

export const LoginContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
`
export const LoginDiv = styled.form`
	width: 30%;
	display: flex;
	flex-direction: column;
	align-items: center;
	${media.mobile`
	width:90%;
	`}
`
export const LoginFormContainer = styled.div`
	width: 82%;
	display: flex;
	flex-direction: column;
	${media.mobile`
	
	width:100%;
	`}
`
