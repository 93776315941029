import DashboardHome from "components/Dashboard/Home"
// import { MainContainer } from "globalStyles"
import AuthLayout from "layout/AuthLayout"
// import Mainlayout from "layout/MainLayout"
import React from "react"

const DashboardPage = () => {
	return (
		<AuthLayout>
			{/* <MainContainer> */}
			<DashboardHome />
			{/* </MainContainer> */}
		</AuthLayout>
	)
}

export default DashboardPage
