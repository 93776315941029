import TextInput from 'components/TextInput'
import { useFormik } from 'formik'
import * as Yup from 'yup'
// import { Div } from 'globalStyles'
import React, { useEffect, useState } from 'react'
// import { NextOfKinImg } from 'utils/assets'
import {
  // InfoContainer,
  // InfoContent,
  // InfoContentLeft,
  // InfoContentRight,
  InfoText,
  // InputDiv,
  // InputWrap,
  // ProceedBtn,
} from './style'
import CustomSelectMain from 'layout/SelectInput'
import { nextOfKinRelData } from './nextOfKinRelData'
import { observer } from 'mobx-react-lite'
// import { addNextOfKinUnder } from 'services/LoanService'
import loanStore from 'mobx/LoanStore'
import { updateNextOfKinUser } from 'services/UserService'
// import { useNavigate } from 'react-router-dom'
// import { DASHBOARD_PAYMENT_DETAILS_ROUTE, DASHBOARD_PROFILEROUTE, DASHBOARD_ROUTE } from 'routes'
import { checkOnBoarding } from 'services/MiscService'
import toast from 'react-hot-toast'
// import { onBoardStep } from 'routes/apirRoutes'
import { Spin } from 'antd'
// { loanType = '', user }
const NextOfKinPage = ({next,prev,currentStep}) => {

  // const navigate = useNavigate();
const [user, setUser] = useState({});
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name: user?.next_of_kin?.first_name
        ? user?.next_of_kin?.first_name
        : '',
      middle_name: user?.next_of_kin?.middle_name
        ? user?.next_of_kin?.middle_name
        : '',
      surname: user?.next_of_kin?.surname ? user?.next_of_kin?.surname : '',
      relationship: user?.next_of_kin?.relationship
        ? user?.next_of_kin?.relationship
        : '',
      phone: user?.next_of_kin?.phone ? user?.next_of_kin?.phone : '',
      address: user?.next_of_kin?.address ? user?.next_of_kin?.address : '',
    },

    onSubmit: async (data, helpers) => {
      // if (loanType === 'undergraduate') {
      //   const payload = { ...data }
        // const underResponse = await addNextOfKinUnder(payload)
      //   if (!underResponse.error) {
      //     if (!user?.bank_account?.account_name) {
      //       navigate(`${DASHBOARD_PAYMENT_DETAILS_ROUTE}`)
      //     }
      //     if (user?.bank_account?.account_name) {
      //       navigate(`${DASHBOARD_ROUTE}`)
      //     }
      //   }
      // }
      // if (loanType === 'user') {
        const payload = { ...data }
        const userResponse = await updateNextOfKinUser(payload)
       
        if (!userResponse.error) {
          // navigate(`${DASHBOARD_ROUTE}`)
          next()
        }
      // }
    },
    

    validationSchema: Yup.object({
      first_name: Yup.string().max(50).required().label('First Name'),
      middle_name: Yup.string().max(50).label('Middle Name'),
      surname: Yup.string().max(50).required().label('Surname'),
      phone: Yup.string().max(100).required().label('Phone No.'),
      address: Yup.string().max(255).required().label('Address'),
      relationship: Yup.string()
        .max(150)
        .required()
        .label('Relationship With Applicant'),
    }),
  })
  	
	const handleOnBoarded = async () => {
		const info = await checkOnBoarding();
		if(info?.error) {
		  toast.error(info.error);
		}else{
		  // reroute to onboarding page
      setUser(info.data);
		  // if(!info.onBoarded && info.nextStep === onBoardStep.profile){
			// navigate(DASHBOARD_PROFILEROUTE)
		  // }
		  // else if(!info.onBoarded && info.nextStep === onBoardStep.bank_account){
			// navigate(DASHBOARD_PAYMENT_DETAILS_ROUTE)
		  // }
		  }
	  }
	  
	  useEffect(()=>{
		handleOnBoarded();
		});
  return (
    <form className="p-4" onSubmit={formik.handleSubmit}>
        <div div className="max-w-[850px] mx-auto">
          <InfoText>Next of Kin Information</InfoText>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <TextInput
                astheric={true}
                label="Surname"
                placeholder="Surname"
                name="surname"
                id="surname"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.surname}
                error={formik.errors.surname}
                visible={formik.touched.surname}
                mt="0.5rem"
              />
              <TextInput
                label="Middle Name"
                placeholder="Middle Name"
                name="middle_name"
                id="middle_name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.middle_name}
                error={formik.errors.middle_name}
                visible={formik.touched.middle_name}
              />
              <CustomSelectMain
                astheric={true}
                label="Relationship With Applicant"
                placeholder="Husband"
                name="relationship"
                id="relationship"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.relationship}
                error={formik.errors.relationship}
                visible={formik.touched.relationship}
              >
                <option style={{ color: 'grey' }} value="">
                  --------
                </option>
                {nextOfKinRelData?.map((local) => (
                  <option
                    className="active-option"
                    key={local.id}
                    value={local.value}
                  >
                    {local.label}
                  </option>
                ))}
              </CustomSelectMain>
            </div>

            <div>
              <TextInput
                astheric={true}
                label="First Name"
                placeholder="First Name"
                name="first_name"
                id="first_name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.first_name}
                error={formik.errors.first_name}
                visible={formik.touched.first_name}
              />
              <TextInput
                astheric={true}
                label="Phone No."
                placeholder="Phone No."
                name="phone"
                id="phone"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phone}
                error={formik.errors.phone}
                visible={formik.touched.phone}
                type="tel"
              />
              <TextInput
                astheric={true}
                label="Address"
                placeholder="Address"
                name="address"
                id="address"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.address}
                error={formik.errors.address}
                visible={formik.touched.address}
              />

            </div>
          </div>
          <footer className="sticky mt-6 flex items-center justify-between border-t border-solid border-gray-300 bottom-0 bg-white py-4">
          {currentStep !==0 && (
            <button onClick={prev} disabled={loanStore.isLoading} className={"disabled:opacity-50 bg-primary-100 border border-solid border-primary-500 px-8 rounded-3xl py-2 disabled:cursor-not-allowed"} type="submit">
              Previous
            </button> 
          )}
          <button disabled={loanStore.isLoading} className={"disabled:opacity-50 bg-primary-100 border border-solid border-primary-500 px-8 rounded-3xl py-2 disabled:cursor-not-allowed"} type="submit">
            {loanStore.isLoading ?<Spin/> : 'Proceed'}
          </button>
       </footer>
          {/* <Div display="flex" width="100%" justify="center" mb="2rem">
            <ProceedBtn type="submit" disabled={loanStore.isLoading}>
              {loanStore.isLoading
                ? 'Saving...'
                : 'Save'}
            </ProceedBtn>
          </Div> */}
        </div>
        {/* <InfoContentRight>
          <Img src={NextOfKinImg} alt="image" />
        </InfoContentRight> */}
      </form>
  )
}

export default observer(NextOfKinPage)
