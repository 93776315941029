import { Img } from "globalStyles"
import React from "react"
import boardData from "./boardData"
import { BoardCard, BoardCardImage, BoardContainer, BoardDescText, BoardDiv, BoardHeaderText } from "./boardStyle"

function Board() {
	return (
		<BoardContainer>
			<div>
				<BoardHeaderText>Meet the Executive Board</BoardHeaderText>
				<BoardDescText>A dedicated team committed to extraordinary results</BoardDescText>
			</div>
			<BoardDiv>
				{boardData?.map(member => (
					<BoardCard key={member.id}>
						<BoardCardImage>{member.imgUrl ? <Img src={member.imgUrl} alt={member.name} /> : null}</BoardCardImage>
						<p className="title-board">{member.title}</p>
						<p className="title-board">{member.name}</p>
						<p className="role-board">{member.role}</p>
					</BoardCard>
				))}
			</BoardDiv>
		</BoardContainer>
	)
}

export default Board
