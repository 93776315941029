import { amas, anderson, ebi, prof } from "utils/assets"

const boardData = [
	{
		id: 4,
		name: "Saviour Nathan Agoro",
		title: "Prof",
		role: "Chairman",
		imgUrl: prof
	},
	{
		id: 2,
		name: "Michael Amaegberi",
		title: "Dr.",
		role: "Executive Secretary",
		imgUrl: amas
	},
	{
		id: 1,
		name: " Ebipatei Apaingolo",
		title: "Hon.",
		role: "Director Evaluation and Monitoring",
		imgUrl: ebi
	},
	{
		id: 3,
		name: "Engr Felix Sam-Ateki",
		title: "Hon",
		role: "Director, Control and Compliance",
		imgUrl: anderson
	}
	
]

export default boardData
