import * as Yup from 'yup'
import CustomSelectMain from 'layout/SelectInput'
import { useFormik } from 'formik'
import TextInput from 'components/TextInput'
import React, { useContext, useEffect, useState } from 'react'
import certData  from './certData'
import {
  InfoText,
} from './style'
import { observer } from 'mobx-react-lite'
import { addInstitutionUnder } from 'services/LoanService'
import { useNavigate } from 'react-router-dom'
import { uniData } from './uniData'
import LoanRequirements from './Requirements'
import { BASE_URL } from 'services'
import http from 'services/httpService'
import useSWR from 'swr'
import FullScreenLoader from 'components/FullScreenLoader'
import { DASHBOARD_NEXTOFKINROUTE, DASHBOARD_PAYMENT_DETAILS_ROUTE, DASHBOARD_PROFILEROUTE, DASHBOARD_ROUTE } from 'routes'
import toast from 'react-hot-toast'
import { checkOnBoarding } from 'services/MiscService'
import { onBoardStep } from 'routes/apirRoutes'
import useInstitutionForm from './hooks/useInstitutionForm'
import { PostGradLoanStepContext } from 'context/PostLoanStepContext'
import { Spin } from 'antd'

const programmes = [
  {value:"PGD",label:"PGD",id:"PGD"},
  {value:"M.SC",label:"M.SC",id:"M.SC"},
  {value:"M.A",label:"M.A",id:"M.A"},
  {value:"M.B.A",label:"M.B.A",id:"M.B.A"},
  {value:"PhD",label:"PhD",id:"PhD"},
]
const PostGradInstitutionForm = ({ loanType="postgraduate", mutate}) => {
  const {next} = useContext(PostGradLoanStepContext)
  let segment;
  const fullUrl = window.location.href;
  segment = fullUrl.split('/').pop();

  console.log(segment);

  const {formValues,handleInput} = useInstitutionForm(segment)
  const [loading,setLoading] = useState(false)

  const [show, setShow] = useState(false)
  const navigate = useNavigate()

  const url = `${BASE_URL}/loans/user`
  const fetcher = async () => {
    const res = await http.get(url)
    // console.log("Fetcher One ", res)
    return res?.data?.data
  }

  const { data: loanAll } = useSWR(url, fetcher)
  const loans = loanAll ? loanAll : []

  const formik = useFormik({
    initialValues:{...formValues,graduation_year:"01-2022"},
    onSubmit: async (data) => {
        const payload = { ...formValues,id:segment }
        payload.loan_type = 'postgraduate'
        payload.request_status =
        loans.length > 1 ? 're-application' : 'first-time'

        setLoading(true)
        const postResponse = await addInstitutionUnder(payload)
        setLoading(false)

        if (postResponse.data.id) {
            next()
        }
    },

    validationSchema: Yup.object({
      name: Yup.string().max(350).required().label('Institution Name'),
      course: Yup.string().max(50).required().label('Course'),
      faculty: Yup.string().max(50).required().label('Faculty'),
      matric_number: Yup.string().max(150).label('Matric Number'),
      study_year: Yup.string().max(100).required().label('Study Year'),
      graduation_year: Yup.string().max(255).required().label('Admission Year'),
      othercertificate: Yup.string().when('certificate', {
        is: 'Others',
        then: Yup.string().required().label('Other Cert'),
      }),
      othername: Yup.string().when('name', {
        is: 'Others',
        then: Yup.string().required().label('Enter Institution'),
      }),
    }),
  })


  function toggleRequirements() {
    setShow(!show)
  }

      const handleOnBoarded = async () => {
        const info = await checkOnBoarding();
        if(info?.error) {
          navigate(DASHBOARD_ROUTE)
          toast.error(info.error);
        }else{
          // reroute to onboarding page
          if(!info.onBoarded && info.nextStep === onBoardStep.profile){
            navigate(DASHBOARD_PROFILEROUTE)
          }
          else if(!info.onBoarded && info.nextStep === onBoardStep.next_of_kin){
            navigate(DASHBOARD_NEXTOFKINROUTE)
          }
          else if(!info.onBoarded && info.nextStep === onBoardStep.bank_account){
            navigate(DASHBOARD_PAYMENT_DETAILS_ROUTE)
          }
        }
      }

    useEffect( ()=>{
      handleOnBoarded();
    });

  if (!loanAll) return <FullScreenLoader show={true} />
  return (
    <form className="" onSubmit={formik.handleSubmit}>
    <header className="sticky z-[99] top-0 mb-4 bg-gray-100">
      <InfoText className="text-center">Institution Information</InfoText>
    </header>
    <div className="max-w-xl mb-6 bg-white g-white rounded-lg p-4 mx-auto">
      <div>
        {show && (
          <LoanRequirements OnClose={toggleRequirements} show={show} />
        )}
        <div className="grid gap-4">
          <div>
            <div className="mb-2">
              <CustomSelectMain
                // mt="1.5rem"
                astheric={true}
                label={'Name of Institution'}
                name="name"
                value={formValues.name}
                onBlur={formik.handleBlur}
                onChange={handleInput}
                error={formik.errors.name}
                visible={formik.touched.name}
              >
                <option style={{ color: 'grey' }} value="">
                  Select Institution
                </option>
                {uniData?.map((uni) => (
                  <option
                    className="active-option"
                    key={uni.id}
                    value={uni.value}
                  >
                    {uni.label}
                  </option>
                ))}
              </CustomSelectMain>
            </div>
            {formValues.name.toLowerCase() === 'others' && (
              <TextInput
                astheric={true}
                label="Specify"
                type="text"
                placeholder="Name of Institution"
                name="othername"
                id="othername"
                onChange={handleInput}
                onBlur={formik.handleBlur}
                value={formValues.othername}
                error={formik.errors.othername}
                visible={formik.touched.othername}
              />
            )}
              <TextInput
              astheric={true}
              label="Course Of Study"
              placeholder="Chemistry"
              name="course"
              id="course"
              onChange={handleInput}
              onBlur={formik.handleBlur}
              value={formValues.course}
              error={formik.errors.course}
              visible={formik.touched.course}
            />
            <TextInput
              astheric={true}
              label="Faculty"
              placeholder="Science"
              name="faculty"
              id="faculty"
              onChange={handleInput}
              onBlur={formik.handleBlur}
              value={formValues.faculty}
              error={formik.errors.faculty}
              visible={formik.touched.faculty}
            />
            <TextInput
              label="Mat. No"
              placeholder="Mat. No"
              name="matric_number"
              id="matric_number"
              onChange={handleInput}
              onBlur={formik.handleBlur}
              value={formValues.matric_number}
              error={formik.errors.matric_number}
              visible={formik.touched.matric_number}
            />
            <TextInput
              astheric={true}
              label="Year of Graduation"
              type="month"
              placeholder="Year of Graduation"
              name="graduation_year"
              id="graduation_year"
              onChange={handleInput}
              onBlur={formik.handleBlur}
              value={formValues.graduation_year}
              error={formik.errors.graduation_year}
              visible={formik.touched.graduation_year}
            />
            <CustomSelectMain
              mt="1.5rem"
              astheric={true}
              label={loanType==="undergraduate"?'Current Year Of Study':"Programme"}
              name="study_year"
              value={formValues.study_year}
              onBlur={formik.handleBlur}
              onChange={handleInput}
              error={formik.errors.study_year}
              visible={formik.touched.study_year}
            >
              <option style={{ color: 'grey' }} value="">
                {loanType==="undergraduate"?'Select Current Year Of Study':"Select Programme"}
              </option>
              {programmes.map((local) => (
                <option
                  className="active-option"
                  key={local.id}
                  value={local.value}
                >
                  {local.label}
                </option>
              ))}
            </CustomSelectMain>

            <CustomSelectMain
              mt="-0.5rem"
              astheric={true}
              label={'Certificate In View'}
              name="certificate"
              value={formValues.certificate}
              onBlur={formik.handleBlur}
              onChange={handleInput}
              error={formik.errors.certificate}
              visible={formik.touched.certificate}
            >
              <option style={{ color: 'grey' }} value="">
                Select Certificate
              </option>
              {certData?.map((local) => (
                <option
                  className="active-option"
                  key={local.id}
                  value={local.value}
                >
                  {local.label}
                </option>
              ))}
            </CustomSelectMain>
            {formValues.certificate === 'others' && (
              <TextInput
                astheric={true}
                label="Specify"
                type="text"
                placeholder="ICAN, HSE etc"
                name="othercertificate"
                id="othercertificate"
                onChange={handleInput}
                onBlur={formik.handleBlur}
                value={formValues.othercertificate}
                error={formik.errors.othercertificate}
                visible={formik.touched.othercertificate}
              />
            )}
          </div>

        </div>

      </div>
      {/* <InfoContentRight>
        <Img src={BankImg} alt="Personal infographics" />
      </InfoContentRight> */}
    </div>
    <footer className="flex py-2 px-4 pt-4 border-t border-gray-200 justify-end border-solid sticky bottom-0 bg-gray-50">
      <button className="bg-secondary-50 border border-solid border-secondary-500 rounded-full py-2 text-secondary-500 disabled:opacity-60 disabled:cursor-not-allowed  px-6" type="submit" disabled={loading}>
        {loading
        ?<Spin/>:
        <span>Proceed</span>}
      </button>
    </footer>
  </form>
  )
}

export default observer(PostGradInstitutionForm)
