// import { useFormik } from "formik"
// import * as Yup from "yup"
import { Img } from "globalStyles"
import React from "react"
import { GuarantorImage } from "utils/assets"
import { InfoContainer, InfoContent, InfoContentLeft, InfoContentRight, InfoText, InputDiv, InputWrap, ProceedBtn } from "./style"
import { observer } from "mobx-react-lite"
import { addFilesGuarantor } from "services/LoanService"

import toast from "react-hot-toast"
import loanStore from "mobx/LoanStore"
import { FileUploader } from "react-drag-drop-files"
import FileUploadForm from "./FileUploadForm"
import { useState } from "react"

const GuarantorFormFile = ({ loanType = "", mutate, id }) => {
	const [guarantorone, setGuarantorOne] = useState(null)
	const [guarantortwo, setGuarantorTwo] = useState(null)

	const fileTypes = ["pdf"]

	const handleTypeError = () => {
		toast.error("only scanned pdf are allowed")
	}
	const handleSizeError = file => {
		toast.error("Maximum file size allowed is 50kb")
	}
	function handleChangeGone(file) {
		if (file.length > 0) {
			setGuarantorOne(file)
		} else {
			setGuarantorOne(guarantorone)
		}
	}

	function handleChangeGTwo(file) {
		if (file.length > 0) {
			setGuarantorTwo(file)
		} else {
			setGuarantorTwo(guarantortwo)
		}
	}

	const handleAddGuarantors = async () => {
		if (!guarantorone) return toast.error("Please upload guarantor one")
		if (!guarantortwo) return toast.error("Please upload guarantor two")

		const formData = new FormData()
		formData.append("first", guarantorone[0])
		formData.append("second", guarantortwo[0])
		formData.set("id", id)
		const underResponse = await addFilesGuarantor(formData)
		if (!underResponse.error) {
			mutate()
		}
	}
	return (
		<InfoContainer>
			<InfoContent>
				<InfoContentLeft>
					<InfoText>Guarantor's Documents </InfoText>

					<InputDiv>
						<InputWrap>
							<FileUploader multiple={true} handleChange={handleChangeGone} maxSize={1000} types={fileTypes} onSizeError={handleSizeError} onTypeError={handleTypeError}>
								<FileUploadForm title="Upload Guarantor One (pdf)" indescribe={guarantorone ? `${guarantorone[0]?.name}` : ""} />
							</FileUploader>
						</InputWrap>

						<InputWrap>
							<FileUploader multiple={true} handleChange={handleChangeGTwo} maxSize={1000} types={fileTypes} onSizeError={handleSizeError} onTypeError={handleTypeError}>
								<FileUploadForm title="Upload Guarantor Two (pdf)" indescribe={guarantortwo ? `${guarantortwo[0]?.name}` : ""} />
							</FileUploader>

							{/* <input ref={guarantorIdRef} style={{ display: "none" }} onChange={handleSubmitDp} type="file" accept="image/*" multiple={false} /> */}
						</InputWrap>
					</InputDiv>
					<ProceedBtn type="button" onClick={() => handleAddGuarantors()} disabled={loanStore.isLoading}>
						{loanStore.isLoading ? "Loading..." : "Proceed"}
					</ProceedBtn>
				</InfoContentLeft>
				<InfoContentRight>
					<Img src={GuarantorImage} alt="image" />
				</InfoContentRight>
			</InfoContent>
		</InfoContainer>
	)
}

export default observer(GuarantorFormFile)
