import styled from "styled-components/macro"
import Colors from "utils/colors"
import media from "utils/media"

export const CompleteDiv = styled.div`
	width: 100%;
	height: 80vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	/* background-color: #f90; */
`
export const CompleteDivInner = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 45%;
	text-align: center;
	${media.smallDesktop`
	width: 75%;
	`}
	${media.tablet`
	width: 75%;
	`}
	${media.mobile`
	width: 95%;
	`}
`
export const CompleteHeaderText = styled.h3`
	font-eight: 700;
	font-size: 28px;
	line-eight: 34px;
	color: #000e28;
	margin-bottom: 5px;
`
export const CompleteHeaderTextDec = styled.p`
	font-style: bold;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: ${Colors.primary};
`
export const CompleteHeaderTextSub = styled.span`
	font-style: bold;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: ${Colors.primary};
`

// textV: {
//     fontFamily: "Inter",
//     fontStyle: "bold",
//     fontWeight: 500,
//     fontSize: "16px",
//     lineHeight: "19px",
//     color: "#0C67A0"
// },
export const CompleteTextOr = styled.p`
	color: #778fab;
`

export const CompleteButton = styled.button`
	width: 202px;
	height: 42px;
	background: ${Colors.gradient};
	border-radius: 3px;
	outline: none;
	border: none;
	margin: 10px 0;
	cursor: pointer;
	color: #ffffff;
	:hover {
		background: ${Colors.primary};
	}
`
