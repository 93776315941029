import { useContext } from "react";
import { Step, StepHeaderContainer } from "./components";
import { loanSteps, UndergraduateLoanStepContext } from "context/LoanStepContext";
import { PostGradLoanStepContext } from "context/PostLoanStepContext";


export default function StepsHeader(){
    const {step:currentStep,goToStep} = useContext(UndergraduateLoanStepContext)
    return (
        <StepHeaderContainer>
            {loanSteps.map((step,i) => (
                <Step onClick={()=>goToStep(i)} active={currentStep === i} key={step}>{i + 1}</Step>
            ))}
        </StepHeaderContainer>
    )
}
export function PostStepsHeader(){
    const {step:currentStep,goToStep} = useContext(PostGradLoanStepContext)
    return (
        <div className="flex gap-4 p-4 py-[2em] bg-gray-100 z-[100] sticky top-0 items-center justify-center w-full">
            {loanSteps.map((step,i) => (
                <Step onClick={()=>goToStep(i)} active={currentStep === i} key={step}>{i + 1}</Step>
            ))}
        </div>
    )
}