import FullScreenLoader from "components/FullScreenLoader"
import { LoadingOutlined } from '@ant-design/icons'
import AuthLayout from "layout/AuthLayout"
import { observer } from "mobx-react-lite"
import React, { useContext, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
// import { BASE_URL } from "services"
// import useSWR from "swr"
import { DASHBOARD_APPLY_UNDERGRAD_ROUTE } from "routes"
// import http from "services/httpService"
import { EditLoanStepContext } from "context/EditLoanContext"
import LoanStepsHeader from "./components/StepHeader"
import InstitutionForm from "./components/InstitutionForm"
import LoanDetailsForm from "./components/LoanDetailsForm"
import GuarantorOneForm from "./components/GuarantorOneForm"
import GuarantorTwoForm from "./components/GuarantorTwoForm"
import UploadDocForm from "./components/UploadDocForm"
import { ConfigProvider, message, Modal, Spin } from "antd"
import {  saveEdit } from "services/LoanService"
import useLoanDetail from "../Apply/LoanReview/useLoanDetail"




function EditAlert({loan}){

	const navigate = useNavigate()
	const [loading,setLoading] = useState(false)
	const [open,setOpen] = useState(false)
	const handleSubmit = async () => {
		setLoading(true)
		const res = await saveEdit(loan.id)
		if(res){
			setOpen(false)
			message.success("Loan Edit Completed Successfully")
			return navigate(`/dashboard/apply/details/${loan.id}`);
		}
		setLoading(false)

	}
	const spinnerTheme = {
		token: {
			colorPrimary:"rgb(109,0,98)"
		},
	}
	return (
		<>
			<Modal open={open} footer={null} centered closeIcon={null} closable={false} maskClosable={false} >
				<p className="text-2xl font-poppins text-center font-bold">Confirm Edit Completion</p>
				<p className="text-center font-poppins my-2">Please review your changes carefully and confirm if everything looks correct before you finalize.</p>
				<footer className="flex items-center justify-center gap-8">
						<button type="button" onClick={()=>setOpen(false)} className="my-2 border-solid border-red-400 font-medium px-6 py-2 border bg-red-50 text-red-600 rounded-full">Cancel</button>
						<ConfigProvider theme={spinnerTheme}>
							<button disabled={loading} type="button" onClick={handleSubmit}
								className="my-2 font-medium px-6 py-2 border border-solid border-secondary-400 bg-secondary-50 text-secondary rounded-full"
							>
								{loading
								?<>
									<Spin indicator={<LoadingOutlined color="#ffffff" spin/>} />
									<span className="inline-block ml-2">Continue</span>
								</>
								:<span>Continue</span>}
							</button>
						</ConfigProvider>
				</footer>
			</Modal>
			<div className="max-w-xl mx-auto p-1">
				<div className="bg-white my-2 border border-secondary-300 border-solid  text-[#242424] px-4 py-3 rounded-md">
					<p className="text-xl mt-4 font-poppins font-semibold">Edit Request Information</p>
					<p className="font-poppins my-1">{loan.request_edit_reason}</p>
					<div className="text-end">
						<button type="button" onClick={()=>setOpen(true)} className="my-2 font-medium px-6 py-2 border bg-secondary-800 text-white rounded-md">
							<span>Complete Edit</span>
						</button>
					</div>
				</div>
			</div>
		</>
	)
}

const EditLoan = () => {
	const {step} =  useContext(EditLoanStepContext)
	const navigate = useNavigate()
	let params = useParams()
	// const url = `${BASE_URL}/loans/${params?.id}`

	// const fetcher = async (apiURL) => {
	// 	const res = await http.get(apiURL)
	// 	return res?.data?.data
	// }
	const {loading,loan} = useLoanDetail()

	const mutate = ()=>{}

	const loanForms = [
		<InstitutionForm loanType={"undergraduate"} mutate={mutate} />,
		<LoanDetailsForm  id={params?.id} mutate={mutate} loanType={"undergraduate"} />,
		<GuarantorOneForm id={params?.id} mutate={mutate} loanType={"undergraduate"}/>,
		<GuarantorTwoForm id={params?.id} mutate={mutate} loanType={"undergraduate"}/>,
		<UploadDocForm mutate={mutate} id={params?.id} />,
	]

	if (loading) return <FullScreenLoader show={true} />
	if (params?.id && loan && loan.length <= 0) {
		navigate(`${DASHBOARD_APPLY_UNDERGRAD_ROUTE}`)
	}

	return (
		<AuthLayout>
			<>
			<LoanStepsHeader/>
			{
				loan
				&& loan.edit_requested
				&& <EditAlert loan={loan}/>
			}
			<br />
			{loanForms[step]}
			</>
		</AuthLayout>
	)
}

export default observer(EditLoan)
