export const HOME_ROUTE = "/"
export const LOGIN_ROUTE = "/login"
export const SIGNUP_ROUTE = "/signup"
export const ABOUT_ROUTE = "/about"
export const CONTACT_ROUTE = "/contact"
export const FAQ_ROUTE = "/faqs"
export const GET_LOAN_ROUTE = "/login"
export const BLOG_ROUTE = "/blog"
export const FORGOT_PASSWORD_ROUTE = "/forgot-password"
export const RESET_PASSWORD_ROUTE = "/reset-password"
export const DASHBOARD_ROUTE = "/dashboard"
export const DASHBOARD_PROFILEROUTE = "/dashboard/profile"
export const DASHBOARD_ONBOARDING_ROUTE = "/dashboard/onboarding"
export const DASHBOARD_NEXTOFKINROUTE = "/dashboard/next-of-kin"
export const DASHBOARD_DOWNLOADROUTE = "/dashboard/form-download"
export const DASHBOARD_PAYMENT_DETAILS_ROUTE = "/dashboard/payment-details"
export const DASHBOARD_APPLY_ROUTE = "/dashboard/apply"
export const DASHBOARD_APPLY_UNDERGRAD_ROUTE = "/dashboard/apply/undergraduate"
export const DASHBOARD_APPLY_POSTGRAD_ROUTE = "/dashboard/apply/postgraduate"
export const DASHBOARD_APPLY_PROFESSIONAL_ROUTE = "/dashboard/apply/professional"
export const DASHBOARD_SETTINGS_ROUTE = "/dashboard/settings"
export const DASHBOARD_LOGOUT_ROUTE = "/dashboard/logout"
