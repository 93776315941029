import { observer } from "mobx-react-lite"
import FullScreenLoader from "components/FullScreenLoader"
import AuthLayout from "layout/AuthLayout"
import React, { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { BASE_URL } from "services"
import http from "services/httpService"
import useSWR from "swr"
import AppFee from "../AppFee"
import GuarantorFormFile from "../GuarantorFormFile"
import InstitutionInfoPage from "../InstitutionInfoPage"
import LoanComp from "../LoanComp"
import ProfessiionalGraduateForm from "./ProfessionalFormDoc"
import GuarantorForm from "../GuarantorForm"
import GuarantorFormTwo from "../GuarantorFormTwo"
import CompleteYourProfile from "components/CompleteProfile"
import { DASHBOARD_APPLY_PROFESSIONAL_ROUTE, DASHBOARD_NEXTOFKINROUTE, DASHBOARD_PAYMENT_DETAILS_ROUTE, DASHBOARD_PROFILEROUTE } from "routes"
import { checkOnBoarding } from "services/MiscService"
import { onBoardStep } from "routes/apirRoutes"
import toast from "react-hot-toast"

const ProfessionalLoanPage = () => {
	const navigate = useNavigate()
	let params = useParams()

	const url = `${BASE_URL}/loans/${params?.id}`
	const fetcher = async () => {
		const res = await http.get(url)
		return res?.data?.data
	}

	const { data: loan, mutate } = useSWR(url, fetcher)

	const urlTwo = `${BASE_URL}/users/who_i_am`
	const fetcherTwo = async () => {
		const res = await http.get(urlTwo)
		return res?.data?.data
	}
	const handleOnBoarded = async () => {
        const info = await checkOnBoarding();
        if(info?.error) { 
          toast.error(info.error);
        }else{
          // reroute to onboarding page
          if(!info.onBoarded && info.nextStep === onBoardStep.profile){
            navigate(DASHBOARD_PROFILEROUTE)
          }
          else if(!info.onBoarded && info.nextStep === onBoardStep.next_of_kin){
            navigate(DASHBOARD_NEXTOFKINROUTE)
          }
          else if(!info.onBoarded && info.nextStep === onBoardStep.bank_account){
            navigate(DASHBOARD_PAYMENT_DETAILS_ROUTE)
          }
        }
      }
    
      useEffect( ()=>{
        handleOnBoarded();
      });
	const { data: userProfile } = useSWR(urlTwo, fetcherTwo)

	if (!loan) return <FullScreenLoader show={true} />
	if (params?.id && loan && loan.length <= 0) {
		navigate(`${DASHBOARD_APPLY_PROFESSIONAL_ROUTE}`)
	}
	return (
		<AuthLayout>
			<>
				{userProfile && !userProfile?.hometown ? (
					<CompleteYourProfile />
				) : userProfile && userProfile?.hometown && !userProfile?.next_of_kin?.first_name ? (
					<CompleteYourProfile title="Next of Kin" desc="Kindly update your next of kin details under ther profile menu to apply" or="goto profile menu, next of kin and update" />
				) : userProfile && userProfile?.hometown && userProfile?.next_of_kin?.first_name && !userProfile?.bank_account?.account_number ? (
					<CompleteYourProfile
						link={DASHBOARD_PAYMENT_DETAILS_ROUTE}
						btnText="Payment"
						title="Account Details"
						desc="Kindly update your bank details under the payment detail menu"
						or="goto payment details menu"
					/>
				) : (
					<>
						{loan?.length <= 0 && <InstitutionInfoPage loanType={"professional"} />}
						{loan?.length > 0 && loan[0]?.loan_step === "details" && <LoanComp id={params?.id} mutate={mutate} loanType={"professional"} />}
						{loan?.length > 0 && loan[0]?.loan_step === "surety_1" && <GuarantorForm id={params?.id} mutate={mutate} loanType={"professional"} />}
						{loan?.length > 0 && loan[0]?.loan_step === "surety_2" && <GuarantorFormTwo id={params?.id} mutate={mutate} loanType={"professional"} />}
						{loan?.length > 0 && loan[0]?.loan_step === "upload_docs" && <ProfessiionalGraduateForm mutate={mutate} id={params?.id} />}
						{loan?.length > 0 && loan[0]?.loan_step === "guarantor" && <GuarantorFormFile id={params?.id} loanType={"professional"} mutate={mutate} />}
						{loan?.length > 0 && loan[0]?.loan_step === "payment" && <AppFee id={params?.id} mutate={mutate} />}
					</>
				)}
			</>
		</AuthLayout>
	)
}

export default observer(ProfessionalLoanPage)
