import { Img } from 'globalStyles'
import React from 'react'
import { ContactItemDiv } from './style'

function ContactItem({ icon, text = 'phone', email = false, phone = false, address = false  }) {
  return (
    <ContactItemDiv>
    <Img src={icon} alt={text} />
      {email && <a className="text-contact" href={`mailto:${text}`}>{text}</a>}
      {phone && <a className="text-contact" href={`tel:${text}`}>{text}</a>}
      {address && <p className="text-contact">{text}</p>}
    </ContactItemDiv>
  )
}

export default ContactItem
