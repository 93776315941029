import { useEffect,useState } from "react";
import apiRoute from "routes/apirRoutes";
import http from "services/httpService";

export default function useUserLoans(){
    const [userLoans,setUserLoans] = useState([]);
    const [loading,setLoading] = useState(false);

    // TODO: remove repeated function after better research
    async function getDrafts(){
        setLoading(true)
        const res = await http.get(apiRoute.loanUser)
        setLoading(false)
        if(res.data){
            setUserLoans(res.data.data)
        }
    }

    useEffect(()=>{
        async function getLoans(){
            setLoading(true)
            const res = await http.get(apiRoute.loanUser)
            setLoading(false)
            if(res.data){
                setUserLoans(res.data.data)
            }
        }
        getLoans()
    },[])

    return {
        loading,
        userLoans,
        getDrafts
    }
}