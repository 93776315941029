import styled from 'styled-components'
import media from 'utils/media'

export const FaqContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 6rem;
  padding: 0px 60px;

  ${media.mobile`
padding: 0px 30px;
`}
`

export const QuestionDiv = styled.div``
export const FaqHeader = styled.h3`
  font-weight: 700;
  font-size: 38px;
  line-height: 48px;
  letter-spacing: -0.04em;
  color: #6d0062;

  ${media.mobile`
  font-size: 25px;
  line-height: 38px;
`}
`
export const FaqQuestion = styled.h3`
  font-family: 'Tahoma';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 35px;
  letter-spacing: -0.04em;

  ${media.mobile`
  font-size: 16px;
  line-height: 30px;
`}
`
export const AnsDiv = styled.div`
  .faq__para {
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 10px;
  }

  ${media.mobile`
  .faq__para {

	  font-size: 14px;
  }
`}
`

export const FAQicon = styled.img``
export const FaqBottom = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
  ${media.mobile`
  margin-bottom: 2rem;
`}
  img {
    margin-left: 10px;
  }
  .faq__link {
    font-family: 'Tahoma';
    font-weight: 700;
    font-size: 16px;
    color: #000000;
  }
`
