import { Checkbox, Modal, Spin, Table } from "antd"
import useDrafts from "hooks/useDrafts";


export default function OngoingApplication(){
    const {
        columns,
        incompleteLoans,
        open,
        deleteAllOpen,
        loading,
        selected,
        deleteLoading,
        deleteAllLoading,
        closeAllDeleteModal,
        closeModal,
        handleDelete,
        handleDeleteAll,
        openDeleteAllModal,
        handleSelectAll,
    } = useDrafts()

    return (
        <>
        <Modal onCancel={closeModal} centered footer={null} maskClosable={false} open={open}>
            <div className="text-center px-4 mt-8">
                <p className="font-poppins text-2xl font-semibold">Confirm Delete</p>
                <p className="font-poppins my-2 mb-6 ">Are you sure you want to delete this application, This action cannot be reversed</p>
                <div className="flex gap-4 justify-center my-3">
                    <button disabled={deleteLoading} onClick={closeModal} className="bg-red-100 border-red-400 disabled:opacity-55 disabled:cursor-not-allowed text-red-700 border-solid px-6 py-2 rounded-full">Cancel</button>
                    <button disabled={deleteLoading} onClick={handleDelete} className="bg-primary-100 border-primary-400 disabled:opacity-55 disabled:cursor-not-allowed border-solid px-6 py-2 rounded-full">
                        {
                            deleteLoading
                            ?<Spin/>
                            :<span>Continue</span>
                        }
                    </button>
                </div>
            </div>
        </Modal>
        <Modal onCancel={closeAllDeleteModal} centered footer={null} maskClosable={false} open={deleteAllOpen}>
            <div className="text-center px-4 mt-8">
                <p className="font-poppins text-2xl font-semibold">Confirm Delete</p>
                <p className="font-poppins my-2 mb-6 ">Are you sure you want to delete all {selected.length} application(s), This action cannot be reversed</p>
                <div className="flex gap-4 justify-center my-3">
                    <button disabled={deleteAllLoading} onClick={closeAllDeleteModal} className="bg-red-100 disabled:opacity-55 disabled:cursor-not-allowed border-red-400 text-red-700 border-solid px-6 py-2 rounded-full">Cancel</button>
                    <button disabled={deleteAllLoading} onClick={handleDeleteAll} className="bg-primary-100 disabled:opacity-55 disabled:cursor-not-allowed border-primary-400 border-solid px-6 py-2 rounded-full">
                        {
                            deleteAllLoading
                            ?<Spin/>
                            :<span>Continue</span>
                        }
                    </button>
                </div>
            </div>
        </Modal>
        <section>
            <header className="text-center sm:text-left mb-4 sm:mb-0">
                <p className="font-poppins text-base sm:text-xl font-semibold text-secondary">Your Incomplete Applications ({incompleteLoans.length})</p>
                <p className="font-poppins text-xs sm:text-sm text-gray-500">Continue Where you left off and complete your applications</p>
            </header>
            <header className="flex items-center mt-4 my-2 justify-between">
                <div className="flex gap-2">
                    <Checkbox checked={incompleteLoans.length === selected.length} onClick={handleSelectAll}/>
                    <p className="font-poppins">Select All</p>
                </div>
                <button onClick={openDeleteAllModal} className="px-3 text-sm sm:text-base sm:px-6 py-2 rounded-md bg-secondary text-white">Delete Marked ({selected.length})</button>
            </header>
            <div className="my-2 max-w-[100vw] bg-white overflow-x-auto">
                <Table loading={loading} pagination={{
                    responsive: true,
                    defaultPageSize:5
                }} columns={columns} dataSource={incompleteLoans}/>
            </div>
        </section>
        </>
    )
}
export function AllApplications(){
    const {
        columnsAllApplications,
        userLoans,
        open,
        loading,
        deleteLoading,
        closeModal,
        handleDelete,
    } = useDrafts()

    return (
        <>
        <Modal onCancel={closeModal} centered footer={null} maskClosable={false} open={open}>
            <div className="text-center px-4 mt-8">
                <p className="font-poppins text-2xl font-semibold">Confirm Delete</p>
                <p className="font-poppins my-2 mb-6 ">Are you sure you want to delete this application, This action cannot be reversed</p>
                <div className="flex gap-4 justify-center my-3">
                    <button disabled={deleteLoading} onClick={closeModal} className="bg-red-100 border-red-400 disabled:opacity-55 disabled:cursor-not-allowed text-red-700 border-solid px-6 py-2 rounded-full">Cancel</button>
                    <button disabled={deleteLoading} onClick={handleDelete} className="bg-primary-100 border-primary-400 disabled:opacity-55 disabled:cursor-not-allowed border-solid px-6 py-2 rounded-full">
                        {
                            deleteLoading
                            ?<Spin/>
                            :<span>Continue</span>
                        }
                    </button>
                </div>
            </div>
        </Modal>
        <section>
            <header className="text-center sm:text-left mb-8 sm:mb-0">
                <p className="font-poppins text-base sm:text-xl font-semibold text-secondary">Your Applications ({userLoans.length})</p>
                <p className="font-poppins text-xs sm:text-sm text-gray-500 hidden sm:block">All your applications in one place for you to see</p>
                <p className="font-poppins text-xs sm:text-sm text-gray-500 sm:hidden">scroll to the right to see all fields</p>
            </header>
            <br />
            <div className="my-2 max-w-[100vw] bg-white overflow-x-auto">
                <Table loading={loading} pagination={{
                    responsive: true,
                    defaultPageSize:10
                }} columns={columnsAllApplications} dataSource={userLoans}/>
            </div>
        </section>
        </>
    )
}