import FullScreenLoader from "components/FullScreenLoader"
import React from "react"
import { useState, useEffect } from "react"
import http from "services/httpService"
import useSWR from "swr"
import apiRoute from "routes/apirRoutes"
import toast from "react-hot-toast"
import { checkOnBoarding } from "services/MiscService"
import { DASHBOARD_NEXTOFKINROUTE, DASHBOARD_PAYMENT_DETAILS_ROUTE } from "routes"
import { useNavigate } from "react-router-dom"
import UpdateProfileForm from "./UpdateProfileForm"
import UpdateNextOfKinForm from "./UpdateNextOfKinForm"
// import { FormatColorResetOutlined } from "@mui/icons-material"

function UserProfile(btnShow = true) {
	const navigate = useNavigate();
	const [key, setKey] = useState("personal")
	// const url = `${BASE_URL}/users/who_i_am`
	const fetcher = async () => {
		const res = await http.get(apiRoute.whoIAm)
		return res?.data?.data
	}

	const { data: userProfile } = useSWR(apiRoute.whoIAm, fetcher)

	useEffect(()=>{
		const handleOnBoarded = async () => {
			const info = await checkOnBoarding();
			if(info?.error) {
				toast.error(info.error);
			}else{
			// reroute to onboarding page
			if(!info.onBoarded && info.nextStep === "next_of_kin"){
				navigate(DASHBOARD_NEXTOFKINROUTE)
			}
			else if(!info.onBoarded && info.nextStep === "bank_account"){
				navigate(DASHBOARD_PAYMENT_DETAILS_ROUTE)
			}
			}
		  }
		handleOnBoarded();
	},[navigate]);
		if (!userProfile) return <FullScreenLoader show={true} />

	const active1 = key === "personal"
	const active2 = key === "kin"

	return (
		<>
			<header className="bg-white mb-8 pt-10 px-4 -mt-[60px] border-b border-primary-200 sticky top-0 z-[100] border-solid">
				<button onClick={()=>setKey("personal")} className={`p-2 px-6 min-w-[150px] rounded-t-lg transition-animation text-lg ${active1?"bg-secondary-800 text-white":""}`}>Profile</button>
				<button onClick={()=>setKey("kin")} className={`p-2 px-6 min-w-[150px] rounded-t-lg transition-animation text-lg ${active2?"bg-secondary-800 text-white":""}`}>Next Of Kin</button>
			</header>
			{key === "personal" ? <UpdateProfileForm setKey={() => setKey("kin")} user={userProfile}  show ={btnShow} /> : null}
			{key === "kin" && <UpdateNextOfKinForm user={userProfile} loanType="user"  show ={btnShow}  />} 
		</>
	)
}

export default UserProfile
